import {useEffect, useState} from "react";
import {IOrgUnit} from "./useOrgunit";
import {Auth} from "aws-amplify";

const orgUnitCompareByName = (a: IOrgUnit, b: IOrgUnit) => (a.orgName > b.orgName) ? 1 : -1

export function useOwnOrgUnit() {
    const [orgUnit, setOrgUnit] = useState<IOrgUnit[]>([])
    const [adminOrgUnit, setAdminOrgUnit] = useState<IOrgUnit[]>([])
    const [errors, setErrors] = useState([])
    const ADMIN_PREFIX_LENGTH = 6;
    useEffect(() => {
        getOrgUnit()
            .then(data => setOrgUnit(data))
            .catch(err => setErrors(err))
        getAdminOrgUnit()
            .then(data => {
                data.forEach(adminOrgUnit => {
                    adminOrgUnit.orgId = adminOrgUnit.orgId.substring(ADMIN_PREFIX_LENGTH);
                })
                setAdminOrgUnit(data)
            })
            .catch(err => setErrors(err))
    }, [])
    return {
        orgUnit: orgUnit,
        adminOrgUnit: adminOrgUnit,
        errors
    }
}

export async function getOrgUnit(): Promise<IOrgUnit[]> {
    const orgUnitURL = process.env.REACT_APP_COGNITO_USER_ENDPOINT! + "/self"
    const cognitoUserSession = await Auth.currentSession();
    const response = await fetch(orgUnitURL, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
        }
    });
    const data = await response.json();
    return [...data.orgUnits].sort(orgUnitCompareByName).filter((org: IOrgUnit) => !org.deleted);

}

export async function getAdminOrgUnit(): Promise<IOrgUnit[]> {
    const orgUnitURL = process.env.REACT_APP_COGNITO_USER_ENDPOINT! + "/self"
    const cognitoUserSession = await Auth.currentSession();
    const response = await fetch(orgUnitURL, {
        method: "GET",
        headers: {
            Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
        }
    });
    const data = await response.json();
    return [...data.adminOrgUnits].sort(orgUnitCompareByName).filter((org: IOrgUnit) => !org.deleted);

}