import {MailConfig} from "../API";
import {gql, useQuery} from "@apollo/client";
import {listMailConfigs} from "../graphql/queries";
import {useEffect} from "react";
import {onCreateMailConfig, onUpdateMailConfig} from "../graphql/subscriptions";

export function useMailConfigList(): MailConfig[] | undefined {
    const {data, subscribeToMore} = useQuery(gql(listMailConfigs));

    useEffect(function subscribe() {
        const unsubscribeOnCreateMailConfig = subscribeToMore({
            document: gql(onCreateMailConfig),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newMailConfig = subscriptionData.data.onCreateMailConfig;
                return (Object.assign({}, prev, {
                        listMailConfigs: {
                            items: [...prev.listMailConfigs.items, newMailConfig],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => {console.error("onCreateMailConfigSubscription: " + JSON.stringify(error));}
        });
        const unsubscribeOnUpdateMailConfig = subscribeToMore({
            document: gql(onUpdateMailConfig),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedMailConfig: MailConfig = subscriptionData.data.onUpdateMailConfig;
                let configAlreadyInList = false;
                let newConfigList = prev.listMailConfigs.items.map((item: MailConfig) => {
                    if (item.userId === updatedMailConfig.userId) {
                        configAlreadyInList = true
                        return updatedMailConfig
                    }
                    return item
                })
                if (!configAlreadyInList) {
                    newConfigList.push(updatedMailConfig)
                }
                return Object.assign({}, prev, {
                    listMailConfigs: {
                        items: newConfigList,
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => {console.error("onUpdateMailConfigSubscription: " + JSON.stringify(error));}
        });

        function unsubscribe() {
            unsubscribeOnCreateMailConfig();
            unsubscribeOnUpdateMailConfig();
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscribeToMore]);

    if (data && data.listMailConfigs && data.listMailConfigs.items) {
        return data.listMailConfigs.items;
    } else {
        return undefined;
    }

}