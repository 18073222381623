import React, {useEffect, useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField
} from "@material-ui/core";
import {Hint, Room} from "../API";
import {CognitoUser} from "../hooks/useCognitoUserList";
import {useTranslation} from "react-i18next";
import {useHintList} from "../hooks/useHintList";
import {gql, useMutation} from "@apollo/client";
import {updateHint} from "../graphql/mutations";
import {comparatorAlphanumericValues} from "../Utils/Helpers";


interface Props {
    room: Room
    showRoomHintsConfig: boolean
    setShowRoomHintsConfig: (value: boolean) => void
    cognitoUserList: CognitoUser[]
}

const ShowRoomHintsManagerComponent: React.FC<Props> = (props) => {
    let {showRoomHintsConfig, setShowRoomHintsConfig, room} = props
    const hints: Hint[] | [] = useHintList((room ? room.roomId : ""));
    const [currentHints, setCurrentHints] = useState<Hint[] | []>([]);
    const [updateHintMutation] = useMutation(gql(updateHint))

    useEffect(() => {
        setCurrentHints(hints)
    }, [hints.length > 0]);

    const handleCloseRoomHintsManager = () => {
        setShowRoomHintsConfig(false)
        setCurrentHints(hints)
    }

    function comparator(item1: Hint, item2: Hint) {
        return comparatorAlphanumericValues(item1.hintId, item2.hintId)
    }

    const handleSaveAllRoomHints = () => {
        currentHints.forEach((el) => {
            updateHintMutation({
                variables: {
                    input: {
                        hintId: el.hintId,
                        roomId: el.roomId,
                        text: el.text.trim(),
                    }
                }
            }).catch((err) => console.error("ShowRoomHintsManagerComponent handleSaveAllRoomHints " + JSON.stringify(err)))
        })
    }

    const handleHintValueTextChange = (hint: Hint, event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        if (event.target.value.length <= 320) {
            setCurrentHints(currentHints.map(h => h.hintId === hint.hintId ? {...h, text: event.target.value} : h));
        }
    };

    currentHints.sort(comparator);
    const {t} = useTranslation();
    return (
        <>
            <Dialog fullWidth={true} maxWidth={"lg"} style={{width: "100%", flexGrow: 1}} open={showRoomHintsConfig}
                    data-testid={"roomHintsManager"}>
                <DialogTitle>{t("room_hint_management_dialog_title")}</DialogTitle>
                <DialogContent>
                    <Box>
                        {/* 'calc(100vh - 213px)' - 213 is arbitrary constant which fits well this tableContainer into the box, so Buttons can be seen all the time*/}
                        <TableContainer
                            style={{maxHeight: 'calc(100vh - 213px)', marginTop: "1rem", tableLayout: "fixed"}}>
                            <Table stickyHeader style={{marginTop: "0"}}>
                                <TableHead>
                                    <TableRow>
                                        <TableCell
                                            data-testid={"room_name_column"}
                                            style={{wordBreak: "break-word"}}>
                                            {t("room_name_column")}
                                        </TableCell>
                                        <TableCell
                                            data-testid={"room_note_text_column"}
                                            style={{wordBreak: "break-word"}}>
                                            {t("room_note_text_column")}
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody data-testid={"room-list"}>
                                    {room.roomId &&
                                        currentHints.map((hint: Hint | null, index: number) => (
                                            <TableRow data-testid={`hint-${index}`}
                                                      key={hint!.hintId}
                                                      style={{cursor: "pointer"}}>
                                                <TableCell>
                                                    {hint!.hintId}
                                                </TableCell>
                                                <TableCell>
                                                    <TextField value={hint!.text} fullWidth={true}
                                                               multiline data-testid={`hint-text-${index}`}
                                                               helperText={`${hint!.text.length}/320`}
                                                               onChange={(event) =>
                                                                   handleHintValueTextChange(hint!, event)}/>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <DialogActions style={{display: "flex", justifyContent: "center"}}>
                            <Button
                                onClick={() => handleSaveAllRoomHints()}
                                color={"primary"}
                                variant={"contained"}
                                data-testid={"save-btn"}
                            >
                                {t('button_save')}
                            </Button>
                            <Button
                                onClick={() => {
                                    handleCloseRoomHintsManager()
                                }}
                                color={"primary"}
                                variant={"contained"}
                                data-testid={"close-btn"}
                            >
                                {t('button_close')}
                            </Button>
                        </DialogActions>
                    </Box>
                </DialogContent>
            </Dialog>
        </>
    )
}

export default ShowRoomHintsManagerComponent