import React, {useEffect, useRef, useState} from 'react';
import {List, ListItem, ListItemText, Paper} from "@mui/material";
import {useRoomPlanContext} from "../../../hooks/useRoomPlanContext";
import dayjs from "dayjs";

interface Props {
    mousePosX: number,
    mousePosY: number,
    offset: number,
    currentUserID: string
}

const BookingMeetingRoomList: React.FC<Props> = (props) => {

    const {
        mousePosX,
        mousePosY,
        offset
    } = props

    const {
        bookingsToDisplay
    } = useRoomPlanContext()

    const [elementPositionX, setElementPositionX] = useState(mousePosX);
    const [elementPositionY, setElementPositionY] = useState(mousePosY);
    const elementRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (elementRef.current) {
            if (window.innerWidth - offset < (mousePosX + elementRef.current.offsetWidth)) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset)
            }
            if (window.innerHeight - offset < (mousePosY + elementRef.current.offsetHeight)) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Paper ref={elementRef} elevation={3} sx={{
            overflowWrap: 'anywhere',
            position: "absolute",
            left: elementPositionX,
            top: elementPositionY,
            backgroundColor: "white",
            pointerEvents: "none",
            whiteSpace: "nowrap"
        }}>
            <List sx={{width: '100%', maxWidth: 600}} disablePadding={true} dense={true}
                  style={{backgroundColor: "rgb(106, 233, 255, .35)"}}>
                {bookingsToDisplay.length > 0 && bookingsToDisplay.map((bookingToDisplay) =>
                    <ListItem style={bookingToDisplay.bookerId === props.currentUserID ?
                        {
                            backgroundColor: "rgb(106, 233, 255)",
                            border: "1px solid rgba(0, 0, 0, .2)",
                            borderRadius: "5px"
                        }
                        : {backgroundColor: "none", border: "1px solid rgba(0, 0, 0, .2)", borderRadius: "5px"}
                    }
                              key={bookingToDisplay.bookingId}
                              sx={{
                                  paddingTop: 0.1,
                                  paddingBottom: 0.1,
                                  paddingLeft: (theme) => theme.spacing(1)
                              }}>
                        <ListItemText
                            primary={dayjs(bookingToDisplay.timeBegin).format("HH:mm") + "\u202F\u2013\u202F"
                                + dayjs(bookingToDisplay.timeEnd).format("HH:mm") + "   |   " + bookingToDisplay.meetingName
                                + "   |   " + bookingToDisplay.participantNumber + "   |   " + bookingToDisplay.meetingType}
                        />
                    </ListItem>)
                }
            </List>
        </Paper>
    );
}

export default BookingMeetingRoomList;