import React, {useEffect, useState} from "react";
import {Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField} from "@mui/material";
import borders from "../../styles/borders";
import locales_de from "../../locales/de";
import MeetingRoomBookingDropdown from "./MeetingRoomBookingDropdown";
import {MeetingType} from "../../Utils/Enums";

interface Props {
    onChangeMeetingName: (name: string) => void,
    onChangeNumberOfParticipants: (number: number) => void,
    onChangeMeetingType: (meetingType: MeetingType) => void,
    maxParticipants: number | null
}

const MeetingRoomBookingSettings: React.FC<Props> = (props) => {
    const [meetingName, setMeetingName] = useState<string>("")
    const [numberOfParticipants, setNumberOfParticipants] = useState<string>("")
    const [meetingType, setMeetingType] = useState<MeetingType>(MeetingType.INTERNAL)
    const [showWarning, setShowWarning] = useState<boolean>(false)

    const {onChangeMeetingName, onChangeNumberOfParticipants, onChangeMeetingType, maxParticipants} = props;

    const handleMeetingNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setMeetingName(event.target.value);
        onChangeMeetingName(event.target.value);
    }
    const handleParticipantChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        event.stopPropagation();
        setNumberOfParticipants(event.target.value);
        onChangeNumberOfParticipants(parseInt(event.target.value));
    }

    useEffect(() => {
        onChangeMeetingType(meetingType);
    }, [meetingType, onChangeMeetingType])

    useEffect(() => {
        if (parseInt(numberOfParticipants) > maxParticipants! || parseInt(numberOfParticipants) < 1) {
            setShowWarning(true);
            return;
        }
        setShowWarning(false);

    }, [maxParticipants, numberOfParticipants])

    return (
        <div style={{display: "flex", flexDirection: "row"}} data-testid={"time-window-table"}>
            <TableContainer sx={{
                minWidth: "320px",
                maxHeight: "400px",
                borderRadius: borders.mediumBorderRadius,
                overflow: "auto"
            }}>
                <Table stickyHeader>
                    <TableHead>
                        <TableRow>
                            <TableCell colSpan={2}
                                       sx={{fontSize: "large"}}>
                                {locales_de["multibookingdialog_meetingroom_settings_title"]}
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody data-testid={"time-window-table-body"}>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_meeting_name"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}}
                                           label="Meeting name" variant="filled" required value={meetingName}
                                           onChange={handleMeetingNameChange}/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_participant_number"}>
                                <TextField style={{width: "100%", backgroundColor: "white"}} label="Teilnehmerzahl"
                                           variant="filled" type="number"
                                           inputProps={{inputMode: 'numeric', pattern: '[0-9]*'}}
                                           value={numberOfParticipants}
                                           helperText={showWarning ? "Teilnehmeranzahl soll zwischen 1 und " + maxParticipants + " liegen" : ""}
                                           error={showWarning} onChange={handleParticipantChange} required/>
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align={'center'} colSpan={2} width={1}
                                       data-testid={"multibookingdialog_meetingroom_settings_dropdown"}>
                                <MeetingRoomBookingDropdown meetingTypeState={meetingType}
                                                            setMeetingTypeState={setMeetingType}/>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer>
        </div>);

}

export default MeetingRoomBookingSettings