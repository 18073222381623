import {SvgIcon} from "@mui/material";

function MonitorIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g transform="translate(-112.44 -81.911)">
                <g transform="matrix(-.00044685 0 0 .00044685 112.96 81.813)" stroke="none" color="#000000">
                    <g transform="matrix(-1433.3 0 0 1433.3 370428 -133685)">
                        <g transform="matrix(.39791 0 0 .39823 65.503 35.765)" strokeWidth=".50242">
                            <path transform="matrix(2.5131 0 0 2.5111 -122.49 -87.424)"
                                  d="m250.58 92.996v6.3125h-9.3535v-6.1523c-0.0879 0.06226-0.14535 0.13498-0.17578 0.21875-0.13889 0.37066-0.18439 1.9916-0.0918 3.6133l0.13867 2.9668 1.9902 0.13867c1.0648 0.0927 1.9453 0.32357 1.9453 0.60156 0 0.23166-0.18512 0.41602-0.46289 0.41602-0.23149 0-0.46289 0.18685-0.46289 0.46484 0 0.23166 0.83306 0.46289 1.8516 0.46289 1.0185 0 1.8516-0.23123 1.8516-0.46289 0-0.27799-0.18511-0.46484-0.46289-0.46484-0.23148 0-0.46289-0.18436-0.46289-0.41602 0-0.27799 0.87914-0.5089 1.9902-0.60156l1.9453-0.13867v-6.9512z"
                                  stroke="none"/>
                        </g>
                        <rect x="257.87" y="94.146" width="9.51" height="6.616" fill="#fff" stroke="#000"
                              strokeWidth=".4"/>
                    </g>
                </g>
            </g>
        </SvgIcon>
    );
}

export default MonitorIcon;

