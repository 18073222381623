import React from "react";
import {TableCell, TableRow} from "@mui/material";
import {TimeWindow} from "../../types/TimeWindowType";
import {Dayjs} from "dayjs";
import {useTranslation} from "react-i18next";

interface Props {
    timeWindow: TimeWindow
    onClick: (start: Dayjs, end: Dayjs) => void
}


const TimeWindowTableRowComponent: React.FC<Props> = (props) => {

    const {t} = useTranslation();

    function onClick() {
        props.onClick(props.timeWindow.start, props.timeWindow.end)
    }

    return (
        <TableRow
            data-testid={"time-window-table-row-component"}
            hover={true}
            onClick={onClick}
            sx={{
                minWidth: "200px",
                paddingTop: "8px",
                paddingBottom: "8px",
                textAlign: "left",
                '&:hover': {
                    cursor: "pointer"
                }
            }}>
            <TableCell data-testid={"time-window-table-cell-time-component"}>
                {props.timeWindow.start.format("HH:mm") + "\u202F\u2013\u202F" + props.timeWindow.end.format("HH:mm")}
            </TableCell>
        </TableRow>
    );
}

export default TimeWindowTableRowComponent;