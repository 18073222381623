import {Booking} from "../../../API";
import React, {useCallback} from "react";
import locales_de from "../../../locales/de";
import {gql, useMutation} from "@apollo/client";
import {
    deleteBookingNoRoomResponse as deleteBookingNoRoomResponseTemplate
} from "../../../graphqlCustom/mutationsCustom";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {SeatPaper} from "./SeatPaper";
import {MeetingRoomPaper} from "./MeetingRoomPaper";
import {ExecuteOtherBookingsButton} from "./ExecuteOtherBookingsButton";
import {BookingType} from "../../../types/BookingType";

interface Props {
    position: React.CSSProperties
    offset: number
    isTimeActive: boolean
    deletableBookings: Booking[]
    handleBookOtherDateTime: () => void
    dateISO: string
    closeDeleteBookingDialog: () => void
    bookingType: BookingType,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

const ItemClickedDialog: React.FC<Props> = (props) => {

    const {
        position,
        offset,
        isTimeActive,
        deletableBookings,
        handleBookOtherDateTime,
        dateISO,
        closeDeleteBookingDialog,
        bookingType,
        meetingRoomBookingProps
    } = props

    const [deleteBookingNoRoomResponseMutation] = useMutation(gql(deleteBookingNoRoomResponseTemplate))
    const removeSelectedBooking = useCallback((bookingId: string) => {
        deleteBookingNoRoomResponseMutation({
            variables: {
                input: {
                    bookingId: bookingId,
                    date: dateISO
                }
            }
        })
            .catch((err) => console.error(err))
            .finally(closeDeleteBookingDialog)

    }, [closeDeleteBookingDialog, dateISO, deleteBookingNoRoomResponseMutation])

    const getExecuteOtherBookingsButtonText = function () {
        const nameBasedOnTimeActivityForSeatPopup = isTimeActive ? locales_de["button_book_other_days_times"] : locales_de["button_book_other_days"];
        return bookingType === "meetingRoom" ? locales_de["open_another_booking_dialog"] : nameBasedOnTimeActivityForSeatPopup;
    }

    const executeOtherBookingsButton = <ExecuteOtherBookingsButton handleBookOtherDateTime={handleBookOtherDateTime}
                                                                   text={getExecuteOtherBookingsButtonText()}/>
    return <>{(bookingType === "meetingRoom") ?
        <MeetingRoomPaper
            deletableBookings={deletableBookings}
            removeSelectedBooking={removeSelectedBooking}
            executeOtherBookingsButton={executeOtherBookingsButton}
            position={position}
            offset={offset}
            meetingRoomBookingProps={meetingRoomBookingProps}
        />
        :
        <SeatPaper
            deletableBookings={deletableBookings}
            removeSelectedBooking={removeSelectedBooking}
            executeOtherBookingsButton={executeOtherBookingsButton}
            position={position}
            offset={offset}
            isTimeActive={isTimeActive}/>
    }
    </>
}

export default ItemClickedDialog





