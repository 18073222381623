import React, {ReactElement} from "react";
import Sit2GetherLogo from "../logos/Sit2GetherLogo.png"
import {User} from "../services/UserClient";
import {MenuNav} from "@2gether/frontend-library";
import {useTranslation} from "react-i18next";
import i18n from "i18next";



interface Props {
    setShowOwnBookingsManager: (value: boolean) => void,
    setShowRoomManager: (value: boolean) => void,
    setShowDockingstationManager: (value: boolean) => void,
    setShowMonitorManager: (value: boolean) => void,
    setShowBookingLimitationManager: (value: boolean) => void,
    setShowNotificationDialog: (value: boolean) => void,
    currentUser: User,
    signOut: () => void,
    sit2getherDailyBookingsDisplay?: ReactElement<any, any> | null
}

const HeaderComponent: React.FC<Props> = (props) => {
    const {
        setShowOwnBookingsManager,
        setShowRoomManager,
        setShowDockingstationManager,
        setShowMonitorManager,
        setShowBookingLimitationManager,
        setShowNotificationDialog,
        currentUser,
        signOut,
        sit2getherDailyBookingsDisplay
    } = props

    const {t} = useTranslation();
    const localisation = i18n.language.substring(0,2)
    return (
        <MenuNav
            key={currentUser + "-" + Math.random()}
            username={currentUser ? currentUser.name : ""}
            imageSource={Sit2GetherLogo}
            headerSize={25}
            items={[]}
            userItems={[
                {
                    name: t("my_bookings_mngmt_dialog-title"),
                    show: true,
                    onClick: () => setShowOwnBookingsManager(true)
                },
                {
                    name: t("room_management_dialog-title"),
                    show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
                    onClick: () => setShowRoomManager(true)
                },
                {
                    name: t("docking_mngmnt_dialog-title"),
                    show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
                    onClick: () => setShowDockingstationManager(true)
                },
                {
                    name: t("monitor_mngmnt_dialog-title"),
                    show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
                    onClick: () => setShowMonitorManager(true)
                },
                {
                    name: t("booking_limitation_dialog_title"),
                    show: currentUser ? (currentUser.isAdmin || currentUser.isOrgUnitAdmin) : false,
                    onClick: () => setShowBookingLimitationManager(true)
                },
                {
                    name: t("button_notifications-title"),
                    show: true,
                    onClick: () => setShowNotificationDialog(true)
                }
            ]}
            nestedItems={[]}
            onLogoClick={() => true}
            signout={signOut}
            centralElement={sit2getherDailyBookingsDisplay}
            language = {localisation}
        />
    )
}
export default HeaderComponent