import {gql, useQuery} from "@apollo/client";
import {getBookingConfig} from "../graphql/queries";
import {useEffect} from "react";
import {onCreateBookingConfig, onUpdateBookingConfig} from "../graphql/subscriptions";

export function useBookingLimitation(orgUnitId: string) {

    const {data, subscribeToMore} = useQuery(gql(getBookingConfig), {
        variables: {
            orgUnitId: orgUnitId
        },
        skip: orgUnitId === ""
    })

    useEffect(function subscribe() {
        const unsubscribeOnUpdateBookingConfig = subscribeToMore({
            document: gql(onUpdateBookingConfig),
            variables: {orgUnitId: orgUnitId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData) {
                    return prev
                } else {
                    return Object.assign({}, {
                        getBookingConfig: subscriptionData.data.onUpdateBookingConfig
                    })
                }
            },
            onError: (error: any) => console.error("useBookingLimitation useEffect:subscribeToMore:onUpdateBookingConfig " + JSON.stringify(error))
        });
        const unsubscribeOnCreateBookingConfig = subscribeToMore({
            document: gql(onCreateBookingConfig),
            variables: {orgUnitId: orgUnitId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData) {
                    return prev
                } else {
                    return Object.assign({}, {
                        getBookingConfig: subscriptionData.data.onCreateBookingConfig
                    })
                }
            },
            onError: (error: any) => console.error("useBookingLimitation useEffect:subscribeToMore:onCreateBookingConfig " + JSON.stringify(error))
        });

        function unsubscribe() {
            unsubscribeOnUpdateBookingConfig();
            unsubscribeOnCreateBookingConfig();
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    if (data && data.getBookingConfig) {
        return data.getBookingConfig
    } else {
        return Object.assign({}, {
            maxDaysInAdvance: 300,
            maxBookableDays: 300
        })
    }
}