import {SvgIcon} from "@mui/material";

function ExternalBookingIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g id="layer1">
                <g
                    id="g1168"
                    transform="matrix(0.05530983,0,0,0.05530983,-266.301,-11.741215)">
                    <g
                        id="g1166">
                        <path
                            fill="#001122"
                            d="m 6159.7,714.2 c -68.4,26.1 -150.3,26 -225.1,51.1 -84.5,28.4 -172.7,39.9 -260.2,34.7 v 600.1 c 14.1,-5.8 27.6,-9.2 40.2,-9.2 144.8,0 414.6,438.6 762,-37.8 C 6824,876.8 6337.1,646.7 6159.7,714.2 Z M 5920.9,1157 c 0,0 170.1,-274.6 453.6,-54.4 0,0 -155,319 -453.6,54.4 z"
                            id="path1164"/>
                    </g>
                </g>
                <g
                    id="g1172"
                    transform="matrix(0.05530983,0,0,0.05530983,-266.301,-11.741215)">
                    <path
                        fill="#001122"
                        d="m 5713,801.4 c -73.7,-0.2 -147.4,-12.2 -218.5,-36 -74.8,-25.1 -156.7,-25.1 -225.1,-51.1 -177.3,-67.5 -664.3,162.6 -316.9,638.9 346.2,474.7 615.5,40.9 760.4,37.9 V 801.4 Z m -658.2,301.2 c 283.5,-220.3 453.6,54.4 453.6,54.4 -298.7,264.6 -453.6,-54.4 -453.6,-54.4 z"
                        id="path1170"/>
                </g>
            </g>
        </SvgIcon>
    );
}

export default ExternalBookingIcon;
