import {List, ListItem, ListItemIcon, ListItemText, Paper} from "@mui/material";
import React, {useEffect, useRef, useState} from "react";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import "../../styles/styleRoomPlan.css"


interface Props {
    mousePosX: number,
    mousePosY: number,
    offset: number
}

const RoomPlanHoverDialog: React.FC<Props> = (props) => {
    const {
        mousePosX,
        mousePosY,
        offset
    } = props

    const {
        itemsToDisplay
    } = useRoomPlanContext()

    const [elementPositionX, setElementPositionX] = useState(mousePosX);
    const [elementPositionY, setElementPositionY] = useState(mousePosY);
    const elementRef = useRef<HTMLDivElement>(null);
    const isNotHint = itemsToDisplay.length > 0 && (itemsToDisplay[0].isHint === undefined || itemsToDisplay[0].isHint === false);

    useEffect(() => {
        if (elementRef.current) {
            if (window.innerWidth - offset < (mousePosX + elementRef.current.offsetWidth)) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset)
            }
            if (window.innerHeight - offset < (mousePosY + elementRef.current.offsetHeight)) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Paper ref={elementRef} elevation={3} sx={{
            overflowWrap: 'nowhere',
            position: "absolute",
            left: elementPositionX,
            top: elementPositionY,
            backgroundColor: "white",
            pointerEvents: "none",
            whiteSpace: isNotHint ? "nowrap" : "normal"
        }}>
            <List sx={{width: '100%', minWidth: '100'}} disablePadding={false} dense={true}>
                {itemsToDisplay.length > 0 && itemsToDisplay.map((itemToDisplay, index) =>
                    <ListItem
                        key={index}
                        sx={{
                            paddingTop: 0.1,
                            paddingBottom: 0.1,
                            paddingLeft: (theme) => theme.spacing(1)
                        }}>
                        <ListItemIcon sx={{minWidth: (theme) => theme.spacing(4)}}>
                            {itemToDisplay.icon}
                        </ListItemIcon>
                        <ListItemText
                            primary={itemToDisplay.text}
                        />
                    </ListItem>
                )
                }
            </List>
        </Paper>
    );
}

export default RoomPlanHoverDialog;