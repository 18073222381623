import React, {useCallback, useEffect, useState} from "react";
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    List,
    ListItem,
    ListItemText
} from "@material-ui/core";
import {MailConfig} from "../API";
import {gql, useMutation} from "@apollo/client";
import {createMailConfig, updateMailConfig} from "../graphql/mutations";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";


interface Props {
    showNotificationDialog: boolean
    setShowNotificationDialog: (value: boolean) => void
}

const NotificationsComponent: React.FC<Props> = (props) => {
    const {
        showNotificationDialog,
        setShowNotificationDialog,
    } = props

    const {
        currentUser, mailConfig
    } = useMainApplicationContext()
    const {reportError} = useErrorContext();

    const [createMailConfigMutation] = useMutation(gql(createMailConfig))
    const [updateMailConfigMutation] = useMutation(gql(updateMailConfig))
    const [isReceiveEmailsForOwnBookings, setIsReceiveEmailsForOwnBookings] = useState<boolean>(false)
    const [isReceiveEmailsForForeignBookings, setIsReceiveEmailsForForeignBookings] = useState<boolean>(false)
    const [userConfig, setUserConfig] = useState<MailConfig | undefined>(undefined)

    useEffect(function populateStates() {
        let userMailConfig = mailConfig?.filter(config => config.userId === currentUser.ID)[0]
        if (userMailConfig?.userId === currentUser.ID) {
            setUserConfig(userMailConfig)
            setIsReceiveEmailsForOwnBookings(userMailConfig?.isReceiveOwnBooking ? userMailConfig!.isReceiveOwnBooking : false)
            setIsReceiveEmailsForForeignBookings(userMailConfig?.isReceiveForeignBooking ? userMailConfig!.isReceiveForeignBooking : false)
        }
    }, [mailConfig, currentUser])


    const handleOnSave = useCallback(() => {
        if (userConfig) {
            updateMailConfigMutation({
                variables: {
                    input: {
                        userId: userConfig.userId,
                        userMail: userConfig.userMail,
                        isReceiveOwnBooking: isReceiveEmailsForOwnBookings,
                        isReceiveForeignBooking: isReceiveEmailsForForeignBookings,
                    }
                }
            })
                .then(() => setShowNotificationDialog(false))
                .catch(error => reportError(error, "", "NotificationComponent handleOnSave"))
        } else {
            createMailConfigMutation({
                variables: {
                    input: {
                        userId: currentUser.ID,
                        userMail: currentUser.email,
                        isReceiveOwnBooking: isReceiveEmailsForOwnBookings,
                        isReceiveForeignBooking: isReceiveEmailsForForeignBookings,
                    }
                }
            })
                .then(() => setShowNotificationDialog(false))
                .catch(error => reportError(error, "", "NotificationComponent handleOnSave"))
        }
    }, [userConfig, updateMailConfigMutation, isReceiveEmailsForOwnBookings, isReceiveEmailsForForeignBookings, setShowNotificationDialog, reportError, createMailConfigMutation, currentUser.ID, currentUser.email])
    const handleCloseNotificationDialog = useCallback(() => {
        setShowNotificationDialog(false)
        setIsReceiveEmailsForOwnBookings(userConfig?.isReceiveOwnBooking ? userConfig?.isReceiveOwnBooking : false)
        setIsReceiveEmailsForForeignBookings(userConfig?.isReceiveForeignBooking ? userConfig?.isReceiveForeignBooking : false)
    }, [setShowNotificationDialog, userConfig])
    const handleReceiveEmailsForOwnBookingsClick =
        useCallback(() => setIsReceiveEmailsForOwnBookings((oldValue) => !oldValue), []);
    const handleReceiveEmailsForForeignBookingsClick =
        useCallback(() => setIsReceiveEmailsForForeignBookings((oldValue) => !oldValue), []);

    const {t} = useTranslation();
    return (<>
        <Dialog
            open={showNotificationDialog}
        >
            <DialogTitle>{t("notification-dialog-title")}</DialogTitle>
            <DialogContent>
                <DialogContentText>{t("notification-dialog-hint")}</DialogContentText>
                <List style={{maxHeight: 300, overflow: 'auto'}}>
                    <ListItem ContainerComponent="div" disableRipple key={4} dense button
                              onClick={handleReceiveEmailsForOwnBookingsClick}>
                        <ListItemText>{t("notification-option-1")}</ListItemText>
                        <Checkbox data-testid={"notification-checkbox-1"}
                                  edge="end"
                                  checked={isReceiveEmailsForOwnBookings}
                        />
                    </ListItem>
                    <ListItem ContainerComponent="div" disableRipple key={5} dense button
                              onClick={handleReceiveEmailsForForeignBookingsClick}>
                        <ListItemText>{t("notification-option-2")}</ListItemText>
                        <Checkbox data-testid={"notification-checkbox-2"}
                                  edge="end"
                                  checked={isReceiveEmailsForForeignBookings}
                        />
                    </ListItem>
                </List>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={handleOnSave}
                    disabled={false}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"notification-dialog-btn-save"}
                >
                    {t("button_save")}
                </Button>
                <Button
                    onClick={handleCloseNotificationDialog}
                    color={"primary"}
                    variant={"contained"}
                    data-testid={"notification-dialog-btn-close"}
                >
                    {t("confirm_dialog_cancel_button-text")}
                </Button>
            </DialogActions>
        </Dialog>
    </>)
}

export default NotificationsComponent