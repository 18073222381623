import {Inventory, Neighborhood, Room, SeatConfig} from "../API";
import React, {useEffect, useMemo, useState} from "react";
import RoomPickerComponent from "./RoomPickerComponent";
import RoomPlanComponent from "./RoomPlanComponents/RoomPlanComponent";
import {Box, createStyles, makeStyles, Theme} from "@material-ui/core";
import {useSeatConfigList} from "../hooks/useSeatConfigList";
import {useInventoryList} from "../hooks/useInventoryList";
import {InvType} from "../Utils/Enums";
import CollapsibleFilterComponent from "./CollapsibleFilterComponent";
import shadows from "../styles/shadows";
import borders from "../styles/borders";
import uiElementMeasures from "../styles/inputElementMeasures";
import {User} from "../services/UserClient";
import FilterContext from "../context/FilterContext";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useTranslation} from "react-i18next";

interface Props {
    rooms: [] | Room [],
    currentUser: User,
    selectedDate: Date,
    onSelectedDateChange: (newSelectedDate: Date) => void,
    selectedRoom: Room | undefined,
    onSelectedRoomChange: (newSelectedRoom: Room | undefined) => void
}

const SeatBookingComponent: React.FC<Props> = (props) => {
    const {
        rooms,
        currentUser,
        selectedDate,
        onSelectedDateChange,
        selectedRoom,
        onSelectedRoomChange
    } = props;

    const [selectedDockingstation, setSelectedDockingstation] = useState<string>("")
    const [selectedMonitor, setSelectedMonitor] = useState<string>("")
    const [isRoomDropdownFocussed, setIsRoomDropdownFocussed] = useState(false)
    const [isOrgUnitDropdownFocused, setIsOrgUnitDropdownFocused] = useState(false)
    const [isNeighborhoodDropdownFocused, setIsNeighborhoodDropdownFocused] = useState(false)

    const seatConfigurationList = useSeatConfigList((!!selectedRoom ? selectedRoom.roomId : ""))
    const allInventories = useInventoryList()
    const [monitorOptions, setMonitorOptions] = useState<Inventory[]>([])
    const [dockingStationOptions, setDockingStationOptions] = useState<Inventory[]>([])
    const [hasHeightAdjustableDesk, setHasHeightAdjustableDesk] = useState<boolean>(false)
    const [showHeightAdjustableDesks, setShowHeightAdjustableDesks] = useState<boolean>(false)
    const [selectedNeighborhood, setSelectedNeighborhood] = useState<Neighborhood | undefined>();
    //This state variable is used so that when an already selected neighborhood is clicked again, the state changes, and it zooms on the neighborhood
    const [triggerNeighborhoodUpdate, setTriggerNeighborhoodUpdate] = useState<boolean>(false)
    const {
        orgUnitList,
    } = useMainApplicationContext();

    const useStyles = makeStyles<Theme>(theme =>
        createStyles({
            divSeatBooking: {
                display: "flex",
                flexDirection: "column",
                [theme.breakpoints.down("sm")]: {
                    marginTop: "80px"
                }
            },
            divRoomPlan: {
                width: "100%",
                height: "100%",
                display: "flex",
                flexDirection: "column",
                zIndex: 1,
                flexGrow: 1
            },
            divRoomPicker: {
                position: "fixed",
                right: "20px",
                marginTop: "20px",
                height: "fit-content",
                alignSelf: "top",
                justifySelf: "flex-end",
                maxWidth: "370px",
                minWidth: "300px",
                zIndex: 2
            },
            boxSeatBooking: {
                borderRadius: "10px",
                border: borders.containerInputElementBorder,
                boxShadow: shadows.containerInputElementShadow,
                backgroundColor: "white",
                padding: "10px",
            }
        })
    );

    const onSelectedNeighborhoodChange = (neighborhood: Neighborhood | undefined) => {
        setSelectedNeighborhood(neighborhood)
        setTriggerNeighborhoodUpdate(!triggerNeighborhoodUpdate)
    }

    const {t} = useTranslation();
    const emptyMonitor: Inventory = {
        __typename: "Inventory",
        inventoryId: "",
        orgUnitId: "",
        name: t('select_monitor'),
        type: InvType.Monitor,
        createdAt: "0",
        updatedAt: "0",
        nameLowerCased: "keinmonitor",
    };

    const emptyDockingstation: Inventory = {
        __typename: "Inventory",
        inventoryId: "",
        orgUnitId: "",
        name: t('select_docking_station'),
        type: InvType.Dockingstation,
        createdAt: "0",
        updatedAt: "0",
        nameLowerCased: "keinedocking",
    }

    function getInventoriesBySeatList() {
        let ids: string[] = []
        seatConfigurationList.forEach((seat: SeatConfig) => {
            seat.inventory.forEach(
                (inventoryItemId) => {
                    ids = [...ids, inventoryItemId]
                }
            )
        })
        return ids;
    }

    useEffect(() => {
        if (seatConfigurationList.length > 0) {
            setMonitorOptions(
                allInventories.filter(
                    (inventoryItem: Inventory) =>
                        inventoryItem.type === InvType.Monitor &&
                        selectedRoom?.orgUnitId === inventoryItem.orgUnitId
                )
            );

            setDockingStationOptions(
                allInventories.filter(
                    (inventoryItem: Inventory) =>
                        inventoryItem.type === InvType.Dockingstation &&
                        selectedRoom?.orgUnitId === inventoryItem.orgUnitId
                )
            );
            setHasHeightAdjustableDesk(
                seatConfigurationList.some(value => value.isSeatHeightAdjustable)
            );
        }
    }, [selectedRoom, seatConfigurationList, allInventories]);


    /*Effect Name: deselectNonExistingInventoryId() */
    useEffect(() => {
            let ids = getInventoriesBySeatList();
            const currentSelectedMonitor = ids.filter((inventoryId) => inventoryId === selectedMonitor)
            const currentSelectedDockingStation = ids.filter((inventoryId) => inventoryId === selectedDockingstation)

            if (currentSelectedMonitor.length === 0) {
                setSelectedMonitor(emptyMonitor.inventoryId)
            }
            if (currentSelectedDockingStation.length === 0) {
                setSelectedDockingstation(emptyDockingstation.inventoryId)
            }
            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [getInventoriesBySeatList()]
    );

    const classes = useStyles();

    const hasDockingStations = () => {
        return dockingStationOptions.length > 0;
    };

    const hasMonitors = () => {
        return monitorOptions.length > 0;
    };


    const ProviderValue = useMemo(() => {
        return {
            selectedMonitor,
            setSelectedMonitor,
            selectedDockingstation,
            setSelectedDockingstation,
            showHeightAdjustableDesks,
            setShowHeightAdjustableDesks,
            seatConfigurationList,
            monitorOptions,
            dockingStationOptions,
            allInventories,
            isRoomDropdownFocussed,
            setIsRoomDropdownFocussed,
            isOrgUnitDropdownFocused,
            setIsOrgUnitDropdownFocused,
            isNeighborhoodDropdownFocused,
            setIsNeighborhoodDropdownFocused
        }
    }, [allInventories, dockingStationOptions, isOrgUnitDropdownFocused, isRoomDropdownFocussed, isNeighborhoodDropdownFocused, monitorOptions, seatConfigurationList, selectedDockingstation, selectedMonitor, showHeightAdjustableDesks]);

    return (
        <div
            data-testid={"seatBookingComponent"}
            className={classes.divSeatBooking}
        >
            {rooms.length > 0 && orgUnitList.length > 0 ? (
                <React.Fragment>
                    <FilterContext.Provider value={ProviderValue}>
                        <div className={classes.divRoomPlan}>
                            {selectedRoom && (
                                <RoomPlanComponent
                                    selectedNeighborhood={selectedNeighborhood}
                                    triggerNeighborhoodUpdate={triggerNeighborhoodUpdate}
                                    room={selectedRoom}
                                    date={selectedDate}
                                    currentUser={currentUser}
                                />
                            )}
                        </div>
                        <div className={classes.divRoomPicker}>
                            <Box className={classes.boxSeatBooking}>
                                <RoomPickerComponent
                                    rooms={rooms}
                                    selectedDate={selectedDate}
                                    selectedRoom={selectedRoom}
                                    selectedNeighborhood={selectedNeighborhood}
                                    onSelectedRoomChange={onSelectedRoomChange}
                                    setSelectedDate={onSelectedDateChange}
                                    onSelectedNeighborhoodChange={onSelectedNeighborhoodChange}
                                />
                                {selectedRoom &&
                                    (hasDockingStations() ||
                                        hasMonitors() ||
                                        hasHeightAdjustableDesk) && (
                                        <div style={{marginTop: uiElementMeasures.marginBetweenElementsInColumn}}>
                                            <CollapsibleFilterComponent
                                                hasDockingStations={hasDockingStations()}
                                                hasMonitors={hasMonitors()}
                                                hasHeightAdjustableDesk={hasHeightAdjustableDesk}
                                                dockingStationListItems={[
                                                    emptyDockingstation,
                                                    ...dockingStationOptions
                                                ]}
                                                monitorListItems={[emptyMonitor, ...monitorOptions]}
                                            />
                                        </div>
                                    )}
                            </Box>
                        </div>
                    </FilterContext.Provider>
                </React.Fragment>
            ) : (
                t('seat_booking_error_no_rooms_configured')
            )}
        </div>
    );
};
export default SeatBookingComponent;
