import {TimeWindow} from "../types/TimeWindowType";
import {Booking} from "../API";
import {RoomBookingColor} from "../Utils/Enums";
import {useEffect, useState} from "react";
import dayjs from "dayjs";

export default function useRoomColor(roomBookingTimeWindow: TimeWindow[], currentUserID: string, bookings: Booking[] | null | undefined, isTimeBookingActive?: boolean): RoomBookingColor {
    const [roomColor, setRoomColor] = useState<RoomBookingColor>(RoomBookingColor.GREEN)

    useEffect(function updateRoomColor() {
        function hasThreeHourWindow(): boolean {
            const windowDuration = dayjs.duration({"hours": 3})
            return roomBookingTimeWindow.some((timeWindow) => {
                return timeWindow.diff >= windowDuration.asMinutes();
            })
        }

        function hasBookingByMe(bookings: Booking[]): boolean {
            return bookings.some((booking: Booking) => {
                return booking.bookerId === currentUserID
            })
        }

        function handleTimeBookingActiveColors() {
            if (!bookings || bookings.length === 0)
                setRoomColor(RoomBookingColor.GREEN)
            else if (bookings.length > 0 && hasBookingByMe(bookings))
                setRoomColor(RoomBookingColor.BLUE)
            else if (hasThreeHourWindow())
                setRoomColor(RoomBookingColor.YELLOW)
            else if (roomBookingTimeWindow.length > 0)
                setRoomColor(RoomBookingColor.ORANGE)
            else
                setRoomColor(RoomBookingColor.RED)
        }

        function handleBookingColors() {
            if (!bookings || bookings.length === 0)
                setRoomColor(RoomBookingColor.GREEN)
            else if (bookings.length === 1 && hasBookingByMe(bookings))
                setRoomColor(RoomBookingColor.BLUE)
            else
                (setRoomColor(RoomBookingColor.RED))
        }

        isTimeBookingActive ? handleTimeBookingActiveColors() : handleBookingColors()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUserID, isTimeBookingActive, bookings?.length, roomBookingTimeWindow])
    return roomColor
}