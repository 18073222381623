import {SvgIcon} from "@mui/material";

function CalenderIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <g id="Sit2Gether" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Sit2Gether-Calendar-Icon" fill={props.fill}>
                    <path
                        d="M16,2 C17.1045695,2 18,2.8954305 18,4 L18,17 C18,18.1045695 17.1045695,19 16,19 L2,19 C0.8954305,19 1.3527075e-16,18.1045695 0,17 L0,4 C-1.3527075e-16,2.8954305 0.8954305,2 2,2 L16,2 Z M16,7 L2,7 L2,17 L16,17 L16,7 Z"
                        id="primary"></path>
                    <path
                        d="M10,11 L13,11 L13,14 L10,14 L10,11 Z M4,0 C4.55228475,-1.01453063e-16 5,0.44771525 5,1 L5,4 C5,4.55228475 4.55228475,5 4,5 C3.44771525,5 3,4.55228475 3,4 L3,1 C3,0.44771525 3.44771525,1.01453063e-16 4,0 Z M14,0 C14.5522847,-1.01453063e-16 15,0.44771525 15,1 L15,4 C15,4.55228475 14.5522847,5 14,5 C13.4477153,5 13,4.55228475 13,4 L13,1 C13,0.44771525 13.4477153,1.01453063e-16 14,0 Z"
                        id="secondary"></path>
                </g>
            </g>
        </SvgIcon>
    );
}

export default CalenderIcon;

