import React, {useEffect} from "react";
import {Room} from "../../API";
import {
    Button,
    Checkbox,
    Radio,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow
} from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import {gql, useMutation} from "@apollo/client";
import {
    publishRoomAccessChange as publishRoomAccessChangeTemplate,
    updateRoom as updateRoomTemplate
} from "../../graphql/mutations";
import {useErrorContext} from "../../hooks/useErrorContext";
import {useTranslation} from "react-i18next";


interface Props {
    rooms: [] | Room[]
    selectedOrgUnitId: string | undefined,
    selectedRoom: Room | undefined
    setSelectedRoom: (value: Room | undefined) => void
    setShowSeatConfig: (bool: boolean) => void
    setShowRoomHintsConfig: (bool: boolean) => void
    setShowNeighborhoodConfig: (bool: boolean) => void
    handleIsTimeBox: (room: Room, newValue: boolean) => void
}

const RoomManagerTableComponent: React.FC<Props> = (props) => {

    const {
        rooms,
        selectedOrgUnitId,
        selectedRoom,
        setSelectedRoom,
        handleIsTimeBox,
    } = props
    const {reportError} = useErrorContext()
    const [updateRoomMutation] = useMutation(gql(updateRoomTemplate))
    const [publishRoomAccessChange] = useMutation(gql(publishRoomAccessChangeTemplate))
    const roomCompareByName = (a: { room: Room, id: number }, b: {
        room: Room,
        id: number
    }) => (a.room.nameLowerCased > b.room.nameLowerCased) ? 1 : -1
    const getIndexedRoomsListFilteredBySelectedOrgUnitId = (): { room: Room, id: number }[] => {
        return (rooms.map((room: Room, index: number) => ({
            room: room,
            id: index
        })).filter(tuple => tuple.room.orgUnitId === selectedOrgUnitId))
    }
    const roomsSortedPerOrgUnit = [...getIndexedRoomsListFilteredBySelectedOrgUnitId()].sort(roomCompareByName)

    useEffect(() => {
        let isDefaultPresent = false
        roomsSortedPerOrgUnit.forEach((roomWithId: { room: Room, id: number }) => {
                if (roomWithId.room.isDefault)
                    isDefaultPresent = true;
            }
        )
        if (!isDefaultPresent && roomsSortedPerOrgUnit.length !== 0)
            handleIsDefault(roomsSortedPerOrgUnit[0].room)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomsSortedPerOrgUnit])

    useEffect(() => {
        if (roomsSortedPerOrgUnit.length === 1 && roomsSortedPerOrgUnit[0].room.isDefault !== true)
            handleIsDefault(roomsSortedPerOrgUnit[0].room)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomsSortedPerOrgUnit])

    const handleIsActive = (room: Room, newValue: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isActive: newValue
                }
            }
        })
            .then(() => {
                if (!newValue)
                    publishRoomAccessChange({variables: {roomId: room.roomId}}).then()
            })
            .catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsActive"))
    }
    const handleIsPublic = (room: Room, newValue: boolean) => {
        updateRoomMutation({
            variables: {
                input: {
                    roomId: room.roomId,
                    isPublic: newValue
                }
            }
        })
            .then(() => {
                if (!newValue)
                    publishRoomAccessChange({variables: {roomId: room.roomId}}).then()
            })
            .catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsPublic"))
    }
    const handleIsDefault = (room: Room) => {
        rooms.forEach(singleRoom => {
            if (singleRoom.orgUnitId === selectedOrgUnitId) {
                updateRoomMutation({
                    variables: {
                        input: {
                            roomId: singleRoom.roomId,
                            isDefault: singleRoom.roomId === room.roomId
                        }
                    }
                }).then(() => {
                    publishRoomAccessChange({variables: {roomId: room.roomId}}).then()
                }).catch((err) => reportError(err, "", "RoomManagerTableComponent handleIsDefault"))
            }

        })

    }

    const isAnyRoomWithRoomPlan = () => rooms.find(room => room.roomPlanExisting)

    const {t} = useTranslation();
    // TODO: Disable public checkbox if not admin
    return (
        <TableContainer style={{maxHeight: "440px", margin: "25px 0px", tableLayout: "fixed"}}>
            <Table stickyHeader data-testid={"room-manager-table"} style={{margin: "0px", maxWidth: "100%"}}>
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{wordBreak: "break-word"}}
                        >{t("room_name-column")}</TableCell>
                        <TableCell
                            data-testid={"room-plan-column"}>{t("room_plan-column")}
                        </TableCell>
                        <TableCell
                            data-testid={"scale-factor-column"}>{t("scaleFactor-column")}
                        </TableCell>
                        {isAnyRoomWithRoomPlan()
                            && <TableCell
                                data-test-id={"seat-config-cta-column"}>
                            </TableCell>}
                        {isAnyRoomWithRoomPlan()
                            && <TableCell
                                data-test-id={"roomHint-config-cta-column"}>
                            </TableCell>}
                        {isAnyRoomWithRoomPlan() && <TableCell
                            data-test-id={"neighborhood-config-cta-column"}>
                        </TableCell>}
                        <TableCell data-testid={"room_active-column-test"}>
                            {t("room_active-column")}
                        </TableCell>
                        <TableCell
                            data-test-id={"time-config-checkbox"}>{t("room_time-active")}
                        </TableCell>
                        {/*
                             <TableCell
                                data-testid={"public-config-column"}>{t("room_public-active")}
                            </TableCell>
                        */}
                        <TableCell data-testid={"room_default-column-test"}>
                            {t("room_default-column")}
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody data-testid={"room-list"}>
                    {roomsSortedPerOrgUnit.map((roomWithId, index) =>
                        (<TableRow data-testid={`room-${roomWithId.id}`}
                                   onClick={() => setSelectedRoom(rooms[roomWithId.id])}
                                   key={index}
                                   selected={rooms[roomWithId.id].roomId === (selectedRoom && selectedRoom.roomId)}>
                            <TableCell
                                style={{maxWidth: "150px", wordBreak: "break-word"}}>
                                {roomWithId.room.name}
                            </TableCell>
                            <TableCell>
                                {roomWithId.room.roomPlanExisting!! &&
                                    <CheckCircleIcon style={{color: "green"}}
                                                     data-testid={"check-mark"}/>}
                            </TableCell>
                            <TableCell>
                                {roomWithId.room.roomPlanScaleFactor ?
                                    roomWithId.room.roomPlanScaleFactor :
                                    ""
                                }
                            </TableCell>
                            {isAnyRoomWithRoomPlan() && <TableCell>
                                {roomWithId.room.roomPlanExisting &&
                                    <Button
                                        onClick={() => props.setShowSeatConfig(true)}
                                        color={"primary"}
                                        variant={"contained"}
                                        data-testid={"seats-button"}
                                        disabled={roomWithId.room !== selectedRoom}
                                    >
                                        {t('seats')}
                                    </Button>}
                            </TableCell>}
                            {isAnyRoomWithRoomPlan()
                                && <TableCell>
                                    {roomWithId.room.roomPlanExisting && roomWithId.room.hasHints &&
                                        <Button
                                            onClick={() => props.setShowRoomHintsConfig(true)}
                                            color={"primary"}
                                            variant={"contained"}
                                            data-testid={"roomHints-button"}
                                            disabled={roomWithId.room !== selectedRoom}
                                        >
                                            {t('rooms')}
                                        </Button>}
                                </TableCell>}
                            {isAnyRoomWithRoomPlan()
                                && <TableCell>
                                {roomWithId.room.roomPlanExisting && roomWithId.room.hasNeighborhood && (
                                    <Button
                                        onClick={() => props.setShowNeighborhoodConfig(true)}
                                        color={"primary"}
                                        variant={"contained"}
                                        data-testid={`neighborhoods-button-${index}`}
                                        disabled={roomWithId.room !== selectedRoom}
                                    >
                                        {t('neighborhoods')}
                                    </Button>)}
                            </TableCell>}
                            <TableCell>
                                <Checkbox
                                    name={"isActive"}
                                    checked={!!roomWithId.room.isActive}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsActive(roomWithId.room, event.target.checked)}
                                    //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                    onClick={(e) => e.stopPropagation()}
                                    data-testid={`room_active-checkbox-test-${roomWithId.id}`}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    name={"isTimeActive"}
                                    checked={!!roomWithId.room.isTimeActive}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsTimeBox(roomWithId.room, event.target.checked)}
                                    //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                    onClick={(e) => e.stopPropagation()}
                                    data-testid={`checkbox-isTime-${index}`}
                                />
                            </TableCell>
                            {/*
                            <TableCell>
                                <Checkbox
                                    name={"isPublic"}
                                    checked={roomWithId.room.isPublic || false}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleIsPublic(roomWithId.room, event.target.checked)}
                                    //stopPropagation wurde eingefügt, damit die TableRow beim Klicken der Checkbox nicht getriggert wird und die Buttons inaktiv bleiben
                                    onClick={(e) => e.stopPropagation()}
                                    data-testid={`room_public-checkbox-test-${roomWithId.id}`}
                                />
                            </TableCell>
                        */}
                            {
                                !roomWithId.room.orgUnitId?.includes("keine") && <TableCell>
                                    <Radio
                                        onChange={() => handleIsDefault(roomWithId.room)}
                                        onClick={(e) => e.stopPropagation()}
                                        key={roomWithId.id}
                                        checked={!!roomWithId.room.isDefault}
                                    />
                                </TableCell>
                            }

                        </TableRow>)
                    )}
                </TableBody>
            </Table>
        </TableContainer>
    )

}

export default RoomManagerTableComponent;