import {gql, useQuery} from "@apollo/client";
import {onUpdateSeatConfig} from "../graphql/subscriptions";
import {SeatConfig} from "../API"
import {getRoomSeats} from "../graphqlCustom/queriesCustom";
import {useEffect} from "react";
import {useErrorContext} from "./useErrorContext";

export function useSeatConfigList(roomId: string): [SeatConfig] | [] {

    const {data, subscribeToMore} = useQuery(gql(getRoomSeats), {
        variables: {
            roomId: roomId,
        }, skip: (roomId === "")
    });

    const {reportError} = useErrorContext()

    useEffect(function subscribe() {
            const unsubscribeOnUpdateSeatConfig = subscribeToMore({

                document: gql(onUpdateSeatConfig),
                variables: {roomId: roomId},
                updateQuery: (prev, {subscriptionData}) => {
                    if (prev.getRoom) {
                        if (!subscriptionData.data) return prev;
                        const updatedSeat: SeatConfig = subscriptionData.data.onUpdateSeatConfig;
                        let seatAlreadyInList = false;
                        let newSeatList = prev.getRoom.seats.items.map((seat: SeatConfig) => {
                            if (seat.seatName === updatedSeat.seatName && seat.roomId === updatedSeat.roomId) {
                                seatAlreadyInList = true
                                return updatedSeat
                            }
                            return seat
                        })
                        if (!seatAlreadyInList) {
                            newSeatList.push(updatedSeat)
                        }
                        return Object.assign({}, prev, {
                            getRoom: {
                                seats: {
                                    items: newSeatList
                                },
                                nextToken: null
                            }
                        })
                    }
                },
                onError: (error: any) => reportError(error, "", "useSeatConfig onUpdateSeatConfigSubscription")
            });

            return () => unsubscribeOnUpdateSeatConfig();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , [subscribeToMore]);

    function comparator(a: SeatConfig, b: SeatConfig) {
        return a.seatName.localeCompare(b.seatName, 'en', {numeric: true})
    }

    return data?.getRoom?.seats?.items.sort(comparator) ?? [];
}