import {Autocomplete} from '@material-ui/lab';
import {Box, makeStyles, TextField} from '@material-ui/core';
import React from "react";

interface Props {
    options: string[];
    label?: string;
    onChange?: (selectedValues: string[]) => void;
    selectedValues?: string[] | null;
}

const useStyles = makeStyles({
    popper: {
        maxWidth: '500px',
        maxHeight: '250px',
    },
    inputRoot: {
        width: '500px',
    }
});

const MultiSelectComboBox: React.FC<Props> = ({options, label, onChange, selectedValues}) => {
    const classes = useStyles();

    return (
        <Box m={2}>
            <Autocomplete
                classes={{
                    inputRoot: classes.inputRoot,
                    paper: classes.popper
                }}
                multiple
                id="multi-select-combo-box"
                options={options}
                getOptionLabel={(option) => option}
                onChange={(_, newValue) => {
                    if (onChange) onChange(newValue);
                }}
                value={selectedValues ?? []}
                renderInput={(params) => <TextField {...params} variant="outlined" label={label ?? "Select"}/>}
            />
        </Box>
    );
};

export default MultiSelectComboBox;
