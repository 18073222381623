import {FileTypes} from "./Enums";
import {BookingInfo} from "../types/BookingInfoType";
import dayjs, {Dayjs} from "dayjs";
import {TimeWindow} from "../types/TimeWindowType";


const detectSVG = require("detect-svg");

export const isSvgFileValid = (fileName: string, fileContent: string): boolean => {
    const fileExtension = fileName.split(".").pop();
    if (fileExtension !== FileTypes.SVG) {
        return false;
    }
    return detectSVG(fileContent);
}

const getCookieValue = (name: string) => (
    document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)')?.pop() || ''
)

export const getCognitoIdTokenFromLocalStorage = (): string => {
    return getCookieValue(".*LastAuthUser")
}

/**
 * Returns all items from L1 that are not in L2
 */
export const getRelativeComplement = (L1: string[], L2: string[]): string[] => {
    let surplus: string[] = []
    L1.forEach(el => {
        if (L2.indexOf(el) === -1) {
            surplus.push(el)
        }
    })
    return surplus;
}

//Compares elements from the current room plan against the newly uploaded and returns which ones to create or delete
export const getElementsToAddAndDelete = (currentList: string[], newList: string[]): string[][] => {
    let toAdd: string[] = newList.filter(el => currentList.indexOf(el) === -1)
    let toDelete: string[] = currentList.filter(el => newList.indexOf(el) === -1)
    return [toAdd, toDelete];
};

/**
 * Returns intersection of items from L1 anr items from L2.
 */
export const getIntersection = (L1: string[], L2: string[]): string[] => {
    let intersectionList: string[] = []
    L1.forEach(el => {
        if (L2.indexOf(el) > -1) {
            intersectionList.push(el)
        }
    })
    return intersectionList;
}

function handleEndOfDay(endOfDay: dayjs.Dayjs, lastEndTime: dayjs.Dayjs, timeWindowsWithDiff: TimeWindow[], currentDate: dayjs.Dayjs) {
    if (endOfDay.diff(lastEndTime) > 0)
        timeWindowsWithDiff.push({
            diff: endOfDay.diff(lastEndTime, "minutes"),
            start: lastEndTime,
            end: endOfDay
        })
}

function extractFreeTimeWindow(booking: BookingInfo, lastEndTime: dayjs.Dayjs, bookingEndTime: dayjs.Dayjs, timeWindowsWithDiff: TimeWindow[], bookingStartTime: dayjs.Dayjs) {
    if (booking.timeStart.isSame(lastEndTime, "minute")) {
        lastEndTime = bookingEndTime;
    }
    if (booking.timeStart.isAfter(lastEndTime, "minute")) {
        timeWindowsWithDiff.push({
            diff: bookingStartTime.diff(lastEndTime, "minutes"),
            start: lastEndTime,
            end: bookingStartTime
        })
        lastEndTime = bookingEndTime;
    }
    return lastEndTime;
}

export function getAllOpenTimeWindowsInMinutes(bookings: BookingInfo[]) {
    let timeWindowsWithDiff: TimeWindow[] = []
    if (bookings.length > 0) {
        let currentDate: Dayjs = dayjs(bookings[0].date);
        let endOfDay: Dayjs = dayjs("23:59", "HH:mm").date(currentDate.date()).month(currentDate.month())
        let lastEndTime: Dayjs = dayjs("00:00", "HH:mm").date(currentDate.date()).month(currentDate.month())
        bookings.forEach((booking: BookingInfo) => {
            let currentBookingDate = dayjs(booking.date)
            let bookingStartTime = booking.timeStart
            let bookingEndTime = booking.timeEnd
            if (currentBookingDate.isSame(currentDate, "day")) {
                lastEndTime = extractFreeTimeWindow(booking, lastEndTime, bookingEndTime, timeWindowsWithDiff, bookingStartTime);
            } else if (currentBookingDate.isAfter(currentDate, "day")) {
                handleEndOfDay(endOfDay, lastEndTime, timeWindowsWithDiff, currentDate);
                lastEndTime = dayjs(booking.date).hour(0).minute(0)
                endOfDay = endOfDay.month(currentBookingDate.month()).date(currentBookingDate.date())
                currentDate = dayjs(booking.date);
                lastEndTime = extractFreeTimeWindow(booking, lastEndTime, bookingEndTime, timeWindowsWithDiff, bookingStartTime);
            }
        })
        if (!lastEndTime.isSame(lastEndTime.minute(0).hour(0), "minute"))
            handleEndOfDay(endOfDay, lastEndTime, timeWindowsWithDiff, currentDate);
        return timeWindowsWithDiff;
    }
    return []
}

export const comparatorAlphanumericValues = (value: string, compValue: string) => {
    return value.localeCompare(compValue, "en", {numeric: true})
}
