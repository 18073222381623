import React, {useEffect, useState} from "react";
import TextSnippetOutlinedIcon from '@mui/icons-material/TextSnippetOutlined';
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext";
import HintIcon from "../../styles/HintIcon";

export interface HintInterface {
    hintId?: string
    roomId?: string
    hintIconPosition: Position
    hintIconDimension: Dimensions
    text: string
}

interface Props {
    hint: HintInterface
}

type Position = {
    x: number,
    y: number,
    transform: string | undefined
}

type Dimensions = {
    width: number,
    height: number
}

const OfficeHintComponent = (props: Props) => {
    const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);
    const {setItemsToDisplay} = useRoomPlanContext()
    const onMouseEnterFunc = () => {
        setIsMouseOverIcon(true);
    };

    const onMouseLeaveFunc = () => {
        setIsMouseOverIcon(false);
    };

    useEffect(function showMouseOver() {
        let mouseOverItems: itemToDisplay[] = [];
        if (isMouseOverIcon) {
            mouseOverItems = [{icon: HintIcon(), text: props.hint.text, isHint: true}];
        }
        setItemsToDisplay(mouseOverItems)
    }, [setItemsToDisplay, isMouseOverIcon, props.hint.text])

    return (
        <g className="room-hint-icon" id={props.hint.hintId}>
            <svg x={props.hint.hintIconPosition.x} y={props.hint.hintIconPosition.y}
                 onMouseEnter={onMouseEnterFunc} onMouseLeave={onMouseLeaveFunc}>
                <rect width={props.hint.hintIconDimension.width}
                      height={props.hint.hintIconDimension.height}
                      style={{"fill": "transparent"}}/>
                <TextSnippetOutlinedIcon stroke="black" width={props.hint.hintIconDimension.width}
                                         height={props.hint.hintIconDimension.height}
                                         visibility={"visible"} strokeWidth={0.3}/>
            </svg>
        </g>)
};

export default OfficeHintComponent;