/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateRoom = /* GraphQL */ `
  subscription OnCreateRoom($orgUnitId: ID, $isActive: Boolean) {
    onCreateRoom(orgUnitId: $orgUnitId, isActive: $isActive) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoom = /* GraphQL */ `
  subscription OnUpdateRoom($orgUnitId: ID, $isActive: Boolean) {
    onUpdateRoom(orgUnitId: $orgUnitId, isActive: $isActive) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoom = /* GraphQL */ `
  subscription OnDeleteRoom($orgUnitId: ID, $isActive: Boolean) {
    onDeleteRoom(orgUnitId: $orgUnitId, isActive: $isActive) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoomOrgUnitAdmin = /* GraphQL */ `
  subscription OnCreateRoomOrgUnitAdmin($orgUnitId: ID) {
    onCreateRoomOrgUnitAdmin(orgUnitId: $orgUnitId) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoomOrgUnitAdmin = /* GraphQL */ `
  subscription OnUpdateRoomOrgUnitAdmin($orgUnitId: ID) {
    onUpdateRoomOrgUnitAdmin(orgUnitId: $orgUnitId) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoomOrgUnitAdmin = /* GraphQL */ `
  subscription OnDeleteRoomOrgUnitAdmin($orgUnitId: ID) {
    onDeleteRoomOrgUnitAdmin(orgUnitId: $orgUnitId) {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onCreateRoomAdmin = /* GraphQL */ `
  subscription OnCreateRoomAdmin {
    onCreateRoomAdmin {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateRoomAdmin = /* GraphQL */ `
  subscription OnUpdateRoomAdmin {
    onUpdateRoomAdmin {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteRoomAdmin = /* GraphQL */ `
  subscription OnDeleteRoomAdmin {
    onDeleteRoomAdmin {
      roomId
      orgUnitId
      orgUnitAdmin
      name
      nameLowerCased
      roomPlanExisting
      roomPlanId
      roomPlanScaleFactor
      seats {
        nextToken
      }
      isActive
      isTimeActive
      isBookingDisabled
      isDefault
      isPublic
      hasHints
      hasNeighborhood
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBooking = /* GraphQL */ `
  subscription OnUpdateBooking($roomId: ID!, $orgUnitId: ID!, $date: AWSDate) {
    onUpdateBooking(roomId: $roomId, orgUnitId: $orgUnitId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBooking = /* GraphQL */ `
  subscription OnDeleteBooking($roomId: ID!, $orgUnitId: ID!, $date: AWSDate) {
    onDeleteBooking(roomId: $roomId, orgUnitId: $orgUnitId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookingWithTime = /* GraphQL */ `
  subscription OnCreateBookingWithTime(
    $roomId: ID!
    $orgUnitId: ID!
    $timeBegin: AWSDateTime
    $timeEnd: AWSDateTime
    $date: AWSDate
  ) {
    onCreateBookingWithTime(
      roomId: $roomId
      orgUnitId: $orgUnitId
      timeBegin: $timeBegin
      timeEnd: $timeEnd
      date: $date
    ) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingWithTime = /* GraphQL */ `
  subscription OnDeleteBookingWithTime(
    $roomId: ID!
    $orgUnitId: ID!
    $timeBegin: AWSDateTime
    $timeEnd: AWSDateTime
    $date: AWSDate
  ) {
    onDeleteBookingWithTime(
      roomId: $roomId
      orgUnitId: $orgUnitId
      timeBegin: $timeBegin
      timeEnd: $timeEnd
      date: $date
    ) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookingWithoutDate = /* GraphQL */ `
  subscription OnCreateBookingWithoutDate($roomId: ID!, $orgUnitId: ID!) {
    onCreateBookingWithoutDate(roomId: $roomId, orgUnitId: $orgUnitId) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingWithoutDate = /* GraphQL */ `
  subscription OnDeleteBookingWithoutDate($roomId: ID!, $orgUnitId: ID!) {
    onDeleteBookingWithoutDate(roomId: $roomId, orgUnitId: $orgUnitId) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookingWithoutRoom = /* GraphQL */ `
  subscription OnCreateBookingWithoutRoom($orgUnitId: ID!, $date: AWSDate) {
    onCreateBookingWithoutRoom(orgUnitId: $orgUnitId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingWithoutRoom = /* GraphQL */ `
  subscription OnDeleteBookingWithoutRoom($orgUnitId: ID!, $date: AWSDate) {
    onDeleteBookingWithoutRoom(orgUnitId: $orgUnitId, date: $date) {
      bookingId
      date
      seatId
      roomId
      room {
        roomId
        orgUnitId
        orgUnitAdmin
        name
        nameLowerCased
        roomPlanExisting
        roomPlanId
        roomPlanScaleFactor
        isActive
        isTimeActive
        isBookingDisabled
        isDefault
        isPublic
        hasHints
        hasNeighborhood
        createdAt
        updatedAt
      }
      bookerId
      bookerName
      bookerGivenName
      bookerFamilyName
      orgUnitId
      orgUnitAdmin
      bookingFor
      timeBegin
      timeEnd
      isMeetingRoomBooking
      meetingName
      roomCapacity
      participantNumber
      meetingType
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookings = /* GraphQL */ `
  subscription OnCreateBookings($roomId: ID!, $orgUnitId: ID!, $date: AWSDate) {
    onCreateBookings(roomId: $roomId, orgUnitId: $orgUnitId, date: $date) {
      Items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        isMeetingRoomBooking
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const onCreateBookingsWithTime = /* GraphQL */ `
  subscription OnCreateBookingsWithTime(
    $roomId: ID!
    $orgUnitId: ID!
    $date: AWSDate
    $timeBegin: AWSDateTime
    $timeEnd: AWSDateTime
  ) {
    onCreateBookingsWithTime(
      roomId: $roomId
      orgUnitId: $orgUnitId
      date: $date
      timeBegin: $timeBegin
      timeEnd: $timeEnd
    ) {
      Items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        isMeetingRoomBooking
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const onCreateBookingsWithoutDate = /* GraphQL */ `
  subscription OnCreateBookingsWithoutDate($roomId: ID!, $orgUnitId: ID!) {
    onCreateBookingsWithoutDate(roomId: $roomId, orgUnitId: $orgUnitId) {
      Items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        isMeetingRoomBooking
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const onCreateBookingsWithoutRoom = /* GraphQL */ `
  subscription OnCreateBookingsWithoutRoom(
    $orgUnitId: ID!
    $date: AWSDate!
    $roomId: ID
  ) {
    onCreateBookingsWithoutRoom(
      orgUnitId: $orgUnitId
      date: $date
      roomId: $roomId
    ) {
      Items {
        bookingId
        date
        seatId
        roomId
        bookerId
        bookerName
        bookerGivenName
        bookerFamilyName
        orgUnitId
        orgUnitAdmin
        bookingFor
        timeBegin
        timeEnd
        isMeetingRoomBooking
        meetingName
        roomCapacity
        participantNumber
        meetingType
        createdAt
        updatedAt
      }
      dates
      roomId
      orgUnitId
    }
  }
`;
export const onRoomAccessChange = /* GraphQL */ `
  subscription OnRoomAccessChange {
    onRoomAccessChange
  }
`;
export const onCreateSecureInventory = /* GraphQL */ `
  subscription OnCreateSecureInventory {
    onCreateSecureInventory {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateHint = /* GraphQL */ `
  subscription OnCreateHint($roomId: ID!) {
    onCreateHint(roomId: $roomId) {
      hintId
      roomId
      text
      orgUnitAdmin
      orgUnitId
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateHint = /* GraphQL */ `
  subscription OnUpdateHint($roomId: ID!) {
    onUpdateHint(roomId: $roomId) {
      hintId
      roomId
      text
      orgUnitAdmin
      orgUnitId
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteHint = /* GraphQL */ `
  subscription OnDeleteHint($roomId: ID!) {
    onDeleteHint(roomId: $roomId) {
      hintId
      roomId
      text
      orgUnitAdmin
      orgUnitId
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNeighborhoodByRoom = /* GraphQL */ `
  subscription OnCreateNeighborhoodByRoom($roomId: ID!) {
    onCreateNeighborhoodByRoom(roomId: $roomId) {
      neighborhoodId
      roomId
      orgUnitIds
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNeighborhoodByRoom = /* GraphQL */ `
  subscription OnUpdateNeighborhoodByRoom($roomId: ID!) {
    onUpdateNeighborhoodByRoom(roomId: $roomId) {
      neighborhoodId
      roomId
      orgUnitIds
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNeighborhoodByRoom = /* GraphQL */ `
  subscription OnDeleteNeighborhoodByRoom($roomId: ID!) {
    onDeleteNeighborhoodByRoom(roomId: $roomId) {
      neighborhoodId
      roomId
      orgUnitIds
      createdAt
      updatedAt
    }
  }
`;
export const onCreateInventory = /* GraphQL */ `
  subscription OnCreateInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onCreateInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateInventory = /* GraphQL */ `
  subscription OnUpdateInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onUpdateInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteInventory = /* GraphQL */ `
  subscription OnDeleteInventory(
    $filter: ModelSubscriptionInventoryFilterInput
  ) {
    onDeleteInventory(filter: $filter) {
      inventoryId
      orgUnitAdmin
      orgUnitId
      type
      name
      nameLowerCased
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSeatConfig = /* GraphQL */ `
  subscription OnCreateSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onCreateSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSeatConfig = /* GraphQL */ `
  subscription OnUpdateSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onUpdateSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSeatConfig = /* GraphQL */ `
  subscription OnDeleteSeatConfig(
    $filter: ModelSubscriptionSeatConfigFilterInput
  ) {
    onDeleteSeatConfig(filter: $filter) {
      seatName
      orgUnitAdmin
      roomId
      seatType
      inventory
      isSeatHeightAdjustable
      owner
      isOwnerRegistered
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBookingConfig = /* GraphQL */ `
  subscription OnCreateBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onCreateBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateBookingConfig = /* GraphQL */ `
  subscription OnUpdateBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onUpdateBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteBookingConfig = /* GraphQL */ `
  subscription OnDeleteBookingConfig(
    $filter: ModelSubscriptionBookingConfigFilterInput
  ) {
    onDeleteBookingConfig(filter: $filter) {
      orgUnitId
      orgUnitAdmin
      maxDaysInAdvance
      maxBookableDays
      createdAt
      updatedAt
    }
  }
`;
export const onCreateMailConfig = /* GraphQL */ `
  subscription OnCreateMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onCreateMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateMailConfig = /* GraphQL */ `
  subscription OnUpdateMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onUpdateMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteMailConfig = /* GraphQL */ `
  subscription OnDeleteMailConfig(
    $filter: ModelSubscriptionMailConfigFilterInput
    $userId: String
  ) {
    onDeleteMailConfig(filter: $filter, userId: $userId) {
      userId
      userMail
      isReceiveOwnBooking
      isReceiveForeignBooking
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAreBookingsTimeDataUpdated = /* GraphQL */ `
  subscription OnCreateAreBookingsTimeDataUpdated(
    $filter: ModelSubscriptionAreBookingsTimeDataUpdatedFilterInput
  ) {
    onCreateAreBookingsTimeDataUpdated(filter: $filter) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAreBookingsTimeDataUpdated = /* GraphQL */ `
  subscription OnUpdateAreBookingsTimeDataUpdated(
    $filter: ModelSubscriptionAreBookingsTimeDataUpdatedFilterInput
  ) {
    onUpdateAreBookingsTimeDataUpdated(filter: $filter) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAreBookingsTimeDataUpdated = /* GraphQL */ `
  subscription OnDeleteAreBookingsTimeDataUpdated(
    $filter: ModelSubscriptionAreBookingsTimeDataUpdatedFilterInput
  ) {
    onDeleteAreBookingsTimeDataUpdated(filter: $filter) {
      id
      didUpdate
      createdAt
      updatedAt
    }
  }
`;
