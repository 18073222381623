import React, {ChangeEvent, useEffect, useRef, useState} from "react";
import {IconButton, makeStyles, Paper, TextField} from "@material-ui/core";
import Box from "@mui/material/Box";
import {Tooltip} from "@mui/material";
import CancelIcon from "@material-ui/icons/Cancel";
import SaveIcon from "@material-ui/icons/Save";
import {InvType} from "../Utils/Enums";
import {gql, useMutation, useQuery} from "@apollo/client";
import {createSecureInventory, updateInventory} from "../graphql/mutations";
import {inventoryByLowerCasedName as inventoryByLowerCasedNameTemplate} from "../graphql/queries";
import {Inventory} from "../API";
import {v4 as uuidv4} from 'uuid';
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";

interface Props {
    setIsShowCreateInv: (value: boolean) => void
    type: InvType
    selectedInv: Inventory | undefined
    setSelectedInv: (value: Inventory | undefined) => void
    setIsShowAlert: (value: boolean) => void
}

const CreateInventoryComponent: React.FC<Props> = (props) => {

    const {setIsShowCreateInv, type, selectedInv, setIsShowAlert, setSelectedInv} = props;
    const {selectedOrgUnit} = useMainApplicationContext();
    const {reportError} = useErrorContext();
    const [newInventoryName, setNewInventoryName] = useState(() => {
        if (selectedInv) {
            return selectedInv.name
        }
        return ""
    })

    const [createInventoryMutation] = useMutation(gql(createSecureInventory))
    const [updateInventoryMutation] = useMutation(gql(updateInventory))

    const {refetch} = useQuery(gql(inventoryByLowerCasedNameTemplate))

    useEffect(function focusTextFieldWhenRendered() {
        if (nameInputRef?.current) nameInputRef.current.focus();
    }, [])

    useEffect(function updateTextFieldValue() {
        setNewInventoryName(selectedInv ? selectedInv.name : "")
    }, [selectedInv])

    const nameInputRef = useRef<HTMLInputElement>(null);

    const showErrorMessage = () => {
        setIsShowAlert(true)
        setTimeout(() => {
            setIsShowAlert(false)
        }, 4000)
    }

    const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        setNewInventoryName(event.target.value)
    }

    const isConflictingWithInventoryNamesInOrgUnit = async (): Promise<boolean> => {
        //This query returns all inventory items with the same name as the currently typed name in the input box
        const queryResult = await refetch({
            nameLowerCased: newInventoryName.trim().toLocaleLowerCase(),
            filter: {
                type: {
                    eq: type
                }
            }
        }).catch((err) => reportError(err, "", "CreateInventoryComponent handleSave"))

        // optional chaining is not working here, because TS does not know the structure of queryResult since it has the type any
        const hasData: boolean = queryResult !== undefined && queryResult.data &&
            queryResult.data.inventoryByLowerCasedName &&
            queryResult.data.inventoryByLowerCasedName.items.length > 0

        if (!hasData) return false;

        // @ts-ignore
        const data = queryResult.data.inventoryByLowerCasedName.items

        return data.some((item: Inventory) =>
            item.orgUnitId === selectedOrgUnit?.orgId &&
            item.nameLowerCased.trim() === newInventoryName.toLocaleLowerCase().trim())
    }

    const handleSave = async () => {
        const isConflicting = await isConflictingWithInventoryNamesInOrgUnit()
        if (isConflicting) {
            showErrorMessage()
            return
        }

        if (selectedInv) handleUpdateInventory()
        else handleCreateInventory()


        setSelectedInv(undefined)
    }

    const handleCreateInventory = () => {
        createInventoryMutation({
            variables: {
                input: {
                    inventoryId: uuidv4(),
                    orgUnitId: selectedOrgUnit?.orgId,
                    orgUnitAdmin: "Admin-" + selectedOrgUnit?.orgId,
                    type: type,
                    name: newInventoryName.trim(),
                    nameLowerCased: newInventoryName.trim().toLocaleLowerCase(),
                }
            }
        })
            .then(() => setIsShowCreateInv(false))
            .catch((err) => reportError(err, "", "CreateInventoryComponent handleCreateInventory"))
    }

    const handleUpdateInventory = () => {
        updateInventoryMutation({
            variables: {
                input: {
                    inventoryId: selectedInv!.inventoryId,
                    orgUnitId: selectedOrgUnit?.orgId,
                    orgUnitAdmin: "Admin-" + selectedOrgUnit?.orgId,
                    type: type,
                    name: newInventoryName.trim(),
                    nameLowerCased: newInventoryName.trim().toLocaleLowerCase(),
                }
            }
        })
            .then(() => setIsShowCreateInv(false))
            .catch((err) => reportError(err, "", "CreateInventoryComponent handleUpdateInventory"))
    }

    const useStyles = makeStyles({
        btnEnabled: {
            color: "green"
        },
        btnDisabled: {
            color: "disabled"
        },
    });

    const classes = useStyles();

    const MAX_ITEM_NAME_LENGTH = 100;
    const isNewInventoryNameValid = (newInventoryName.trim().length > 0 && newInventoryName.trim().length <= MAX_ITEM_NAME_LENGTH);

    const styles = {
        paperStyle: {
            marginTop: "0.625rem",
            padding: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
        },
        textFieldBoxStyle: {
            paddingTop: "1rem",
            display: "flex",
            justifyContent: "flex-start",
            width: "74%"
        },
        toolTipsBoxStyle: {
            paddingTop: "1rem",
            display: "flex",
            justifyContent: "flex-end",
            width: "25%"
        }
    }
    const {t} = useTranslation();

    return (
        <Paper style={styles.paperStyle} data-testid={"createInventoryComponent"}>
            <Box style={styles.textFieldBoxStyle}>
                <TextField
                    style={{marginRight: "10px", width: "100%"}}
                    label={type}
                    value={newInventoryName}
                    onChange={handleInputChange}
                    inputRef={nameInputRef}
                    inputProps={{"data-testid": "inventoryName-text"}}
                />
            </Box>

            <Box style={styles.toolTipsBoxStyle}>
                <Tooltip title={t("confirm_dialog_ok_button-text")}>
                    <span>
                        <IconButton onClick={handleSave} disabled={!isNewInventoryNameValid} data-testid={"save-btn"}>
                            <SaveIcon className={isNewInventoryNameValid ? classes.btnEnabled : classes.btnDisabled} style={{cursor: "pointer"}} fontSize="large"/>
                        </IconButton>
                    </span>
                </Tooltip>
                <Tooltip title={t("confirm_dialog_cancel_button-text")}>
                    <IconButton onClick={() => setIsShowCreateInv(false)} data-testid={"cancel-btn"}>
                        <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"/>
                    </IconButton>
                </Tooltip>
            </Box>
        </Paper>
    )
}
export default CreateInventoryComponent;