import {Inventory} from "../API";
import {gql, useQuery} from "@apollo/client";
import {listInventories} from "../graphql/queries";
import {useEffect} from "react";
import {onCreateInventory, onDeleteInventory, onUpdateInventory, onCreateSecureInventory} from "../graphql/subscriptions";
import {comparatorAlphanumericValues} from "../Utils/Helpers";

export function useInventoryList() {
    const {data, subscribeToMore} = useQuery(gql(listInventories));

    useEffect(function subscribe() {
        const unsubscribeOnCreateInventory = subscribeToMore({
            document: gql(onCreateSecureInventory),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newInv = subscriptionData.data.onCreateSecureInventory;
                return (Object.assign({}, prev, {
                        listInventories: {
                            items: [...prev.listInventories.items, newInv],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => console.error("useInventoryList useEffect:subscribeToMore:onCreateInventory " + JSON.stringify(error))
        });
        const unsubscribeOnDeleteInventory = subscribeToMore({
            document: gql(onDeleteInventory),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedInvId = subscriptionData.data.onDeleteInventory.inventoryId;
                return Object.assign({}, prev, {
                    listInventories: {
                        items: prev.listInventories.items.filter((item: Inventory) => (item.inventoryId !== deletedInvId)),
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error("useInventoryList useEffect:subscribeToMore:onDeleteInventory " + JSON.stringify(error))

        });
        const unsubscribeOnUpdateInventory = subscribeToMore({
            document: gql(onUpdateInventory),
            variables: {},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedInv: Inventory = subscriptionData.data.onUpdateInventory;
                let invAlreadyInList = false;
                let newInvList = prev.listInventories.items.map((item: Inventory) => {
                    if (item.inventoryId === updatedInv.inventoryId) {
                        invAlreadyInList = true
                        return updatedInv
                    }
                    return item
                })
                if (!invAlreadyInList) {
                    newInvList.push(updatedInv)
                }
                return Object.assign({}, prev, {
                    listInventories: {
                        items: newInvList,
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error("useInventoryList useEffect:subscribeToMore:onUpdateInventory " + JSON.stringify(error))

        });

        function unsubscribe() {
            unsubscribeOnCreateInventory();
            unsubscribeOnDeleteInventory();
            unsubscribeOnUpdateInventory();
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function comparator(item1: Inventory, item2: Inventory) {
        return comparatorAlphanumericValues(item1.name, item2.name)
    }

    return data?.listInventories?.items.sort(comparator) ?? [];

}