export enum FileTypes {
    SVG = "svg"
}

export enum BookingFor {
    BOOKING_FOR_ME = 'BOOKING_FOR_ME',
    BOOKING_FOR_OTHERS = 'BOOKING_FOR_OTHERS'
}

export enum MeetingType {
    INTERNAL = 'INTERNAL',
    EXTERNAL = 'EXTERNAL',
    VIP = 'VIP'
}

export enum InvType {
    Dockingstation = "Dockingstation",
    Monitor = "Monitor"
}

export enum BookedByStatus {
    Past,
    Bookable,
    CurrentUser,
    NotAvailable,
    TimeSlotUndecided
}

export enum SeatBookingColor {
    RED = "seatStyleBooked",
    ORANGE = "seatStyleOrange",
    YELLOW = "seatStyleYellow",
    GREEN = "seatStyleNotBooked",
    BLUE = "seatStyleBookedByMe",
}

export enum RoomBookingColor {
    RED = "roomStyleBooked",
    ORANGE = "roomStyleOrange",
    YELLOW = "roomStyleYellow",
    GREEN = "roomStyleNotBooked",
    BLUE = "roomStyleBookedByMe",
}