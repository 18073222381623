import {BookingConfigDays} from "../../types/BookingConfigDaysTypes";
import {createNewTodayDateWithoutHours, toDateISO} from "../../services/DateUtils";
import {Booking, Room} from "../../API";

const today: Date = createNewTodayDateWithoutHours()

//Vorlauf-Konfig
export function getGreatestBookableDate(bookingConfig: BookingConfigDays): Date {
    if (bookingConfig && bookingConfig.maxDaysInAdvance !== 0) {
        let tempEndDate: Date = new Date();
        tempEndDate.setDate(today.getDate() + bookingConfig.maxDaysInAdvance)
        return tempEndDate
    } else {
        return new Date(2050, 12, 1)
    }
}

//Vorlauf-Konfig
export function handleLimitOfBookings(withoutSelected: boolean, customBookedDaysArr: Date[], selectedDates: Date[], bookingConfig: BookingConfigDays): boolean {
    let tempArr = [...customBookedDaysArr.map(value => toDateISO(value))];
    if (!withoutSelected) {
        tempArr = [...selectedDates.map(value => toDateISO(value)), ...tempArr];
    }

    const newBookingCount = tempArr.filter((value, index) => tempArr.indexOf(value) === index).length

    if (bookingConfig.maxBookableDays === 0) {
        return false
    } else return bookingConfig.maxBookableDays <= (newBookingCount);
}

export function isDateSelected(date: Date, selectedDates: Date[]): boolean {
    return (selectedDates.map(d => toDateISO(d)).indexOf(toDateISO(date)) !== -1)
}

export function isTimeBookingForSeatAndSelectedDate(b: Booking, seatId: string, roomId: string, selectedDate: string) {
    return b.roomId === roomId && b.seatId === seatId && !!b.timeBegin && !!b.timeEnd && b.date === selectedDate;
}

export function getAllTimeBookingsForCurrentSeatAndDate(bookingList: Booking[], currentSeatId: string, room: Room, selectedDate: string): Booking[] {
    return bookingList.filter((b) => isTimeBookingForSeatAndSelectedDate(b, currentSeatId, room.roomId, selectedDate))
}