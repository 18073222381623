import {gql, useQuery} from "@apollo/client";
import {Neighborhood} from "../API"
import {useEffect} from "react";
import {neighborhoodByRoomId} from "../graphql/queries";
import {
    onCreateNeighborhoodByRoom,
    onDeleteNeighborhoodByRoom,
    onUpdateNeighborhoodByRoom
} from "../graphql/subscriptions";

export function useNeighborhoodList(roomId: string | undefined): [Neighborhood[], () => void] {

    const {data, subscribeToMore, refetch} = useQuery(gql(neighborhoodByRoomId), {
        variables: {
            roomId: roomId,
        },
        skip: !roomId || roomId === ""
    });

    useEffect(() => {
        if (!roomId) return;

        const unsubscribeFromCreate = subscribeToMore({
            document: gql(onCreateNeighborhoodByRoom),
            variables: {roomId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newNeighborhood = subscriptionData.data.onCreateNeighborhoodByRoom;
                return {
                    ...prev,
                    neighborhoodByRoomId: {
                        ...prev.neighborhoodByRoomId,
                        items: [newNeighborhood, ...prev.neighborhoodByRoomId.items]
                    }
                };
            }
        });

        const unsubscribeFromUpdate = subscribeToMore({
            document: gql(onUpdateNeighborhoodByRoom),
            variables: {roomId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedNeighborhood = subscriptionData.data.onUpdateNeighborhoodByRoom;
                return {
                    ...prev,
                    neighborhoodByRoomId: {
                        ...prev.neighborhoodByRoomId,
                        items: prev.neighborhoodByRoomId.items.map((item: Neighborhood) =>
                            item.neighborhoodId === updatedNeighborhood.neighborhoodId ? updatedNeighborhood : item
                        )
                    }
                };
            }
        });

        const unsubscribeFromDelete = subscribeToMore({
            document: gql(onDeleteNeighborhoodByRoom),
            variables: {roomId},
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedNeighborhood = subscriptionData.data.onDeleteNeighborhoodByRoom;
                return {
                    ...prev,
                    neighborhoodByRoomId: {
                        ...prev.neighborhoodByRoomId,
                        items: prev.neighborhoodByRoomId.items.filter(
                            (item: Neighborhood) => item.neighborhoodId !== deletedNeighborhood.neighborhoodId
                        )
                    }
                };
            }
        });

        return () => {
            unsubscribeFromCreate();
            unsubscribeFromUpdate();
            unsubscribeFromDelete();
        };
    }, [subscribeToMore, roomId]);

    function comparator(a: Neighborhood, b: Neighborhood) {
        return a.neighborhoodId.localeCompare(b.neighborhoodId, 'en', {numeric: true});
    }

    return [data?.neighborhoodByRoomId?.items.sort(comparator) ?? [], refetch];
}
