import {useCallback, useEffect, useState} from "react";
import {Room} from "../API";
import {gql, useQuery} from "@apollo/client";
import {getPresignedRoomplanUrl} from "../graphql/queries";

export function useRoomPlanFromS3(room: Room | undefined): string {

    const [roomPlan, setRoomPlan] = useState<string>("");

    const {data} = useQuery(gql(getPresignedRoomplanUrl),
        {
            variables: {
                writeAccess: false,
                roomId: (room ? room!.roomId : ""),
                roomplanId: (room ? room!.roomPlanId : "")
            },
            skip: !room?.roomId || !room?.roomPlanId,
            fetchPolicy: 'cache-and-network',
        });
    const getRoomplan = useCallback(async () => {
    if (!data || !data.getPresignedRoomplanUrl) {
        return roomPlan
    }

    const preSignedUrl = data.getPresignedRoomplanUrl

    const roomPlanResponse = fetch(preSignedUrl, {
        method: "GET"
    });

    if (roomPlanResponse && (await roomPlanResponse).status === 200) {
        return (await roomPlanResponse).text();
    }
    return ""
    },[data, roomPlan])
    useEffect(() => {
        if (room && room.roomPlanExisting) {
            getRoomplan().then(data => {
                setRoomPlan(data)
            })
        } else {
            setRoomPlan("")
        }
    }, [getRoomplan, room]);
    return roomPlan;
}
