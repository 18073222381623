import {useEffect, useState} from "react";
import {Auth} from "aws-amplify";
import {User} from "../services/UserClient";
import {Room} from "../API";
import {getAdminOrgUnit, getOrgUnit} from "./useOwnOrgUnit";

export type IOrgUnit = {
    orgId: string,
    orgName: string,
    deleted: boolean
}

export function useOrgunit(user: User, rooms: Room[] = []) {

    const [orgUnitList, setOrgUnitList] = useState<IOrgUnit[]>([])
    const [adminUnitList, setAdminOrgUnitList] = useState<IOrgUnit[]>([]);
    const [allOrgUnitList, setAllOrgUnitList] = useState<IOrgUnit[]>([]);
    const [errors, setErrors] = useState([])
    const allOrgUnitsURL = process.env.REACT_APP_ALL_ORG_UNIT_API_ENDPOINT!
    const ADMIN_PREFIX_LENGTH = 6;

    const orgUnitCompareByName = (a: IOrgUnit, b: IOrgUnit) => (a.orgName.toLowerCase() > b.orgName.toLowerCase()) ? 1 : -1

    function joinArraysWithoutDuplicates(array1: IOrgUnit[], array2: IOrgUnit[]) {
        const uniqueMap = new Map();

        array1.forEach(element => {
            uniqueMap.set(element.orgId, element);
        });
        array2.forEach(element => {
            uniqueMap.set(element.orgId, element);
        });

        return Array.from(uniqueMap.values());
    }

    async function getAllOrgUnits(): Promise<IOrgUnit[]> {
        const cognitoUserSession = await Auth.currentSession();
        const response = await fetch(allOrgUnitsURL, {
            method: "GET",
            headers: {
                Authorization: "Bearer " + cognitoUserSession.getAccessToken().getJwtToken()
            }
        });
        return response.json()
    }

    async function getAllAdminOrgUnits(orgUnits: IOrgUnit[]) {
        if (user.isAdmin) {
            return orgUnits.filter(org => !org.deleted);
        } else if (user.isOrgUnitAdmin) {
            const adminOrgUnits = await getAdminOrgUnit();
            adminOrgUnits.forEach(adminOrgUnit => {
                adminOrgUnit.orgId = adminOrgUnit.orgId.substring(ADMIN_PREFIX_LENGTH);
            });
            return adminOrgUnits;
        } else {
            const adminOrgUnits: IOrgUnit[] = [];
            return adminOrgUnits;
        }
    }

    async function getVisibleOrgUnits() {
        let allOrgUnits = await getAllOrgUnits()
        let usersOrgUnits = await getOrgUnit()

        if (user.isAdmin) {
            return allOrgUnits
        }

        let visibleUsersOrgUnits = usersOrgUnits.filter(orgUnit =>
            rooms.some(room => room.orgUnitId === orgUnit.orgId && room.isActive))
        let publicOrgUnits = allOrgUnits.filter(orgUnit =>
            rooms.some(room => room.orgUnitId === orgUnit.orgId && room.isPublic && room.isActive)
        )

        return joinArraysWithoutDuplicates(visibleUsersOrgUnits, publicOrgUnits)
    }

    useEffect(() => {
        // eslint-disable-next-line react-hooks/exhaustive-deps
        getVisibleOrgUnits().then(data => {
            setOrgUnitList(data.sort(orgUnitCompareByName)
                .filter(orgUnit => !orgUnit.deleted)
            )

            getAllOrgUnits().then(data => setAllOrgUnitList(data.filter(orgUnit => !orgUnit.deleted).sort(orgUnitCompareByName)))

            return getAllAdminOrgUnits(data)
        }).then(data => setAdminOrgUnitList(data.sort(orgUnitCompareByName)))
            .catch(err => setErrors(err));
    }, [user])

    return {orgUnitList, adminUnitList, allOrgUnitList, errors}

}