import React, {useEffect, useMemo, useState} from 'react';
import SeatComponent, {SeatInterface} from "./SeatComponent";
import {Booking, Hint} from "../../API";
import SVG from 'react-inlinesvg';
import {SeatBookings} from "../../types/SeatBookinType";
import MeetingRoomComponent, {MeetingRoomInterface} from './MeetingRoomComponent';
import {MeetingRoomBookings} from '../../types/MeetingRoomBookingType';
import OfficeHintComponent, {HintInterface} from "./OfficeHintComponent";
import {useHintList} from "../../hooks/useHintList";


interface Props {
    onSeatClick: (seatBookings: SeatBookings) => void
    onMeetingRoomClick: (meetingRoomBookings: MeetingRoomBookings) => void
    bookingList: [Booking] | []
    roomId: string
    RoomPlan: string
    isTimeBookingActive: boolean
}

const TwoGetherSVGLoader: React.FC<Props> = (props) => {
    const {
        onSeatClick,
        onMeetingRoomClick,
        bookingList,
        roomId,
        RoomPlan,
        isTimeBookingActive,
    } = props

    const [svgViewBoxWidth, setSvgViewBoxWidth] = useState("")
    const [svgViewBoxHeight, setSvgViewBoxHeight] = useState("")

    const hintsCurrentRoom: Hint[] | [] = useHintList(roomId);

    const xmlDocument: XMLDocument = useMemo(() => new DOMParser().parseFromString(RoomPlan, "image/svg+xml"), [RoomPlan, hintsCurrentRoom])

    useEffect(function getSvgViewBoxDimensions() {
        let svgElements = xmlDocument.getElementsByTagName("svg")
        if (svgElements.length >= 1) {
            setSvgViewBoxWidth(svgElements[0].getAttribute("width") !== null ? svgElements[0].getAttribute("width")! : "")
            setSvgViewBoxHeight(svgElements[0].getAttribute("height") !== null ? svgElements[0].getAttribute("height")! : "")
        }
    }, [xmlDocument])

    // Begin Processing Seats
    let seatArray: SeatInterface[] = useMemo(() => {
        let seats: SeatInterface[] = []
        const seatElement = xmlDocument.getElementById("seats");
        if (seatElement?.childNodes) {
            const children = seatElement.querySelectorAll("g");
            children.forEach(child => {
                let seat = child as Element;
                let table = seat.querySelector("rect");
                let bookerName = seat.querySelector(".bookerName");
                let seatName = seat.querySelector(".seatName");
                seats.push({
                    seatID: seat.getAttribute("id") ?? "0",
                    seatPosition: {
                        x: seat.getAttribute("x") ?? "0",
                        y: seat.getAttribute("y") ?? "0",
                        transform: seat?.getAttribute("transform") ?? undefined
                    },
                    tablePosition: {
                        x: table?.getAttribute("x") ?? "0",
                        y: table?.getAttribute("y") ?? "0",
                        transform: table?.getAttribute("transform") ?? undefined
                    },
                    bookerNamePosition: {
                        x: bookerName?.getAttribute("x") ?? "0",
                        y: bookerName?.getAttribute("y") ?? "0",
                        transform: bookerName?.getAttribute("transform") ?? undefined
                    },
                    tableTextPosition: {
                        x: seatName?.getAttribute("x") as string ?? "0",
                        y: seatName?.getAttribute("y") as string ?? "0",
                        transform: seatName?.getAttribute("transform") ?? undefined
                    },
                });
                seat.querySelector(".bookerName")?.remove()
                seat.querySelector(".seatName")?.remove()
            })
        }

        return seats;
    }, [xmlDocument])
    const seatBookings: SeatBookings[] = seatArray.map((seat) => {
        let bookings = bookingList.filter(bi => bi.seatId === seat.seatID && bi.roomId === roomId)
        return {
            seat: seat,
            bookings: bookings,
        }
    })
    const renderSeats = useMemo(() => {
        return seatBookings.map((seatBooking: SeatBookings) => {
            return <SeatComponent
                key={seatBooking.seat.seatID}
                onClick={onSeatClick}
                seatBookings={seatBooking}
                isTimeBookingActive={isTimeBookingActive}
            />
        })
    }, [seatBookings, onSeatClick, isTimeBookingActive])
    // End Processing Seats

    // Begin Processing Meeting Rooms
    type meetingRoomWithBookings = {
        meetingRoom: MeetingRoomInterface,
        bookings: Booking[]
    }
    let meetingRoomArray: MeetingRoomInterface[] = useMemo(() => {
        let meetingRooms: MeetingRoomInterface[] = []
        const meetingRoomElement = xmlDocument.getElementById("rooms");

        if (!meetingRoomElement?.childNodes) return meetingRooms;

        const children = meetingRoomElement.querySelectorAll("g");
        children.forEach(child => {
            let roomGroup = child as Element;
            let room = roomGroup.querySelector("path");
            let roomCap = roomGroup.querySelector(".roomCapacity");
            let roomIcon = roomGroup.querySelector(".icon");
            meetingRooms.push({
                meetingRoomID: roomGroup.getAttribute("id") ?? "0",
                roomCap: roomCap?.getAttribute("roomCap") as unknown as number ?? 0,
                meetingRoomGroupPosition: {
                    x: parseInt(roomGroup?.getAttribute("x") ?? "0"),
                    y: parseInt(roomGroup?.getAttribute("y") ?? "0"),
                    transform: roomGroup?.getAttribute("transform") ?? undefined
                },
                meetingRoomPosition: {
                    x: parseInt(room?.getAttribute("x") ?? "0"),
                    y: parseInt(room?.getAttribute("y") ?? "0"),
                    transform: room?.getAttribute("transform") ?? undefined
                },
                meetingRoomDimension: {
                    width: parseInt(room?.getAttribute("width") ?? "0"),
                    height: parseInt(room?.getAttribute("height") ?? "0"),
                },
                meetingRoomShape: room?.getAttribute("d") ?? "0",
                meetingRoomIconPosition: {
                    x: parseInt(roomIcon?.getAttribute("x") ?? "0"),
                    y: parseInt(roomIcon?.getAttribute("y") ?? "0"),
                    transform: roomIcon?.getAttribute("transform") ?? undefined
                },
                meetingRoomIconDimension: {
                    width: parseInt(roomIcon?.getAttribute("width") ?? "0"),
                    height: parseInt(roomIcon?.getAttribute("height") ?? "0"),
                }
            });
            roomGroup.querySelector(".bookerName")?.remove()
            roomGroup.querySelector(".icon")?.remove()
        })
        return meetingRooms;
    }, [xmlDocument])
    const meetingRoomBookings: MeetingRoomBookings[] = meetingRoomArray.map((meetingRoom): meetingRoomWithBookings => {
        let bookings = bookingList.length !== 0 ? bookingList.filter(bi => bi.seatId === meetingRoom.meetingRoomID && bi.roomId === roomId) : []
        return {
            meetingRoom: meetingRoom,
            bookings: bookings,
        }
    })
    const renderMeetingRooms = useMemo(() => {

        return meetingRoomBookings.map((meetingRoomBooking: MeetingRoomBookings) => {
            return <MeetingRoomComponent
                key={meetingRoomBooking.meetingRoom.meetingRoomID}
                onClick={onMeetingRoomClick}
                meetingRoomBookings={meetingRoomBooking}
                isTimeBookingActive={isTimeBookingActive}
            />
        })

    }, [meetingRoomBookings, onMeetingRoomClick, isTimeBookingActive])
    // End Processing Meeting Rooms

    // Begin Processing Hints for offices
    let hintArray: HintInterface[] = useMemo(() => {
        let roomHints: HintInterface[] = []
        const officeElement = xmlDocument.getElementById("offices");
        if (!officeElement?.childNodes) return roomHints;
        const hintRects = officeElement.querySelectorAll("rect");
        hintRects.forEach(hint => {
            const hintDb = hintsCurrentRoom.find(h => h.hintId === hint.getAttribute("id") ?? "0");
            if (hintDb && hintDb.text.length > 0) {
                roomHints.push({
                    hintId: hint.getAttribute("id") ?? "0",
                    text: hintDb.text,
                    hintIconPosition: {
                        x: parseInt(hint?.getAttribute("x") ?? "0"),
                        y: parseInt(hint?.getAttribute("y") ?? "0"),
                        transform: hint?.getAttribute("transform") ?? undefined
                    },
                    hintIconDimension: {
                        width: parseInt(hint?.getAttribute("width") ?? "0"),
                        height: parseInt(hint?.getAttribute("height") ?? "0"),
                    }
                });
            }
            hint.remove();
        })
        return roomHints;
    }, [xmlDocument])

    const renderHints = useMemo(() => {
        return hintArray.map((hint: HintInterface) => {
            return <OfficeHintComponent hint={hint} key={hint.hintId}/>
        })

    }, [hintArray])
    // End Processing Hints for offices

    const cleanedRoomPlan: string = useMemo(() => new XMLSerializer().serializeToString(xmlDocument), [xmlDocument])

    const style = {width: "100vw", height: "100vh"}
    const viewBox = `0 0 ${svgViewBoxWidth} ${svgViewBoxHeight}`;

    return (
        <div>
            {svgViewBoxWidth !== "" && svgViewBoxHeight !== "" &&
                <svg style={style} viewBox={viewBox}>
                    <SVG src={cleanedRoomPlan}/>
                    {renderSeats}
                    {renderMeetingRooms}
                    {renderHints}
                </svg>}
        </div>
    )
}

export default TwoGetherSVGLoader;