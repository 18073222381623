import {gql, useQuery} from "@apollo/client";
import {bookingByRoomAndDate} from "../graphql/queries";
import {onCreateBookings, onDeleteBooking, onUpdateBooking} from "../graphql/subscriptions";
import {useEffect} from "react";
import {Booking} from "../API"
import {useErrorContext} from "./useErrorContext";

export function useBookingList(roomId: string, roomOrgUnitId: string, dateISO?: string): [Booking] | [] {
    const {reportError} = useErrorContext()
    const {data, subscribeToMore} = useQuery(gql(bookingByRoomAndDate), {
        variables: {
            roomId: roomId,
            date: {eq: dateISO}
        }, skip: (roomId === "")
    });

    useEffect(() => {
        const unsubscribeOnCreateBookings = subscribeToMore({
            document: gql(onCreateBookings),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newBookings = subscriptionData.data.onCreateBookings.Items;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate: {
                            items: [...prev.bookingByRoomAndDate.items, ...newBookings],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onCreateBookingsSubscription")
        });
        const unsubscribeOnDeleteBooking = subscribeToMore({
            document: gql(onDeleteBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedBookingId = subscriptionData.data.onDeleteBooking.bookingId;
                const deletedBookingDate = subscriptionData.data.onDeleteBooking.date;
                return Object.assign({}, prev, {
                    bookingByRoomAndDate: {
                        items: prev.bookingByRoomAndDate.items.filter((item: Booking) => (item.bookingId !== deletedBookingId || item.date !== deletedBookingDate)),
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => reportError(error, "", "useBookingList onDeleteBookingSubscription")
        });
        const unsubscribeOnUpdateBooking = subscribeToMore({
            document: gql(onUpdateBooking),
            variables: {
                roomId: roomId,
                date: dateISO,
                orgUnitId: roomOrgUnitId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedBooking = subscriptionData.data.onUpdateBooking;
                return (Object.assign({}, prev, {
                        bookingByRoomAndDate: {
                            items: [...prev.bookingByRoomAndDate.items.filter((booking: Booking) => booking.bookingId !== updatedBooking.bookingId), updatedBooking],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => reportError(error, "", "useBookingList onUpdateBookingSubscription")
        });

        function unsubscribe() {
            unsubscribeOnCreateBookings();
            unsubscribeOnDeleteBooking();
            unsubscribeOnUpdateBooking();
        }

        return () => unsubscribe();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomOrgUnitId, roomId, dateISO]);

    return data?.bookingByRoomAndDate?.items ?? [];

}