import React, {useEffect, useMemo, useState} from 'react';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
} from '@material-ui/core';
import {useTranslation} from 'react-i18next';
import MultiSelectComboBox from "./MultiselectCombobox";
import {Neighborhood, Room} from "../API";
import {gql, useMutation} from "@apollo/client";
import {updateNeighborhood} from "../graphql/mutations";
import {useNeighborhoodList} from "../hooks/useNeighborhoodList";
import {useOrgunit} from "../hooks/useOrgunit";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";

interface Props {
    showNeighborhoodManager: boolean;
    setShowNeighborhoodManager: (value: boolean) => void;
    selectedRoom: Room;
}

const NeighborhoodManagerComponent: React.FC<Props> = (props) => {
    const {
        showNeighborhoodManager,
        setShowNeighborhoodManager,
        selectedRoom,
    } = props;

    const {
        currentUser
    } = useMainApplicationContext()

    const {t} = useTranslation();
    const [updateNeighborhoodMutation] = useMutation(gql(updateNeighborhood))
    const [neighborhoods, refetchNeighborhoods] = useNeighborhoodList(selectedRoom?.roomId);
    const allOrgUnitList = useOrgunit(currentUser).allOrgUnitList;
    const [localNeighborhoods, setLocalNeighborhoods] = useState<Neighborhood[]>(neighborhoods);

    useEffect(() => {
        setLocalNeighborhoods(neighborhoods);
    }, [neighborhoods]);

    const handleSave = async () => {
        let isSuccess = true;

        for (const neighborhood of localNeighborhoods) {
            try {
                if (neighborhood.neighborhoodId) {
                    await updateNeighborhoodMutation({
                        variables: {
                            input: {
                                neighborhoodId: neighborhood.neighborhoodId,
                                roomId: neighborhood.roomId,
                                orgUnitIds: neighborhood.orgUnitIds ?? [],
                            }
                        }
                    });
                } else {
                    console.error('Invalid neighborhood data:', neighborhood);
                    isSuccess = false;
                }
            } catch (error) {
                console.error("Failed to update neighborhood", error);
                isSuccess = false;
            }
        }

        if (isSuccess) {
            refetchNeighborhoods();
        }

        setShowNeighborhoodManager(false);
    };

    const handleClose = () => {
        setShowNeighborhoodManager(false);
    };

    const handleSelectionChange = (neighborhood: Neighborhood, selectedOrgNames: string[]) => {
        const selectedOrgIds = selectedOrgNames.map(orgName => {
            const orgUnit = allOrgUnitList.find(unit => unit.orgName === orgName);
            return orgUnit?.orgId;
        }).filter((id): id is string => Boolean(id));

        const updatedNeighborhood = {
            ...neighborhood,
            orgUnitIds: selectedOrgIds,
        };

        setLocalNeighborhoods(prev =>
            prev.map(n => n.neighborhoodId === neighborhood.neighborhoodId ? {...updatedNeighborhood} : n)
        );
    };


    const computedSelectedValues = useMemo(() => {
        return localNeighborhoods.map(neighborhood => {
            return (neighborhood.orgUnitIds ?? []).map(orgId => {
                const orgUnit = allOrgUnitList.find(unit => unit.orgId === orgId);
                return orgUnit?.orgName;
            }).filter((name): name is string => Boolean(name));
        });
    }, [localNeighborhoods, allOrgUnitList]);


    return (
        <Dialog
            open={showNeighborhoodManager}
            onClose={handleClose}
            fullWidth
            maxWidth="lg"
        >
            <DialogTitle>{t("neighborhood_management_dialog-title")}</DialogTitle>
            <DialogContent>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>{t("neighborhoods")}</TableCell>
                            <TableCell>{t("organisational_units")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {localNeighborhoods.map((neighborhood, index) => (
                            <TableRow key={neighborhood.neighborhoodId} data-testid={`neighborhood-row-${index}`}>
                                <TableCell
                                    data-testid={`neighborhood-id-${index}`}>{neighborhood.neighborhoodId}</TableCell>
                                <TableCell data-testid={`neighborhood-${index}-select-box`}>
                                    <MultiSelectComboBox
                                        selectedValues={computedSelectedValues[index]}
                                        options={allOrgUnitList.filter(orgUnit => !orgUnit.deleted).map(orgUnit => orgUnit.orgName)}
                                        onChange={(selectedValues) => handleSelectionChange(neighborhood, selectedValues)}
                                    />
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleSave}
                        color="primary"
                        variant={"contained"}>
                    {t('button_save')}
                </Button>
                <Button onClick={handleClose}
                        color="primary"
                        variant={"contained"}>
                    {t('button_close')}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default NeighborhoodManagerComponent;
