import {useUpdateBookingInfos} from "../../hooks/useUpdateBookingInfos"
import {useGetFreeTimeWindows} from "../../hooks/useGetFreeTimeWindows"
import {useRoomPlanContext} from "../../hooks/useRoomPlanContext"
import useRoomColor from "../../hooks/useRoomColor";
import RoomIcon from "./meetingRoomRenderComponents/RoomIcon";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import React, {useEffect, useState} from "react";
import {Booking} from "../../API";

export interface MeetingRoomInterface {
    meetingRoomID: string
    roomCap: number | null
    meetingRoomGroupPosition: Position
    meetingRoomPosition: Position
    meetingRoomDimension: Dimensions
    meetingRoomShape: string
    meetingRoomIconPosition: Position
    meetingRoomIconDimension: Dimensions
}

interface Props {
    onClick: (bookings: MeetingRoomBookings) => void
    meetingRoomBookings: MeetingRoomBookings
    isTimeBookingActive: boolean
}

type Position = {
    x: number,
    y: number,
    transform: string | undefined
}

type Dimensions = {
    width: number,
    height: number
}

const MeetingRoomComponent = (props: Props) => {
    const {meetingRoomBookings} = props
    const meetingRoomGroupPosition = meetingRoomBookings.meetingRoom.meetingRoomGroupPosition;
    const meetingRoomPosition = meetingRoomBookings.meetingRoom.meetingRoomPosition;
    const bookingInfos = useUpdateBookingInfos(meetingRoomBookings.bookings, props.isTimeBookingActive);
    const seatBookingTimeWindow = useGetFreeTimeWindows(bookingInfos, props.isTimeBookingActive, "08:00", "18:00");
    const {setBookingsToDisplay, currentUserID} = useRoomPlanContext()
    const roomBookingState = useRoomColor(seatBookingTimeWindow, currentUserID, meetingRoomBookings.bookings, props.isTimeBookingActive);

    const [isMouseOverIcon, setIsMouseOverIcon] = useState(false);

    useEffect(function showMouseOver() {
        let mouseOverBookings: Booking[] = [];
        if (isMouseOverIcon) {
            mouseOverBookings = props.meetingRoomBookings.bookings;
        }
        setBookingsToDisplay(mouseOverBookings)
    }, [setBookingsToDisplay, isMouseOverIcon])

    return (
        <g className="meeting-room" id={props.meetingRoomBookings.meetingRoom.meetingRoomID}
           transform={meetingRoomGroupPosition.transform}
           onClick={() => {
               props.onClick(meetingRoomBookings);
               setIsMouseOverIcon(false);
           }}>
            <svg x={meetingRoomGroupPosition.x} y={meetingRoomGroupPosition.y}>
                <g className={"meeting-room-icon"}>
                    <svg x={meetingRoomPosition.x} y={meetingRoomPosition.y}
                         className={roomBookingState}>
                        <RoomIcon elementPosition={meetingRoomBookings.meetingRoom.meetingRoomIconPosition}
                                  elementDimensions={meetingRoomBookings.meetingRoom.meetingRoomIconDimension}
                                  isFiltered={false}
                                  setMouseOver={setIsMouseOverIcon}/>
                    </svg>
                </g>
            </svg>
        </g>)
};

export default MeetingRoomComponent;