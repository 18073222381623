import {createContext} from "react";
import {mainApplicationContextTypes} from "../types/mainApplicationContextTypes";

const MainApplicationContext = createContext<mainApplicationContextTypes>({
    orgUnitList: [],
    adminOrgUnitList: [],
    selectedOrgUnit: {orgId: "", orgName: "", deleted: false},
    setSelectedOrgUnit: () => {
    },

    currentUser: {
        ID: "",
        email: "",
        name: "",
        isOrgUnitAdmin: false,
        orgUnits: [],
        adminOrgUnits: [],
        isAdmin: false,
        familyName: "Wurst",
        givenName: "Wurst"
    },
    rooms: [],
    mailConfig: [{
        userId: "",
        userMail: "",
        isReceiveForeignBooking: false,
        isReceiveOwnBooking: false,
        __typename: "MailConfig",
        createdAt: "",
        updatedAt: ""
    }],
})


export default MainApplicationContext