import {Button} from "@material-ui/core";
import locales_de from "../../../locales/de";
import {Booking} from "../../../API";
import dayjs from "dayjs";
import DeleteIcon from "@material-ui/icons/Delete";
import React, {useEffect, useState} from "react";
import {MeetingRoomBookings} from "../../../types/MeetingRoomBookingType";
import {BookingType} from "../../../types/BookingType";

interface Props {
    deletableBookings: Booking[]
    handleDeleteBooking: (bookingId: string) => void
    bookingType: BookingType,
    meetingRoomBookingProps?: MeetingRoomBookings | null
}

const DeleteBookingButtons: React.FC<Props> = (props) => {
    const {
        deletableBookings,
        handleDeleteBooking,
        bookingType
    } = props
    const [sortedBookings, setSortedBookings] = useState<Booking[]>([])

    useEffect(function sortBookings() {
        const tempBookingList = [...deletableBookings]
        tempBookingList.sort((a, b) => {
            return (a.timeBegin || "") < (b.timeBegin || "") ? -1 : 1
        })
        setSortedBookings(tempBookingList)
    }, [deletableBookings])

    function formatDate(date: string) {
        return dayjs(date).format("HH:mm")
    }

    function getButtonText(booking: Booking, bookingType: BookingType) {
        if (bookingType === "meetingRoom") return getMeetingText(booking)
        else return getSeatText(booking)

        function getSeatText(booking: Booking) {
            if (booking.timeBegin) {
                return formatDate((booking.timeBegin || "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? "")));
            } else {
                return locales_de["button_delete_booking"];
            }
        }

        function getMeetingText(booking: Booking) {
            return booking.meetingName + " (" + formatDate((booking.timeBegin ?? "")) + "\u202F\u2013\u202F" + (formatDate((booking.timeEnd ?? ""))) + " )";
        }
    }


    return <>
        {
            sortedBookings.map((booking) =>
                <Button className={"SeatAlreadyBookedHintButton"}
                        key={booking.bookingId}
                        onClick={() => handleDeleteBooking(booking.bookingId)}
                        data-testid={"already-booked-delete-booking"}
                        color={"secondary"}
                        variant={"outlined"}
                        size="small"
                        endIcon={<DeleteIcon/>}
                >
                    {getButtonText(booking, bookingType)}
                </Button>)

        }
    </>

}

export default DeleteBookingButtons