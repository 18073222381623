import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import Alert from "@material-ui/lab/Alert";
import AlertTitle from "@material-ui/lab/AlertTitle";
import Button from "@material-ui/core/Button"
import Collapse from "@material-ui/core/Collapse"
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import 'react-calendar/dist/Calendar.css';
import "../../styles/MultiBookingDialog.css";
import {DateObject} from "react-multi-date-picker";
import {getCognitoIdTokenFromLocalStorage} from "../../Utils/Helpers";
import {
    getAllTimeBookingsForCurrentSeatAndDate,
    getGreatestBookableDate,
    handleLimitOfBookings,
    isDateSelected
} from "../MultiBookingDialog/MultiBookingDialogHelper";
import {Booking, Room} from "../../API";
import {BookingConfigDays} from "../../types/BookingConfigDaysTypes";
import {useBookingBySeatId} from "../../hooks/useBookingBySeatId";
import {gql, useQuery} from "@apollo/client";
import locales_de from "../../locales/de";
import {bookingByBookerAndDate} from "../../graphql/queries";
import MultiBookingLegendComponent from "../MultiBookingDialog/MultiBookingLegendComponent";
import {defaultOrgUnitId} from "../../Utils/commons";
import MultiBookingCalendarComponent from "../MultiBookingDialog/MultiBookingCalendarComponent";
import MultiBookingDialogTitle from "../MultiBookingDialog/MultiBookingDialogTitle";
import MultiBookingDialogNameField from "../MultiBookingDialog/MultiBookingDialogNameField";
import {createNewTodayDateWithoutHours, isGreaterThanDate, isLowerThanDate, toDateISO} from "../../services/DateUtils";
import MultiBookingDialogBookingButton from "../MultiBookingDialog/MultiBookingDialogBookingButton";
import {BookingFor, MeetingType} from "../../Utils/Enums";
import dayjs, {Dayjs} from "dayjs";
import duration from "dayjs/plugin/duration";
import {AdapterDayjs} from '@mui/x-date-pickers/AdapterDayjs';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import {renderTimeViewClock, TimePicker} from "@mui/x-date-pickers";
import TimeWindowTableComponent from "../MultiBookingDialog/TimeWindowTableComponent";
import customParseFormat from 'dayjs/plugin/customParseFormat'
import MultiBookingDialogContext from "../../context/MultiBookingDialogContext";
import TextField from "@mui/material/TextField";
import MeetingRoomBookingSettings from "./MeetingRoomBookingSettings";

dayjs.extend(customParseFormat)
dayjs.extend(duration);

interface Props {
    handleClose: () => void
    dateSelectedInCalendar: Date
    bookerName: string
    bookerGivenName: string
    bookerFamilyName: string
    seatId: string
    room: Room
    roomCap: number | null
    selectedSeatIsBookedOnSelectedDay: boolean
    selectedSeatIsBookedByMeOnSelectedDay?: boolean
    bookingConfig: BookingConfigDays
    bookingList: Booking[] | []
}

function CustomTextFieldLeft(props: any) {
    return (
        <TextField {...props} data-testid={"time-picker-left"}/>
    );
}

function CustomTextFieldRight(props: any) {
    return (
        <TextField {...props} data-testid={"time-picker-right"}/>
    );
}

const MultiBookingDialogMeetingRoomComponent: React.FC<Props> = (props) => {
    const {
        handleClose,
        dateSelectedInCalendar, //currently used date in calendar
        bookerName,
        bookerGivenName,
        bookerFamilyName,
        seatId,
        room,
        selectedSeatIsBookedOnSelectedDay,
        selectedSeatIsBookedByMeOnSelectedDay,
        bookingConfig,
        bookingList,
        roomCap
    } = props

    const mounted = useRef(false);
    const [bookable, setBookable] = useState(false);
    const today: Date = createNewTodayDateWithoutHours()
    const [currentMonth, setCurrentMonth] = useState(new Date(dateSelectedInCalendar.getFullYear(), dateSelectedInCalendar.getMonth(), 1).toString())
    const bookingBySeatId = useBookingBySeatId(
        room.roomId, room.orgUnitId ? room.orgUnitId : defaultOrgUnitId, currentMonth, seatId)
    const [isMaxBookableDaysWithSelectionReached, setIsMaxBookableDaysWithSelectionReached] = useState(false)
    const [isBookingLimitInDaysReached, setIsBookingLimitInDaysReached] = useState(false)
    const [isGreaterThanGreatestBookableDate, setIsGreaterThanGreatestBookableDate] = useState(false);
    const [isSeatAlreadyTaken, setIsSeatAlreadyTaken] = useState(false);
    const [isParallelBookedByOtherUsers, setIsParallelBookedByOtherUsers] = useState(false);
    const [isTimeActiveInputBookable, setIsTimeActiveInputBookable] = useState(true);

    const [greatestBookableDate, setGreatestBookableDate] = useState<Date>(getGreatestBookableDate(bookingConfig));
    const [parallelBookedDatesByOtherUsers, setParallelBookedDatesByOtherUsers] = useState<Date[]>([]);
    const [showBookingDidNotComplete, setShowBookingDidNotComplete] = useState(false);
    const [selectedDates, setSelectedDates] = useState<Date[]>([dateSelectedInCalendar])
    const [bookedDays, setBookedDays] = useState<Date[]>([])
    const [bookingForState] = useState(BookingFor.BOOKING_FOR_ME)
    const [bookingForInputUsername, setBookingForInputUsername] = useState<string>("")

    const [meetingType, setMeetingType] = useState<MeetingType>(MeetingType.INTERNAL);
    const [meetingName, setMeetingName] = useState<string>("");
    const [participantNumber, setParticipantNumber] = useState<number>(0);
    const maxParticipantNumber = roomCap;

    const {refetch: refetchBookingByBookerAndDate} = useQuery(gql(bookingByBookerAndDate), {})
    const MultiBookingDialogProviderValues = useMemo(() => ({
        currentMonth,
        setCurrentMonth
    }), [currentMonth, setCurrentMonth]);

    // Begin: Default times calculation

    const isBeforeOrSame = (bookingEnd: Dayjs, beginTime: Dayjs | null) => {
        return (bookingEnd.isBefore(beginTime) || bookingEnd.isSame(beginTime))
    }

    const isAfterOrSame = (bookingBegin: Dayjs, endTime: Dayjs | null) => {
        return (bookingBegin.isAfter(endTime) || bookingBegin.isSame(endTime))
    }

    const getDefaultTimes = useCallback(() => {
        const defaultStart = dayjs("08:00", "HH:mm").millisecond(0);
        const defaultEnd = dayjs("17:00", "HH:mm").millisecond(0);

        const seatBookings = bookingList.filter(booking =>
            booking.seatId === seatId
        )
        const doesBookingOverlapWithDefaultTimes = (booking: Booking) => {

            const timeBegin = dayjs(booking.timeBegin, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const timeEnd = dayjs(booking.timeEnd, "YYYY-MM-DDTHH:mm:ss.SSSZ").millisecond(0);
            const dayDefaultStart = timeBegin.set('hour', 8);
            const dayDefaultEnd = timeEnd.set('hour', 17);

            return ((booking.timeBegin && booking.timeEnd) &&
                (
                    (isAfterOrSame(timeBegin, dayDefaultStart) && timeBegin.isBefore(dayDefaultEnd)) ||
                    (timeEnd.isAfter(dayDefaultStart) && isBeforeOrSame(timeEnd, dayDefaultEnd)) ||
                    (isAfterOrSame(timeEnd, dayDefaultEnd) && isBeforeOrSame(timeBegin, dayDefaultStart))
                ))
        }

        return seatBookings.some(doesBookingOverlapWithDefaultTimes) ? [null, null] : [defaultStart, defaultEnd];
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [beginTime, setBeginTime] = useState<Dayjs | null>(() => getDefaultTimes()[0])
    const [endTime, setEndTime] = useState<Dayjs | null>(getDefaultTimes()[1])
    // End: Default times calculation

    const [isInvalidBookingTime, setIsInvalidBookingTime] = useState(selectedSeatIsBookedOnSelectedDay);//ist uhrzeit gültig (ende nach Beginn)
    const handleStartTime = (newValue: Dayjs | null) => { // millisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setBeginTime(newValue.millisecond(0)) : setBeginTime(newValue)
    }

    const handleEndTime = (newValue: Dayjs | null) => { // illisecond set to 0 because of possible bug in @mui/x-date-pickers
        (newValue != null) ? setEndTime(newValue.millisecond(0)) : setEndTime(newValue)
    }

    const isWithinSelectedTime = useCallback((booking: Booking): boolean => {
        const bookingBegin: Dayjs = dayjs(booking.timeBegin)
        const bookingEnd: Dayjs = dayjs(booking.timeEnd)
        return !(isBeforeOrSame(bookingEnd, beginTime) || isAfterOrSame(bookingBegin, endTime))
    }, [beginTime, endTime])

    useEffect(function removeUnavailableButSelectedDates() {
        let arr = [...selectedDates];

        arr = arr.filter((_date: Date) => isAnyTimeslotBookable(_date) || isDateBookedByCurrentUser(_date))
        if (isMaxBookableDaysWithSelectionReached && !isDateBookedByCurrentUser(dateSelectedInCalendar))
            arr = arr.filter((_date: Date) => _date !== dateSelectedInCalendar)

        setSelectedDates(arr)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        isBookingLimitInDaysReached,
        isGreaterThanGreatestBookableDate,
        isMaxBookableDaysWithSelectionReached,
        bookingConfig,
        parallelBookedDatesByOtherUsers
    ])

    useEffect(function setComponentMounted() {
        mounted.current = true; // Will set it to true on mount ...
        return () => {
            mounted.current = false;
        }; // ... and to false on unmount
    }, []);

    useEffect(function updateSelectedDatesIfDateIsStillBookable() {
        if (isAnyTimeslotBookable(dateSelectedInCalendar) || selectedSeatIsBookedByMeOnSelectedDay)
            setSelectedDates([dateSelectedInCalendar])
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function checkIsSeatAlreadyTakenOnComponentLoad() {
        setIsSeatAlreadyTaken(selectedSeatIsBookedOnSelectedDay && !selectedSeatIsBookedByMeOnSelectedDay)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function updateMyBookedDaysOnLoad() {
        if (mounted.current)
            updateMyBookedDays().then((response) => {
                if (bookingConfig) {
                    handleLimitOfDaysInAdvance()
                    setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, response, selectedDates, bookingConfig))
                    setIsBookingLimitInDaysReached(handleLimitOfBookings(true, response, selectedDates, bookingConfig))
                }
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function removeBookedSeat() {
        if (bookingConfig) {
            handleLimitOfDaysInAdvance()
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig))
        }
        setIsSeatAlreadyTaken(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dateSelectedInCalendar, selectedDates])

    const updateMyBookedDays = useCallback(async () => {
        const bookerId = getCognitoIdTokenFromLocalStorage();
        const bookingsByBooker = await refetchBookingByBookerAndDate({
            bookerId: bookerId,
            date: {between: [toDateISO(today), toDateISO(greatestBookableDate)]}
        })
        let mapOfBookedDays = bookingsByBooker.data.bookingByBookerAndDate.items.map((item: any) => item.date)
            .filter((value: any, index: any, self: any) => self.indexOf(value) === index).map((value: string) => new Date(value))

        setBookedDays([...mapOfBookedDays])

        return [...mapOfBookedDays]
    }, [greatestBookableDate, refetchBookingByBookerAndDate, today])

    const getBookingsForDate = useCallback((date: Date): Booking[] => {
        return bookingBySeatId.filter((booking) => {
            return booking.date === toDateISO(date) && isWithinSelectedTime(booking)
        })
    }, [bookingBySeatId, isWithinSelectedTime])

    const isDateBookedByCurrentUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking) => booking.bookerId === getCognitoIdTokenFromLocalStorage());
    }, [getBookingsForDate])

    const isDateBookedByAnotherUser = useCallback((date: Date): boolean => {
        const bookings = getBookingsForDate(date)
        if (!bookings || bookings.length === 0) return false
        return bookings.some((booking: Booking) => booking.bookerId !== getCognitoIdTokenFromLocalStorage())
    }, [getBookingsForDate])

    const isAnyTimeslotBookable = useCallback((d: Date): boolean => { //TODO: fix this to enable day
        // let _bookingList;   
        // if (d === dateSelectedInCalendar) {
        //     _bookingList = bookingList;           
        // }
        // else {
        //     _bookingList = bookingBySeatId.filter(booking => booking.date === toDateISO(d));     
        // }    

        // const isSameSeat = _bookingList.some(booking => booking.seatId === seatId)

        // if (_bookingList.length && isSameSeat)
        //     return false;
        // let availableMinutesInDay = dayjs.duration({ hours: 24 }).asMinutes() - 1;

        // const allTimeBookingsForCurrentSeat = getAllTimeBookingsForCurrentSeatAndDate(_bookingList, seatId, room, toDateISO(d));

        // allTimeBookingsForCurrentSeat.forEach((booking: Booking) => {
        //     const bookingDuration = dayjs(booking.timeEnd).diff(dayjs(booking.timeBegin), "minutes");
        //     availableMinutesInDay = availableMinutesInDay - bookingDuration;          
        // }
        // )

        let bookingListForDate = bookingList.filter(booking => booking.date === toDateISO(d) && booking.seatId === seatId);

        // if (bookingListForDate[0].date === d){

        // }

        let availableMinutesInDay = dayjs.duration({hours: 24}).asMinutes() - 1;

        bookingListForDate.forEach((booking: Booking) => {
            const bookingDuration = dayjs(booking.timeEnd).diff(dayjs(booking.timeBegin), "minutes");
            availableMinutesInDay = availableMinutesInDay - bookingDuration;
        })

        return availableMinutesInDay !== 0;
    }, [bookingList, room, seatId]) //TODO here is the problem bookingbyseatid is updated too often

    useEffect(function bookingConfigHasChanged() {
        if (bookingConfig) {
            setGreatestBookableDate(getGreatestBookableDate(bookingConfig))
            handleLimitOfDaysInAdvance()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxDaysInAdvance, dateSelectedInCalendar])

    useEffect(function updateMyBookedDaysOnLoad() {
        updateMyBookedDays().then((response) => {
            if (bookingConfig) {
                handleLimitOfDaysInAdvance()
                setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, response, selectedDates, bookingConfig))
                setIsBookingLimitInDaysReached(handleLimitOfBookings(true, response, selectedDates, bookingConfig))
            }
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(function updateMyBookedDaysBySeatId() {
        if (bookingConfig && mounted.current) {
            updateMyBookedDays()
                .catch(error => {
                    console.error(error)
                })
            setIsMaxBookableDaysWithSelectionReached(handleLimitOfBookings(false, bookedDays, selectedDates, bookingConfig))
            setIsBookingLimitInDaysReached(handleLimitOfBookings(true, bookedDays, selectedDates, bookingConfig))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingConfig.maxBookableDays, bookingBySeatId.length, selectedDates])

    useEffect(function showBookingDidNotCompleteAlert() {
        setTimeout(() => {
            if (mounted.current)
                setShowBookingDidNotComplete(false);
        }, 7000);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showBookingDidNotComplete]);

    useEffect(() => {
        async function updateSeatAlreadyTakenAndParallelBookDates() {

            setIsSeatAlreadyTaken(selectedDates.some(value => getBookingsForDate(value) && !isDateBookedByCurrentUser(value) && selectedSeatIsBookedOnSelectedDay))
            setParallelBookedDatesByOtherUsers(selectedDates.filter((date) => isDateBookedByAnotherUser(date)))
        }

        updateSeatAlreadyTakenAndParallelBookDates().then(() => {
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingBySeatId.length])

    useEffect(function updateIsParallelBookedByOtherUsers() {
        setIsParallelBookedByOtherUsers(parallelBookedDatesByOtherUsers.length > 0)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [parallelBookedDatesByOtherUsers])

    function getErrorMsgSeatParallelBooked(): string {
        let errorMsg = ""
        let dates = parallelBookedDatesByOtherUsers.map(date => date.toLocaleDateString(locales_de["jsLocalDateProperty"], {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        })).join(", ")
        if (parallelBookedDatesByOtherUsers.length > 1) {
            errorMsg += (locales_de["multibookingdialog_meetingroom_error_parallel_bookings"]).replace("%s", dates)
        } else {
            errorMsg += (locales_de["multibookingdialog_meetingroom_error_parallel_booking"]).replace("%s", dates)
        }
        return errorMsg
    }

    function getAlertContent() {
        if (showBookingDidNotComplete) {
            return locales_de["multibookingdialog_error_booking_not_complete"]
        } else if (isGreaterThanGreatestBookableDate) {
            return locales_de["multibookingdialog_error_max_booking_days_exceeded"]
                + greatestBookableDate.toLocaleDateString('de-DE', {
                    year: 'numeric',
                    month: 'long',
                    day: 'numeric'
                }) + "."
        } else if (isMaxBookableDaysWithSelectionReached) {
            return locales_de["multibookingdialog_meetingroom_error_max_bookable_days_"]
        } else if (selectedSeatIsBookedOnSelectedDay) {
            return locales_de["multibookingdialog_error_time_slot_not_bookable"]
        } else if (isSeatAlreadyTaken) {
            return locales_de["multibookingdialog_meetingroom_error_seat_already_taken"]
        } else if (!isTimeActiveInputBookable) {
            return locales_de["multibookingdialog_error_time_slot_not_bookable"]
        } else if (isParallelBookedByOtherUsers) {
            return getErrorMsgSeatParallelBooked()
        }
    }

    function isDateBookableForMe(date: Date): boolean {
        if (isLowerThanDate(date, today)) {
            return false;
        }
        if (isGreaterThanDate(date, greatestBookableDate)) {
            return false;
        }
        if (isBookingLimitInDaysReached) {
            return isThereAnotherBookingOnThatDayByMe(date)
        }
        if (isMaxBookableDaysWithSelectionReached) {
            if (isDateSelected(date, selectedDates)) {
                return !inputConflictsWithBookingsOnDay(date);
            } else {
                const dateIsBooked = bookedDays.map(d => toDateISO(d)).some((_dateStr) => _dateStr === toDateISO(date))
                return dateIsBooked && isAnyTimeslotBookable(date);
            }
        }
        if (!isAnyTimeslotBookable(date))
            return false;

        return !inputConflictsWithBookingsOnDay(date);
    }

    function isThereAnotherBookingOnThatDayByMe(date: Date): boolean {
        return bookedDays.map(d => toDateISO(d)).indexOf(toDateISO(date)) !== -1;
    }

    function handleClickOnTimeWindow(start: Dayjs, end: Dayjs) {
        setBeginTime(start);
        setEndTime(end);
    }

    function handleOnCalendarChange(dates: any) {
        setSelectedDates(dates.map((d: DateObject) => new Date(d.toString())))
    }

    function handleLimitOfDaysInAdvance(): void {
        if (bookingConfig.maxDaysInAdvance === 0) {
            setIsGreaterThanGreatestBookableDate(false)
        } else if (isGreaterThanDate(dateSelectedInCalendar, greatestBookableDate) && selectedDates.length === 0) {
            setIsGreaterThanGreatestBookableDate(true)
        } else if (!selectedDates || !selectedDates?.length) {
            setIsGreaterThanGreatestBookableDate(false)
        } else {
            const sortedDays = [...selectedDates].sort((a, b) => {
                return a.getTime() - b.getTime();
            });
            const currentLargestDate = sortedDays[sortedDays.length - 1];
            if (currentLargestDate.getTime() - 86400000 >= today.getTime() + (bookingConfig.maxDaysInAdvance * 86400000)) {
                setIsGreaterThanGreatestBookableDate(true);
            } else {
                setIsGreaterThanGreatestBookableDate(false);
            }
        }
    }

    function bookingTimeIntervalIsValid(newBookingBeginTime: string, newBookingEndTime: string, oldBookingBeginTime: string, oldBookingEndTime: string): boolean {
        if (newBookingBeginTime >= newBookingEndTime) {
            return false;
        }
        if (oldBookingBeginTime === newBookingBeginTime) {
            return false;
        }
        if (newBookingBeginTime >= oldBookingEndTime) {
            return true;
        }
        return oldBookingBeginTime >= newBookingEndTime;
    }

    const inputConflictsWithBookingsOnDay = useCallback((date: Date): boolean => {
        const allTimeBookingsForCurrentSeat = getAllTimeBookingsForCurrentSeatAndDate(bookingBySeatId, seatId, room, toDateISO(date));
        return allTimeBookingsForCurrentSeat.some((booking: Booking) =>
            !bookingTimeIntervalIsValid((beginTime?.format("HH:mm") ?? ""), (endTime?.format("HH:mm") ?? ""), dayjs(booking.timeBegin).format("HH:mm"), dayjs(booking.timeEnd).format("HH:mm")))
    }, [beginTime, bookingBySeatId, endTime, room, seatId])

    const isBookingTimeFormatValid = useCallback(() => {
        return beginTime?.isValid() && endTime?.isValid() && endTime?.isAfter(beginTime);
    }, [beginTime, endTime])

    useEffect(() => {
        const areRequirementsNotFullfilled = !meetingType || !meetingName || !participantNumber || meetingType.length === 0 || meetingName.length === 0 || participantNumber === 0 || participantNumber < 0 || participantNumber > maxParticipantNumber!;
        if (areRequirementsNotFullfilled) {
            setBookable(false)
            return;
        }
        setBookable(true)
    }, [meetingType, meetingName, participantNumber, maxParticipantNumber])


    useEffect(() => {
        const bookingTimeFormatValid = isBookingTimeFormatValid();

        if (selectedDates.length === 0) {
            setIsInvalidBookingTime(false);
            setIsTimeActiveInputBookable(true);
            return;
        }
        if (selectedDates.length > 0) {
            setIsParallelBookedByOtherUsers(false)
        }

        if (!bookingTimeFormatValid) {
            setIsInvalidBookingTime(true);
            setIsTimeActiveInputBookable(!beginTime || !endTime);
            return;
        }
        const inputConflictsWithAnyBookingsOnSelectedDates
            = selectedDates.some((_date: Date) => inputConflictsWithBookingsOnDay(_date))
        if (inputConflictsWithAnyBookingsOnSelectedDates) {
            setIsInvalidBookingTime(true);
            setIsTimeActiveInputBookable(false)
        } else {
            setIsInvalidBookingTime(false);
            setIsTimeActiveInputBookable(true)
        }

    }, [beginTime, endTime, selectedDates, bookingBySeatId, dateSelectedInCalendar, room, bookingList, seatId, isAnyTimeslotBookable, inputConflictsWithBookingsOnDay, isBookingTimeFormatValid])

    const onChangeMeetingName = (value: string) => {
        setMeetingName(value)
    }
    const onChangeMeetingType = (value: MeetingType) => {
        setMeetingType(value)
    }
    const onChangeNumberOfParticipants = (value: number) => {
        setParticipantNumber(value)
    }

    return (
        <div className={"dialogContentDiv"} data-testid={"multi-booking-dialog"}>
            <MultiBookingDialogContext.Provider value={MultiBookingDialogProviderValues}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={"de"}>
                    <Collapse data-testid={"alerts"}
                              in={showBookingDidNotComplete ||
                                  (isSeatAlreadyTaken) ||
                                  isGreaterThanGreatestBookableDate ||
                                  isMaxBookableDaysWithSelectionReached ||
                                  (isParallelBookedByOtherUsers) ||
                                  (!isTimeActiveInputBookable)
                              }>
                        <Alert severity="error" className={"alertStyle"}>
                            {showBookingDidNotComplete &&
                                <AlertTitle className={"alertTitleStyle"}>
                                    {locales_de["booking-error_title"]}
                                </AlertTitle>}
                            {getAlertContent()}
                        </Alert>
                    </Collapse>
                    <DialogContent>
                        <MultiBookingDialogTitle
                            dateSelectedInCalendar={dateSelectedInCalendar}
                            greatestBookableDate={greatestBookableDate}
                            isGreaterThanGreatestBookableDate={isGreaterThanGreatestBookableDate}
                            selectedSeatIsBookedOnSelectedDay={selectedSeatIsBookedOnSelectedDay}
                        ></MultiBookingDialogTitle>
                        <div data-testid={"multibooking-calendar-and-legend"} className={"calendarAndLegendDiv"}>
                            <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                                <MultiBookingLegendComponent
                                    bookingType="meetingRoom"
                                    isTimeActive={true}
                                    isBookingTimeFormatValid={isBookingTimeFormatValid}
                                />
                            </div>
                            <div className={"verticalLine"}/>
                            <div style={{display: "flex", flexDirection: "column", maxWidth: 300}}>
                                <MultiBookingCalendarComponent
                                    bookingType="meetingRoom"
                                    today={today}
                                    selectedDates={selectedDates}
                                    onCalendarSelectionChange={handleOnCalendarChange}
                                    bookings={bookingBySeatId}
                                    greatestBookableDate={greatestBookableDate}
                                    isBookingLimitReached={isMaxBookableDaysWithSelectionReached}
                                    isTimeActive={true}
                                    isDateBookableForMe={isDateBookableForMe}
                                    isAnyTimeslotBookable={isAnyTimeslotBookable}
                                    isBookingTimeFormatValid={isBookingTimeFormatValid}
                                />
                                <div className={"leftSideChild"} style={{display: "flex"}}>
                                    <TimePicker viewRenderers={{
                                        hours: renderTimeViewClock, minutes: renderTimeViewClock
                                    }}
                                                value={beginTime}
                                                onChange={(newValue: Dayjs | null) => handleStartTime(newValue)}
                                                slots={{textField: CustomTextFieldLeft}}/>
                                    <TimePicker viewRenderers={{
                                        hours: renderTimeViewClock, minutes: renderTimeViewClock
                                    }}
                                                value={endTime}
                                                onChange={(newValue: Dayjs | null) => handleEndTime(newValue)}
                                                slots={{textField: CustomTextFieldRight}}/>
                                </div>
                                {(bookingForState === BookingFor.BOOKING_FOR_OTHERS) &&
                                    <MultiBookingDialogNameField
                                        bookingForInputUsername={bookingForInputUsername}
                                        setBookingForInputUsername={setBookingForInputUsername}
                                    />}
                            </div>
                            {
                                <div className={"verticalLine"}/>
                            }
                            {

                                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                                    <MeetingRoomBookingSettings maxParticipants={maxParticipantNumber}
                                                                onChangeMeetingName={onChangeMeetingName}
                                                                onChangeMeetingType={onChangeMeetingType}
                                                                onChangeNumberOfParticipants={onChangeNumberOfParticipants}/>
                                </div>
                            }
                            {
                                <div className={"verticalLine"}/>
                            }
                            {
                                <div style={{display: "flex", flexDirection: "column", justifyContent: "flex-start"}}>
                                    <TimeWindowTableComponent handleClickOnTimeWindow={handleClickOnTimeWindow}
                                                              bookings={bookingBySeatId} selectedDates={selectedDates}/>
                                </div>
                            }

                        </div>
                    </DialogContent>
                    <div>
                        <DialogActions className={"dialogActions"}>
                            <MultiBookingDialogBookingButton
                                bookingType="meetingRoom"
                                shouldDisabled={!bookable}
                                meetingRoomProps={{
                                    meetingName: meetingName,
                                    meetingType: meetingType,
                                    numberOfParticipants: participantNumber,
                                    maxParticipantNumber: maxParticipantNumber
                                }}
                                handleClose={handleClose}
                                bookingConfig={bookingConfig}
                                bookerName={bookerName}
                                bookerGivenName={bookerGivenName}
                                bookerFamilyName={bookerFamilyName}
                                seatId={seatId}
                                room={room}
                                selectedDates={selectedDates}
                                setSelectedDates={setSelectedDates}
                                isDateBookableForMe={isDateBookableForMe}
                                bookedDays={bookedDays}
                                bookingForState={bookingForState}
                                bookingForInputUsername={bookingForInputUsername}
                                setShowBookingDidNotComplete={setShowBookingDidNotComplete}
                                beginTime={(beginTime?.format("HH:mm") ?? "")}
                                endTime={(endTime?.format("HH:mm") ?? "")}
                                setIsInvalidBookingTime={setIsInvalidBookingTime}
                                isInvalidBookingTime={isInvalidBookingTime}
                            >
                            </MultiBookingDialogBookingButton>
                            <Button
                                data-testid={"close-btn"}
                                variant={"contained"}
                                color={"primary"}
                                onClick={() => handleClose()}
                            >
                                {locales_de["cancel"]}
                            </Button>
                        </DialogActions>
                    </div>
                </LocalizationProvider>
            </MultiBookingDialogContext.Provider>
        </div>
    )
}
export default MultiBookingDialogMeetingRoomComponent