import {Booking, Neighborhood, Room} from "../../API";
import React, {useCallback, useEffect, useMemo, useRef, useState} from "react";
import "../../styles/styleRoomPlan.css"
import {useBookingList} from "../../hooks/useBookingList";
import {Dialog} from "@mui/material";
import {TransformComponent, TransformWrapper} from "react-zoom-pan-pinch";
import ZoomControlComponent from "./ZoomControlComponent";
import MultiBookingDialogComponent from "../MultiBookingDialog/MultiBookingDialogComponent";
import {User} from "../../services/UserClient";
import {useBookingLimitation} from "../../hooks/useBookingLimitation";
import RoomPlanPanningComponent from "./RoomPlanPanningComponent";
import TwoGetherSVGLoader from "../svgComponents/TwoGetherSVGLoader";
import {useRoomPlanFromS3} from "../../hooks/useRoomPlanFromS3";
import {useOutsideDivClick} from "../../hooks/useOutsideDivClick";
import RoomPlanContext from "../../context/RoomPlanContext";
import {itemToDisplay} from "../../types/RoomPlanContextTypes";
import RoomPlanHoverDialog from "./RoomPlanHoverDialog";
import {SeatBookings} from "../../types/SeatBookinType";
import {useFilterContext} from "../../hooks/useFilterContext";
import ItemClickedDialog from "./ItemClickedDialogComponents/ItemClickedDialog";
import {MeetingRoomBookings} from "../../types/MeetingRoomBookingType";
import MultiBookingDialogMeetingRoomComponent
    from "../MultiBookingDialogMeetingRoom/MultiBookingDialogMeetingRoomComponent";
import BookingMeetingRoomList from "../svgComponents/meetingRoomRenderComponents/BookingRoomMeetingList";
import {BookingType} from "../../types/BookingType";


interface Props {
    selectedNeighborhood: Neighborhood | undefined
    room: Room
    date: Date
    currentUser: User
    triggerNeighborhoodUpdate: boolean
}


export function highlightSeats(element: Element, predicate: boolean) {
    predicate ?
        element.setAttribute("class", "highlighted") :
        element.setAttribute("class", "")
}

const RoomPlanComponent: React.FC<Props> = (props) => {
    const {
        selectedNeighborhood,
        room,
        date,
        currentUser,
        triggerNeighborhoodUpdate,
    } = props

    const zoomContext = useRef<any>(null);
    const dateISO = date.toLocaleDateString('sv', {timeZone: 'Europe/Berlin'}).substring(0, 10)

    const [selectedSeat, setSelectedSeat] = useState<string>("");
    const [currentRoomCap, setCurrentRoomCap] = useState<number | null>(0);

    // TODO: Maybe possible to get rid of these states
    const [clickedOnBookedSeat, setClickedOnBookedSeat] = useState<boolean>(false);
    const [selectedSeatIsBookedByMe, setSelectedSeatIsBookedByMe] = useState<boolean>(false);
    const [, setShowAlreadyBookedHint] = useState(false);
    const [showMultiBookingDialog, setShowMultiBookingDialog] = useState(false);
    const [showMultiBookingMeetingRoomDialog, setShowMultiBookingMeetingRoomDialog] = useState(false);
    const [showItemClickedDialog, setShowItemClickedDialog] = useState(false);
    const [deletableBookings, setDeletableBookings] = useState<Booking[]>([])
    const [itemsToDisplay, setItemsToDisplay] = useState<itemToDisplay[]>([])
    const [bookingsToDisplay, setBookingsToDisplay] = useState<Booking[]>([])
    const [currentMeetingRoomBookings, setCurrentMeetingRoomBookings] = useState<MeetingRoomBookings | null>(null);
    const [isDeletingMeetingRoomBookings, setIsDeletingMeetingRoomBookings] = useState<boolean>(false);

    const bookingConfig = useBookingLimitation(room.orgUnitId ? room.orgUnitId : "");
    const roomPlanContainerRef = useRef<any>(null);
    const mouse = useRef({x: 0, y: 0});

    const roomPlan = useRoomPlanFromS3(room)
    const bookingList = useBookingList((room ? room.roomId : ""), ((room?.orgUnitId) ? room.orgUnitId : ""), dateISO)

    const initialScale = (room?.roomPlanScaleFactor) ? room.roomPlanScaleFactor / 100 : 1;
    let minScale = Math.min(initialScale, 0.5);
    const maxScale = 10;

    const [staticMousePosXOnSeat, setStaticMousePosXOnSeat] = useState<string>("0px")
    const [staticMousePosYOnSeat, setStaticMousePosYOnSeat] = useState<string>("0px")

    const {isRoomDropdownFocussed, isOrgUnitDropdownFocused} = useFilterContext();

    const offsetPopUpDialog: number = 10;

    const handleMouseMove = (e: React.MouseEvent) => {
        mouse.current = {
            ...mouse.current,
            x: e.pageX,
            y: e.pageY + 12
        }
    }

    useEffect(() => {
        setStaticMousePosXOnSeat(mouse.current.x + "px")
        setStaticMousePosYOnSeat(mouse.current.y + "px")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSeat])


    const handleClickOnSeat = useCallback((selectedSeatBookings: Booking[]) => {
        let _deletableBookings: Booking[] = [];
        if (selectedSeatBookings.length) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
            if (currentUser.isAdmin)
                _deletableBookings = selectedSeatBookings
            else {
                let myBookingsAtSelectedSeat = selectedSeatBookings.filter(booking => booking.bookerId === currentUser.ID)
                if (myBookingsAtSelectedSeat) {
                    setSelectedSeatIsBookedByMe(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
                    _deletableBookings = myBookingsAtSelectedSeat
                }
            }
            if (_deletableBookings.length > 0) {
                setDeletableBookings(_deletableBookings);
                setShowItemClickedDialog(true);
            } else {
                setShowMultiBookingDialog(true)
            }
        } else {
            setShowMultiBookingDialog(true)
        }
    }, [currentUser.ID, currentUser.isAdmin])

    const handleClickOnMeetingRoom = useCallback((selectedMeetingRoomBookings: Booking[]) => {
        let _deletableBookings: Booking[] = [];
        if (selectedMeetingRoomBookings.length) {
            setClickedOnBookedSeat(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
            if (currentUser.isAdmin || currentUser.isOrgUnitAdmin)
                _deletableBookings = selectedMeetingRoomBookings
            else {
                let myBookingsAtSelectedSeat = selectedMeetingRoomBookings.filter(booking => booking.bookerId === currentUser.ID)
                if (myBookingsAtSelectedSeat) {
                    setSelectedSeatIsBookedByMe(true) //TODO Is it possible to get rid of these states? Look into Multibooking Component
                    _deletableBookings = myBookingsAtSelectedSeat
                }
            }
            if (_deletableBookings.length > 0) {
                setDeletableBookings(_deletableBookings);
                setIsDeletingMeetingRoomBookings(true);
                setShowItemClickedDialog(true);
            } else {
                setShowMultiBookingMeetingRoomDialog(true)
            }
        } else {
            setShowMultiBookingMeetingRoomDialog(true)
        }
    }, [currentUser.ID, currentUser.isAdmin])

    const onSeatClick = useCallback((selectedSeatBookings: SeatBookings) => {
        setSelectedSeat(selectedSeatBookings.seat.seatID)
        handleClickOnSeat(selectedSeatBookings.bookings)
    }, [handleClickOnSeat])

    // MeetingRooms are still treated as Seats
    const onMeetingRoomClick = useCallback((selectedMeetingRoomBookings: MeetingRoomBookings) => {
        setSelectedSeat(selectedMeetingRoomBookings.meetingRoom.meetingRoomID)
        setCurrentRoomCap(selectedMeetingRoomBookings.meetingRoom.roomCap)
        setCurrentMeetingRoomBookings(selectedMeetingRoomBookings)
        handleClickOnMeetingRoom(selectedMeetingRoomBookings.bookings)
    }, [handleClickOnMeetingRoom])

    const closeDeleteBookingDialog = () => {
        setShowItemClickedDialog(false)
        setSelectedSeat("")
        setSelectedSeatIsBookedByMe(false)
        setClickedOnBookedSeat(false)
    }

    const outsideClickRef = useOutsideDivClick(function handleOutsideClick() {
        setShowAlreadyBookedHint(false)
        setShowItemClickedDialog(false)
        setIsDeletingMeetingRoomBookings(false)
        setCurrentMeetingRoomBookings(null)
        setCurrentRoomCap(null)
        setDeletableBookings([])
        setSelectedSeat("")
    })

    const renderRoomPlanHoverDialog = useMemo(() => {
        if (itemsToDisplay.length > 0) {
            return (
                <RoomPlanHoverDialog mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                     offset={offsetPopUpDialog}/>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsToDisplay])

    const renderMeetingRoomBookingListHoverDialog = useMemo(() => {
        if (bookingsToDisplay.length > 0) {
            return (
                <BookingMeetingRoomList mousePosX={mouse.current.x} mousePosY={mouse.current.y}
                                        currentUserID={currentUser.ID} offset={offsetPopUpDialog}/>
            )
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bookingsToDisplay])


    const renderSVG = useMemo(() => {
        return (
            <TwoGetherSVGLoader onSeatClick={onSeatClick}
                                onMeetingRoomClick={onMeetingRoomClick}
                                bookingList={bookingList}
                                roomId={room.roomId}
                                RoomPlan={roomPlan}
                                isTimeBookingActive={!!room.isTimeActive}
            />
        )
    }, [onSeatClick, onMeetingRoomClick, bookingList, room.roomId, room.isTimeActive, roomPlan])

    const renderRoomPlan = useMemo(() => {
        return (
            <div>
                {room &&
                    <div style={{
                        overflowY: "hidden",
                        flexGrow: 1,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        justifyContent: "center"
                    }}>
                        <TransformWrapper
                            key={room.roomId}
                            ref={zoomContext}
                            initialScale={initialScale}
                            minScale={minScale}
                            maxScale={maxScale}
                            doubleClick={{disabled: true}}
                            panning={{disabled: false, velocityDisabled: true}}
                            centerOnInit={true}
                            limitToBounds={false}
                            centerZoomedOut={false}
                        >
                            {({zoomIn, zoomOut, centerView}: any) => (
                                <React.Fragment>
                                    <ZoomControlComponent
                                        zoomIn={zoomIn}
                                        zoomOut={zoomOut}
                                        reset={() => {
                                            centerView(initialScale);
                                        }}
                                        zoomInDisabled={zoomContext?.current?.instance.transformState.scale >= maxScale}
                                        zoomOutDisabled={zoomContext.current === null ||
                                            (zoomContext.current.instance.transformState &&
                                                zoomContext.current.instance.transformState.scale <= minScale)}

                                    />
                                    <div className={"divTransform"} onMouseMove={handleMouseMove}>
                                        <TransformComponent>
                                            <div className={"divSVG"} ref={roomPlanContainerRef}>
                                                {renderSVG}
                                            </div>
                                        </TransformComponent>
                                    </div>
                                </React.Fragment>
                            )}
                        </TransformWrapper>
                    </div>
                }
            </div>
        )
    }, [initialScale, minScale, renderSVG, room])

    const renderMultiBookingDialog = useMemo(() => {
        return (
            <>
                <Dialog PaperProps={{
                    style: {
                        display: "inline-table"
                    },
                }} open={showMultiBookingDialog} onClose={() => {
                    setShowMultiBookingDialog(false);
                    setSelectedSeat("");
                    setClickedOnBookedSeat(false);
                }}>
                    <MultiBookingDialogComponent
                        handleClose={() => {
                            setShowMultiBookingDialog(false);
                            setSelectedSeat("");
                            setClickedOnBookedSeat(false);
                        }}
                        dateSelectedInCalendar={date}
                        bookerName={currentUser.name}
                        bookerGivenName={currentUser.givenName}
                        bookerFamilyName={currentUser.familyName}
                        room={room}
                        seatId={selectedSeat}
                        selectedSeatIsBookedOnSelectedDay={clickedOnBookedSeat}
                        selectedSeatIsBookedByMeOnSelectedDay={selectedSeatIsBookedByMe}
                        bookingConfig={bookingConfig}
                        bookingList={bookingList}>
                    </MultiBookingDialogComponent>
                </Dialog>
            </>
        )
    }, [bookingConfig, clickedOnBookedSeat, date, room, selectedSeat, selectedSeatIsBookedByMe,
        showMultiBookingDialog, currentUser.name, currentUser.givenName, currentUser.familyName, bookingList])

    const renderMultiBookingMeetingRoomDialog = useMemo(() => {
        return (
            <>
                <Dialog PaperProps={{
                    style: {
                        display: "inline-table"
                    },
                }} open={showMultiBookingMeetingRoomDialog} onClose={() => {
                    setShowMultiBookingMeetingRoomDialog(false);
                    setSelectedSeat("");
                    setClickedOnBookedSeat(false);
                }}>
                    <MultiBookingDialogMeetingRoomComponent
                        handleClose={() => {
                            setShowMultiBookingMeetingRoomDialog(false);
                            setSelectedSeat("");
                            setCurrentMeetingRoomBookings(null);
                            setClickedOnBookedSeat(false);
                        }}
                        dateSelectedInCalendar={date}
                        bookerName={currentUser.name}
                        bookerGivenName={currentUser.givenName}
                        bookerFamilyName={currentUser.familyName}
                        room={room}
                        seatId={selectedSeat}
                        selectedSeatIsBookedOnSelectedDay={clickedOnBookedSeat}
                        selectedSeatIsBookedByMeOnSelectedDay={selectedSeatIsBookedByMe}
                        roomCap={currentRoomCap}
                        bookingConfig={bookingConfig}
                        bookingList={bookingList}>
                    </MultiBookingDialogMeetingRoomComponent>
                </Dialog>
            </>
        )
    }, [showMultiBookingMeetingRoomDialog, date, currentUser.name, currentUser.givenName, currentUser.familyName, room, selectedSeat, clickedOnBookedSeat, selectedSeatIsBookedByMe, currentRoomCap, bookingConfig, bookingList])

    const renderItemClickedDialog = useMemo(() => {

        const bookingType: BookingType = isDeletingMeetingRoomBookings ? "meetingRoom" : "seat";
        const bookOtherDateTime = () => {
            setShowItemClickedDialog(false);
            if (!isDeletingMeetingRoomBookings) {
                setShowMultiBookingDialog(true);
                return;
            }
            setShowMultiBookingMeetingRoomDialog(true);
            setIsDeletingMeetingRoomBookings(false);
        }

        return <>
            {showItemClickedDialog &&
                <div ref={outsideClickRef}>
                    <ItemClickedDialog
                        bookingType={bookingType}
                        position={{left: mouse.current.x, top: mouse.current.y}}
                        offset={offsetPopUpDialog}
                        isTimeActive={room.isTimeActive!}
                        deletableBookings={deletableBookings}
                        handleBookOtherDateTime={bookOtherDateTime}
                        dateISO={dateISO}
                        closeDeleteBookingDialog={closeDeleteBookingDialog}
                        meetingRoomBookingProps={currentMeetingRoomBookings}
                    />
                </div>
            }
        </>

    }, [isDeletingMeetingRoomBookings, showItemClickedDialog, outsideClickRef, staticMousePosXOnSeat, staticMousePosYOnSeat, room.isTimeActive, deletableBookings, dateISO, currentMeetingRoomBookings])

    const providerValues = useMemo(() => ({
        currentUserID: props.currentUser.ID,
        itemsToDisplay,
        setItemsToDisplay,
        bookingsToDisplay,
        setBookingsToDisplay
    }), [bookingsToDisplay, itemsToDisplay, props.currentUser.ID]);

    const renderRoomPlanComponent = useMemo(() => {
        return (
            <RoomPlanContext.Provider value={providerValues}>
                {renderRoomPlan}
                {renderMultiBookingDialog}
                {renderItemClickedDialog}
                {renderMultiBookingMeetingRoomDialog}
                <RoomPlanPanningComponent selectedNeighborhood={selectedNeighborhood}
                                          triggerNeighborhoodUpdate={triggerNeighborhoodUpdate}
                                          isRoomDropdownFocussed={isRoomDropdownFocussed}
                                          isOrgUnitDropdownFocused={isOrgUnitDropdownFocused} zoomContext={zoomContext}
                                          roomPlanContainerRef={roomPlanContainerRef}></RoomPlanPanningComponent>
                {renderRoomPlanHoverDialog}
                {renderMeetingRoomBookingListHoverDialog}
            </RoomPlanContext.Provider>
        )
    }, [selectedNeighborhood,triggerNeighborhoodUpdate, isOrgUnitDropdownFocused, providerValues, renderRoomPlan, renderMultiBookingDialog, renderItemClickedDialog, renderMultiBookingMeetingRoomDialog, isRoomDropdownFocussed, renderRoomPlanHoverDialog, renderMeetingRoomBookingListHoverDialog])

    return (
        <>
            {renderRoomPlanComponent}
        </>
    )
}
export default RoomPlanComponent