import React, {useEffect, useState} from "react";
import {
    Box,
    Checkbox,
    createStyles,
    FormControl,
    FormControlLabel,
    IconButton,
    makeStyles,
    Paper,
    Theme,
    Tooltip
} from "@material-ui/core";
import {gql, useMutation} from "@apollo/client";
import {updateSeatConfig} from "../graphql/mutations";
import {Inventory, SeatConfig} from "../API";
import SaveIcon from "@material-ui/icons/Save";
import CancelIcon from "@material-ui/icons/Cancel";
import {InvType} from "../Utils/Enums";
import MultiSelectorDuplicities from "./MultiSelectorDuplicities";
import {CognitoUser} from "../hooks/useCognitoUserList";
import SeatConfigOwnerComponent from "./SeatConfigOwnerComponent";
import {useMainApplicationContext} from "../hooks/useMainApplicationContext";
import {useErrorContext} from "../hooks/useErrorContext";
import {useTranslation} from "react-i18next";


interface Props {
    setShowSeatConfiguration: (value: boolean) => void
    setSelectedSeatConfiguration: (value: SeatConfig | undefined) => void
    seat: SeatConfig
    inventoryItems: Inventory[]
    cognitoUserList: CognitoUser[]
}

const useStyles = makeStyles<Theme>(theme => createStyles({
    divSeatConfiguration: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "flex-start",
        margin: "20px",
        [theme.breakpoints.down("sm")]: {
            marginTop: "80px"
        }
    },
    divRoomPicker: {
        width: "20%",
        maxWidth: "350px",
        minWidth: "250px",
        padding: "20px"
    },
    btnEnabled: {
        color: "green"
    },
    btnDisabled: {
        color: "disabled"
    },
    heightAdjustableCheckbox: {
        paddingLeft: "7px"
    }
}));

const SeatConfigurationComponent: React.FC<Props> = (props) => {
    const classes = useStyles();

    const {setShowSeatConfiguration, setSelectedSeatConfiguration, seat, inventoryItems, cognitoUserList} = props
    const {selectedOrgUnit} = useMainApplicationContext()
    const [updateSeatConfigMutation] = useMutation(gql(updateSeatConfig))
    const {reportError} = useErrorContext();

    const [dockingStationOptions, setDockingStationOptions] = useState<Inventory[]>([])
    const [monitorOptions, setMonitorOptions] = useState<Inventory[]>([])
    const [selectedDockingStations, setSelectedDockingStations] = useState<string[]>([])
    const [selectedMonitors, setSelectedMonitors] = useState<string[]>([])
    const [newOwner, setNewOwner] = useState("")
    const [isNewOwnerRegistered, setIsNewOwnerRegistered] = useState(false)
    const [isSeatHeightAdjustable, setIsSeatHeightAdjustable] = useState(() => {
        return seat.isSeatHeightAdjustable || false
    })

    useEffect(() => {
        if (inventoryItems) {
            setDockingStationOptions(inventoryItems.filter(
                (inventoryItem: Inventory) => inventoryItem.type === InvType.Dockingstation && inventoryItem.orgUnitId === selectedOrgUnit?.orgId))
            setMonitorOptions(inventoryItems.filter(
                (inventoryItem: Inventory) => inventoryItem.type === InvType.Monitor && inventoryItem.orgUnitId === selectedOrgUnit?.orgId))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [inventoryItems])
    useEffect(() => {
        if (seat.inventory) {

            setSelectedDockingStations(seat.inventory.map((invId) => inventoryItems.filter((invItem) => invItem.inventoryId === invId
                && invItem.type === InvType.Dockingstation)
                .filter(seatInventory => dockingStationOptions.find(docker => docker.nameLowerCased === seatInventory.nameLowerCased))
                .find(element => element !== undefined)?.name!)
                .filter((element) => element !== undefined))

            setSelectedMonitors(seat.inventory.map((invId) => inventoryItems.filter((invItem) => invItem.inventoryId === invId
                && invItem.type === InvType.Monitor)
                .filter(seatInventory => monitorOptions.find(monitor => monitor.nameLowerCased === seatInventory.nameLowerCased))
                .find(element => element !== undefined)?.name!)
                .filter((element) => element !== undefined))
        }
        if (seat.owner) {
            setNewOwner(seat.owner)
            if (seat.isOwnerRegistered) {
                setIsNewOwnerRegistered(seat.isOwnerRegistered)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [monitorOptions, dockingStationOptions])

    const handleSaveSeatConfig = () => {
        const optionsAll: Inventory[] = selectedDockingStations
            .map((itemName: string) => inventoryItems.find((invItem: Inventory) => invItem.name === itemName))
            .concat(selectedMonitors
                .map((itemName: string) => inventoryItems.find((invItem: Inventory) => invItem.name === itemName))) as Inventory[]

        updateSeatConfigMutation({
            variables: {
                input: {
                    seatName: seat.seatName,
                    roomId: seat.roomId,
                    isSeatHeightAdjustable: isSeatHeightAdjustable,
                    owner: newOwner,
                    isOwnerRegistered: isNewOwnerRegistered,
                    inventory: optionsAll.map((inventoryItem: Inventory) => inventoryItem.inventoryId)
                }
            }
        }).then(() => setShowSeatConfiguration(false))
            .catch((err) => reportError(err, "", "SeatConfigurationComponent handleSaveSeatConfig"))
        setSelectedSeatConfiguration(undefined)
    }

    const handleIsSeatHeightAdjustable = () => {
        setIsSeatHeightAdjustable(!isSeatHeightAdjustable)
    }

    const {t} = useTranslation();
    return (
        <Paper
            style={{
                marginTop: "0.625rem",
                marginBottom: "0.625rem",
                padding: "0.5rem",
                width: "inherit",
            }}
            data-testid={"configure-seat-component"}
        >
            <SeatConfigOwnerComponent cognitoUserList={cognitoUserList} newOwner={newOwner}
                                      setNewOwner={setNewOwner}
                                      isNewOwnerRegistered={isNewOwnerRegistered}
                                      setIsNewOwnerRegistered={setIsNewOwnerRegistered}
            />
            <Box>
                <FormControl
                    style={{
                        width: "100%"
                    }}
                    data-testid={"input-fields"}>
                    <MultiSelectorDuplicities
                        onChange={(selected) => setSelectedDockingStations(selected)}
                        selected={selectedDockingStations}
                        title={t('seat_info_dockingstations_title')}
                        options={dockingStationOptions.map((option) => option.name)}
                    />
                    <MultiSelectorDuplicities
                        onChange={(selected) => setSelectedMonitors(selected)}
                        selected={selectedMonitors}
                        title={t("seat_info_monitors_title")}
                        options={monitorOptions.map((option) => option.name)}
                    />
                </FormControl>
                <div style={{
                    display: "flex",
                    justifyContent: "space-between"
                }}>
                    <FormControlLabel className={classes.heightAdjustableCheckbox}
                                      control={<Checkbox name="checkedC" checked={isSeatHeightAdjustable}
                                                         onChange={handleIsSeatHeightAdjustable}/>}
                                      label={t("height-adjustable-checkbox-label")}/>
                    <div style={{justifyContent: "flex-end"}}>
                        <Tooltip title={t("confirm_dialog_ok_button-text")}>
                            <IconButton onClick={handleSaveSeatConfig} data-testid={"save-btn"}>
                                <SaveIcon style={{cursor: "pointer"}} fontSize="large" className={classes.btnEnabled}
                                />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title={t("confirm_dialog_cancel_button-text")}>
                            <IconButton onClick={() => setShowSeatConfiguration(false)} data-testid={"cancel-btn"}>
                                <CancelIcon style={{cursor: "pointer"}} color="primary" fontSize="large"
                                />
                            </IconButton>
                        </Tooltip>
                    </div>
                </div>
            </Box>
        </Paper>
    )
}
export default SeatConfigurationComponent