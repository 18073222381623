import React from "react";
import {
    makeStyles,
    styled,
    Table,
    TableBody,
    TableCell,
    TableCellProps,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    TooltipProps,
    Zoom
} from "@material-ui/core";
import {tooltipClasses} from "@mui/material";
import {Booking, Room} from "../API";
import {IOrgUnit} from "../hooks/useOrgunit";
import {useTranslation} from "react-i18next";
import i18n from "i18next";


export const DAILYBOOKINGS_TOOLTIP_DELAY: number = 250;


//region Styling
const DailyBookingsTooltip = styled(({className, ...props}: TooltipProps) => (
    <Tooltip {...props} classes={{popper: className}} TransitionComponent={Zoom}
             interactive={true}
             enterDelay={DAILYBOOKINGS_TOOLTIP_DELAY} children={props.children}></Tooltip>
))(() => ({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: "100%",
        backgroundColor: "white",
        padding: "20px",
        border: "#ccc 1px solid",
        borderRadius: "4px",
        color: "rgba(0, 0, 0, 0.87)"
    },
}));

const DailyBookingsTableCell = styled(TableCell)<TableCellProps>(() => ({
    paddingTop: "5px",
    paddingBottom: "5px",
    border: "none",
    background: "inherit !important",
    color: "inherit !important",
    whiteSpace: "nowrap"
}))

const useStyles = makeStyles(() => ({
    tableRowRoot: {
        color: "rgba(0, 0, 0, 0.87)",
        "&$tableRowSelected, &$tableRowSelected:hover": {
            color: "#1765c4"
        }
    },
    tableRowSelected: {
        color: "#1765c4"
    },
    dailyBookingToolTip: {
        maxWidth: "80%"
    }
}));

//endregion

function compareByOrgNameAscAndBookingNameAsc(a: BookingInfo, b: BookingInfo) {
    const compareByOrgNameAsc = a.orgName.localeCompare(b.orgName, undefined, {sensitivity: 'accent'});
    if (compareByOrgNameAsc === 0)
        return a.bookingName.localeCompare(b.bookingName, undefined, {sensitivity: 'accent'});
    else
        return compareByOrgNameAsc;
}

//region DailyBookingsInfoTable
export interface DailyBookingsDisplayProps {
    orgBookingData: Booking[],
    rooms: Room[],
    orgUnits: IOrgUnit[]
    selectedRoom: Room | undefined,
    selectedDate: Date
}

//This data gets displayed in the popup, distilled from the retrieved Booking[] data from the DB.
//'key' is only for list optimization in React and gets normally set to bookingId.
class BookingInfo {
    bookingName: string;
    roomName: string | undefined;
    seatId: string;
    orgName: string;
    key: string;

    constructor(bookingName: string = "Empty Booking", roomName: string | undefined = undefined, seatId: string = "0", orgName: string, key: string) {
        this.bookingName = bookingName;
        this.roomName = roomName;
        this.seatId = seatId;
        this.orgName = orgName;
        this.key = key;
    }

    isForRoom(room: Room | undefined): boolean {
        return this.roomName === room?.name;
    }

    static createBookingInfoInOrgUnitRooms(b: Booking, rooms: Room[], orgUnits: IOrgUnit[], key: string) {
        let room = rooms.find(r => r.roomId === b.roomId);
        if (!room) return undefined;
        let orgUnit = orgUnits.find(o => o.orgId === b.orgUnitId);
        if (!orgUnit) return undefined;
        return new BookingInfo(b.bookingFor ? b.bookingFor : b.bookerName, room?.name, b.seatId, orgUnit?.orgName, key)
    }

    //This static field gets repopulated anew on each rerender and then displayed in the popup.
    private static bookingInfoList: BookingInfo[] = [];

    static populateBookingInfoList(orgBookingData: Booking[], rooms: Room[], orgUnits: IOrgUnit[]) {
        orgBookingData.forEach((b) => {
            let v = BookingInfo.createBookingInfoInOrgUnitRooms(b, rooms, orgUnits, b.bookingId);
            if (v instanceof BookingInfo) this.bookingInfoList.push(v);
        });
    }

    static createSortedBookingInfoList(orgBookingData: Booking[], rooms: Room[], orgUnits: IOrgUnit[], compareFn?: ((a: BookingInfo, b: BookingInfo) => number) | undefined): BookingInfo[] {
        this.bookingInfoList = [];
        this.populateBookingInfoList(orgBookingData, rooms, orgUnits);
        return [...this.bookingInfoList].sort(compareFn);
    }

}

const DailyBookingsTable: React.FC<DailyBookingsDisplayProps> = (props) => {
    const {
        orgBookingData,
        rooms,
        orgUnits,
        selectedRoom
    } = props

    const {t} = useTranslation();
    const classes = useStyles()

    const bookingInfos: BookingInfo[] = BookingInfo.createSortedBookingInfoList(orgBookingData, rooms, orgUnits, compareByOrgNameAscAndBookingNameAsc);


    return (
        <div>
            <TableContainer style={{maxHeight: "440px", scrollbarWidth: "thin"}}>
                <Table stickyHeader style={{margin: "auto"}} data-testid="daily-bookings-table">
                    <TableHead>
                        <TableRow style={{color: "black", background: "#eeeeee"}}>
                            <DailyBookingsTableCell>{t('daily_bookings_table_bookingname_column')}</DailyBookingsTableCell>
                            <DailyBookingsTableCell>{t('daily_bookings_table_room_column')}</DailyBookingsTableCell>
                            <DailyBookingsTableCell>{t('daily_bookings_table_seat_column')}</DailyBookingsTableCell>
                            {orgUnits.length > 1 &&
                                <DailyBookingsTableCell>{t('daily_bookings_table_orgunit_column')}</DailyBookingsTableCell>
                            }
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            bookingInfos
                                .map((info) => (
                                    <TableRow key={info.key} style={{backgroundColor: "#fcfcfc"}} classes={{
                                        root: classes.tableRowRoot,
                                        selected: classes.tableRowSelected,
                                    }}
                                              selected={info.isForRoom(selectedRoom)}>
                                        <DailyBookingsTableCell>
                                            {info.bookingName}
                                        </DailyBookingsTableCell>
                                        <DailyBookingsTableCell>
                                            {info.roomName}
                                        </DailyBookingsTableCell>
                                        <DailyBookingsTableCell>
                                            {info.seatId}
                                        </DailyBookingsTableCell>
                                        {orgUnits.length > 1 &&
                                            <DailyBookingsTableCell>
                                                {info.orgName}
                                            </DailyBookingsTableCell>
                                        }
                                    </TableRow>
                                ))
                        }
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
};

//endregion


const DailyBookingsDisplay: React.FC<DailyBookingsDisplayProps> = (props) => {

    const {t} = useTranslation();
    const localisation = i18n.language;
    const {
        orgBookingData,
        rooms,
        orgUnits,
        selectedRoom,
        selectedDate
    } = props

    const classes = useStyles()
    const bookingInfos: BookingInfo[] = BookingInfo.createSortedBookingInfoList(orgBookingData, rooms, orgUnits, compareByOrgNameAscAndBookingNameAsc);

    function getDailyBookingsTooltipContent(): NonNullable<React.ReactNode> {
        if (bookingInfos.length > 0) {
            return <DailyBookingsTable
                orgBookingData={orgBookingData}
                rooms={rooms}
                orgUnits={orgUnits}
                selectedRoom={selectedRoom}
                selectedDate={selectedDate}
            />
        }
        return <span data-testid="daily-bookings-empty-list-message" style={{fontSize: "14px"}}
                     className={"daily-bookings-empty-list-message"}>{t('daily_bookings_empty_list_message')}</span>;
    }

    return <div style={{padding: "10px"}}>
        <DailyBookingsTooltip title={getDailyBookingsTooltipContent()} className={classes.dailyBookingToolTip}>
            <span data-testid="daily-bookings-counter"
                  style={{
                      cursor: "default"
                  }}>{t('Booking_day')} {selectedDate.toLocaleDateString(localisation, {
                year: 'numeric',
                month: 'short',
                day: 'numeric'
            })}: {bookingInfos.length}</span>
        </DailyBookingsTooltip>
    </div>
};


export default DailyBookingsDisplay;