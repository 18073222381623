import React from "react";
import {makeStyles, TextField} from "@material-ui/core";
import {bookingForMaxLength} from "../../Utils/commons";
import uiElementMeasures from "../../styles/inputElementMeasures";
import colors from "../../styles/colors";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";


interface Props {
    bookingForInputUsername: string,
    setBookingForInputUsername: (username: string) => void
}
const MultiBookingDialogNameField: React.FC<Props> = (props) => {
    const {
        bookingForInputUsername,
        setBookingForInputUsername,
    } = props
    const {t} = useTranslation();
    const bookingForOthersUsernameMaxLength = bookingForMaxLength

    const useStyles = makeStyles({
        leftSideChild: {
            marginBottom: uiElementMeasures.marginBetweenElementsInColumn,
            width: "auto",
            color: colors.iconAndTextGrey,
            '& .MuiOutlinedInput-root': {
                '& fieldset': {
                    borderRadius: borders.mediumBorderRadius,
                },
            },
        }
    })

    const classes = useStyles()

    function handleBookingForUsernameUpdate(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>): void {
        e.preventDefault()
        const name = e.target.value
        if (name.trim().length <= bookingForOthersUsernameMaxLength) {
            setBookingForInputUsername(name)
        }
    }


    return <>
        <TextField
            inputProps={{"data-testid": "booking-for-input"}}
            size={"small"}
            className={classes.leftSideChild}
            value={bookingForInputUsername}
            onChange={handleBookingForUsernameUpdate}
            variant={"outlined"}
            label={t("multibookingdialog_textfield_label_booking_for")}
        />
    </>
}

export default MultiBookingDialogNameField