import {makeStyles, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@material-ui/core";
import colors from "../../styles/colors";
import borders from "../../styles/borders";
import {useTranslation} from "react-i18next";
import {BookingType} from "../../types/BookingType";

interface Props {
    isTimeActive: boolean | null | undefined
    isBookingTimeFormatValid: () => boolean | undefined
    bookingType: BookingType
}

const MultiBookingLegendComponent: React.FC<Props> = (props) => {
    // eslint-disable-next-line no-empty-pattern
    const {
        isTimeActive,
        isBookingTimeFormatValid,
        bookingType
    } = props
    const {t} = useTranslation();
    const useStyles = makeStyles({
        legendTableContainer: {
            width: "auto",
            borderRadius: borders.mediumBorderRadius

        },
        legendDateDisplayTableCell: {
            paddingTop: "0.75rem",
            paddingBottom: "0.75rem"
        },

        legendDateDisplay: {
            borderRadius: "4px",
            boxShadow: "rgb(255, 255, 255) 0px 0px 0px",
            color: "black",
            height: "34px",
            position: "relative",
            width: "34px",
            display: "inline-flex",
            alignItems: "center",
            justifyContent: "center"
        },
        legendLabel: {
            minWidth: "230px",
            paddingTop: "8px",
            paddingBottom: "8px",
            textAlign: "center",
            "&.MuiTableCell-root": {
                textAlign: "left",
            }
        }
    })
    const classes = useStyles();

    return <div style={{display: "flex", flexDirection: "row"}} data-testid={"booking-legend"}>

        <TableContainer className={classes.legendTableContainer}>
            <Table stickyHeader style={{marginTop: "auto"}}>
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}
                                   style={{fontSize: "large"}}>{t("multibookingdialog_legend_title")}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {!isTimeActive &&
                        <TableRow>
                            <TableCell className={classes.legendDateDisplayTableCell}>
                                <div>
                                            <span
                                                className={classes.legendDateDisplay}
                                                style={{
                                                    backgroundColor: colors.pastellblau,
                                                }}
                                            >00</span>
                                </div>
                            </TableCell>
                            <TableCell
                                className={classes.legendLabel}>{bookingType === "seat" ? t("multibookingdialog_legend_own_booking") : t("multibookingdialog_meetingroom_legend_own_booking")}</TableCell>
                        </TableRow>}
                    <TableRow>
                        <TableCell className={classes.legendDateDisplayTableCell}>
                            <div>
                                                <span
                                                    className={classes.legendDateDisplay}
                                                    style={{
                                                        backgroundColor: colors.pastellgruen,
                                                    }}>00</span>
                            </div>
                        </TableCell>
                        <TableCell
                            className={classes.legendLabel}>{bookingType === "seat" ? t("multibookingdialog_legend_no_booking") : t("multibookingdialog_meetingroom_legend_no_booking")}</TableCell>
                    </TableRow>

                    {isTimeActive && !isBookingTimeFormatValid() &&
                        <TableRow>
                            <TableCell className={classes.legendDateDisplayTableCell}>
                                <div>
                                                <span
                                                    className={classes.legendDateDisplay}
                                                    style={{
                                                        backgroundColor: colors.pastellgelb,
                                                    }}>00</span>
                                </div>
                            </TableCell>
                            <TableCell
                                className={classes.legendLabel}>{t("multibookingdialog_legend_booking_available")}</TableCell>
                        </TableRow>}
                    <TableRow>
                        <TableCell className={classes.legendDateDisplayTableCell}>
                            <div>
                                                <span
                                                    className={classes.legendDateDisplay}
                                                    style={{
                                                        backgroundColor: colors.pastellrot,
                                                    }}>00</span>
                            </div>
                        </TableCell>
                        <TableCell
                            className={classes.legendLabel}>{bookingType === "seat" ? t("multibookingdialog_legend_other_user") : t("multibookingdialog_meetingroom_legend_other_user")}</TableCell>
                    </TableRow>
                    <TableRow>
                        <TableCell style={{
                            paddingTop: "0.5625rem",
                            paddingBottom: "0.5625rem",
                        }}>
                            <div>
                                        <span
                                            className={classes.legendDateDisplay}
                                            style={{
                                                backgroundColor: "argb(0,0,0,0)",
                                                borderStyle: "dotted",
                                                borderColor: "rgb(52,73,29)"
                                            }}>00</span>
                            </div>
                        </TableCell>
                        <TableCell
                            className={classes.legendLabel}>{t("multibookingdialog_legend_marked")}</TableCell>
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>

    </div>;
}

export default MultiBookingLegendComponent