import {SvgIcon} from "@mui/material";

function OwnerIcon(props: any) {
    return (
        <SvgIcon {...props}>
            <path
               fill="#001122"
               d="m 427.36219,398.12167 c -15.306,-74.617 -66.109,-135.106 -132.393,-160.473 -20.004,11.767 -43.217,18.665 -68.094,18.854 -24.873,-0.188 -48.091,-7.096 -68.097,-18.854 -66.272003,25.345 -117.083003,85.854 -132.379002,160.473 -2.473,12.042 -9.648005,39.744 26.937999,39.744 H 400.42219 c 36.576,0 29.418,-27.702 26.94,-39.744 z m -200.966,-171.996 c 58.906,0 106.653,-47.74 106.653,-106.632 0,-58.887003 -47.747,-106.628003 -106.653,-106.628003 -58.904,0 -106.656,47.74 -106.656,106.628003 0.001,58.891 47.751,106.632 106.656,106.632 z"
               id="path4" />
        </SvgIcon>
    );
}

export default OwnerIcon;