import {gql, useQuery} from "@apollo/client";
import {Hint} from "../API"
import {hintsByRoom} from "../graphql/queries";
import {useEffect} from "react";
import {onCreateHint, onDeleteHint, onUpdateHint} from "../graphql/subscriptions";

export function useHintList(roomId: string): Hint[] | [] {

    const {data, subscribeToMore} = useQuery(gql(hintsByRoom), {
        variables: {
            roomId: roomId
        }
    });

    useEffect(function subscribe() {
        const unsubscribeOnCreateHint = subscribeToMore({
            document: gql(onCreateHint),
            variables: {
                roomId: roomId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const newInv = subscriptionData.data.onCreateHint;
                return (Object.assign({}, prev, {
                        hintsByRoom: {
                            items: [...prev.hintsByRoom.items, newInv],
                            nextToken: null
                        }
                    })
                )
            },
            onError: (error: any) => console.error("useHintList useEffect:subscribeToMore:onCreateHint " + JSON.stringify(error))
        });
        const unsubscribeOnDeleteHint = subscribeToMore({
            document: gql(onDeleteHint),
            variables: {
                roomId: roomId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const deletedInvId = subscriptionData.data.onDeleteHint.hintId;
                return Object.assign({}, prev, {
                    hintsByRoom: {
                        items: prev.hintsByRoom.items.filter((item: Hint) => (item.hintId !== deletedInvId)),
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error("useHintList useEffect:subscribeToMore:onDeleteHint " + JSON.stringify(error))

        });
        const unsubscribeOnUpdateHint = subscribeToMore({
            document: gql(onUpdateHint),
            variables: {
                roomId: roomId
            },
            updateQuery: (prev, {subscriptionData}) => {
                if (!subscriptionData.data) return prev;
                const updatedInv: Hint = subscriptionData.data.onUpdateHint;
                let invAlreadyInList = false;
                let newInvList = prev.hintsByRoom.items.map((item: Hint) => {
                    if (item.hintId === updatedInv.hintId) {
                        invAlreadyInList = true
                        return updatedInv
                    }
                    return item
                })
                if (!invAlreadyInList) {
                    newInvList.push(updatedInv)
                }
                return Object.assign({}, prev, {
                    hintsByRoom: {
                        items: newInvList,
                        nextToken: null
                    }
                })
            },
            onError: (error: any) => console.error("useHintList useEffect:subscribeToMore:onUpdateHint " + JSON.stringify(error))
        });

        function unsubscribe() {
            unsubscribeOnCreateHint();
            unsubscribeOnDeleteHint();
            unsubscribeOnUpdateHint();
        }

        return () => unsubscribe();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [roomId]);

    return data?.hintsByRoom?.items ?? [];
}
