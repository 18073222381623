import React, {useEffect, useRef, useState} from "react";
import {Booking} from "../../../API";
import DeleteBookingButtons from "./DeleteBookingButtons";
import {Button} from "@material-ui/core";
import locales_de from "../../../locales/de";
import {Paper} from "@mui/material";

interface SeatPaperProps {
    executeOtherBookingsButton: JSX.Element
    position: React.CSSProperties,
    offset: number,
    isTimeActive: boolean,
    deletableBookings: Booking[],
    removeSelectedBooking: (bookingId: string) => void
}

export function SeatPaper({
                              executeOtherBookingsButton,
                              position,
                              offset,
                              isTimeActive,
                              deletableBookings,
                              removeSelectedBooking
                          }: SeatPaperProps) {

    const [elementPositionX, setElementPositionX] = useState(position.left);
    const [elementPositionY, setElementPositionY] = useState(position.top);
    const elementRef = useRef<HTMLDivElement>(null);

    function deleteTimeBookingButtons() {
        return isTimeActive &&
            <DeleteBookingButtons bookingType={"seat"} deletableBookings={deletableBookings}
                                  handleDeleteBooking={removeSelectedBooking}/>;
    }

    function deleteDayBookingButton() {
        return !isTimeActive && deletableBookings.length > 0 &&
            <Button className={"SeatAlreadyBookedHintButton"}
                    onClick={() => {
                        removeSelectedBooking(deletableBookings[0].bookingId)
                    }}
                    data-testid={"already-booked-delete-booking"}
                    color={"secondary"}
                    variant={"outlined"}
                    size="small"
            >
                {locales_de["button_delete_booking"]}
            </Button>;
    }

    useEffect(() => {
        if (elementRef.current && position.left && position.top) {
            if (window.innerWidth - offset < parseInt(position.left as string) + elementRef.current.offsetWidth) {
                setElementPositionX(window.innerWidth - elementRef.current.offsetWidth - offset);
            }
            if (window.innerHeight - offset < parseInt(position.top as string) + elementRef.current.offsetHeight) {
                setElementPositionY(window.innerHeight - elementRef.current.offsetHeight - offset);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [elementRef.current])

    return <Paper ref={elementRef} elevation={3} className={"SeatAlreadyBookedHint"} data-testid={"already-booked"}
                  sx={{
                      left: elementPositionX,
                      top: elementPositionY,
                      whiteSpace: "nowrap"
                  }}>
        {executeOtherBookingsButton}
        {deleteTimeBookingButtons()}
        {deleteDayBookingButton()}
    </Paper>
}