import {gql, useQuery, useSubscription} from "@apollo/client";
import {listRooms} from "../graphql/queries";
import {
    onCreateRoom,
    onCreateRoomAdmin,
    onCreateRoomOrgUnitAdmin,
    onDeleteRoom,
    onDeleteRoomAdmin,
    onDeleteRoomOrgUnitAdmin,
    onRoomAccessChange,
    onUpdateRoom,
    onUpdateRoomAdmin,
    onUpdateRoomOrgUnitAdmin
} from "../graphql/subscriptions";
import {useEffect} from "react";
import {Room} from "../API"
import {User} from "../services/UserClient";

export function useRoomList(currentUser: User): [Room] | [] {

    const {data, subscribeToMore, refetch} = useQuery(gql(listRooms));

    const {data: subscriptionData} = useSubscription(gql(onRoomAccessChange))

    const roomCompareByName = (a: Room, b: Room) => (a.name.toLowerCase() > b.name.toLowerCase()) ? 1 : -1

    function fillDocument(mode: string) {
        switch (mode) {
            case "CREATE":
                if (currentUser.isAdmin)
                    return gql(onCreateRoomAdmin)
                else if (currentUser.isOrgUnitAdmin)
                    return gql(onCreateRoomOrgUnitAdmin)
                return gql(onCreateRoom)
            case "DELETE":
                if (currentUser.isAdmin)
                    return gql(onDeleteRoomAdmin)
                else if (currentUser.isOrgUnitAdmin)
                    return gql(onDeleteRoomOrgUnitAdmin)
                return gql(onDeleteRoom)
            case "UPDATE":
                if (currentUser.isAdmin)
                    return gql(onUpdateRoomAdmin)
                else if (currentUser.isOrgUnitAdmin)
                    return gql(onUpdateRoomOrgUnitAdmin)
                return gql(onUpdateRoom)
            default:
                throw new Error("Wrong room list subscription mode")
        }
    }

    function fillVariables(orgUnitId: string) {
        if (!orgUnitId)
            return {}
        if (currentUser.isAdmin) {
            return {}
        } else {
            if (currentUser.isOrgUnitAdmin) {
                return {orgUnitId: orgUnitId}
            }
            return {orgUnitId: orgUnitId, isActive: true}
        }
    }

    function fetchSubscriptionData(mode: string, subscriptionData: any) {
        switch (mode) {
            case "CREATE":
                if (currentUser.isAdmin)
                    return subscriptionData.data.onCreateRoomAdmin
                else if (currentUser.isOrgUnitAdmin)
                    return subscriptionData.data.onCreateRoomOrgUnitAdmin
                return subscriptionData.data.onCreateRoom
            case "DELETE":
                if (currentUser.isAdmin)
                    return subscriptionData.data.onDeleteRoomAdmin.roomId
                else if (currentUser.isOrgUnitAdmin)
                    return subscriptionData.data.onDeleteRoomOrgUnitAdmin.roomId
                return subscriptionData.data.onDeleteRoom.roomId
            case "UPDATE":
                if (currentUser.isAdmin)
                    return subscriptionData.data.onUpdateRoomAdmin
                else if (currentUser.isOrgUnitAdmin)
                    return subscriptionData.data.onUpdateRoomOrgUnitAdmin
                return subscriptionData.data.onUpdateRoom
            default:
                throw new Error("Wrong room list subscription mode")
        }
    }

    useEffect(function subscribe() {

        const unsubscriptionsList: (() => void)[] = []
        if ((currentUser.isAdmin && currentUser.orgUnits.length > 0) || (currentUser.isOrgUnitAdmin && currentUser.orgUnits.length > 0) || currentUser.orgUnits?.length) {
            currentUser.orgUnits.forEach(org => {
                const unsubscribeCreateRoom = subscribeToMore({
                    document: fillDocument("CREATE"),
                    variables: fillVariables(org.orgId),
                    updateQuery: (prev, {subscriptionData}) => {
                        if (!subscriptionData.data) return prev;
                        const newRoom = fetchSubscriptionData("CREATE", subscriptionData);
                        return (Object.assign({}, prev, {
                                listRooms: {
                                    items: [...prev.listRooms.items, newRoom].sort(roomCompareByName),
                                    nextToken: null
                                }
                            })
                        )
                    },
                    onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
                });
                const unsubscribeDeleteRoom = subscribeToMore({
                    document: fillDocument("DELETE"),
                    variables: fillVariables(org.orgId),
                    updateQuery: (prev, {subscriptionData}) => {
                        if (!subscriptionData.data) return prev;
                        const deletedRoomId = fetchSubscriptionData("DELETE", subscriptionData);
                        return Object.assign({}, prev, {
                            listRooms: {
                                items: prev.listRooms.items.filter((item: any) => item.roomId !== deletedRoomId),
                                nextToken: null
                            }
                        })
                    },
                    onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
                });
                const unsubscribeUpdateRoom = subscribeToMore({
                    document: fillDocument("UPDATE"),
                    variables: fillVariables(org.orgId),
                    updateQuery: (prev, {subscriptionData}) => {
                        if (!subscriptionData.data) return prev;
                        const updatedRoom: Room = fetchSubscriptionData("UPDATE", subscriptionData);
                        let roomAlreadyInList = false;
                        let newRoomList = prev.listRooms.items.map((item: Room) => {
                            if (item.roomId === updatedRoom.roomId) {
                                roomAlreadyInList = true
                                return updatedRoom
                            }
                            return item
                        })
                        if (!roomAlreadyInList) {
                            newRoomList.push(updatedRoom)
                        }
                        return Object.assign({}, prev, {
                            listRooms: {
                                items: [...newRoomList].sort(roomCompareByName),
                                nextToken: null
                            }
                        })
                    },
                    onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
                });

                unsubscriptionsList.push(...[unsubscribeCreateRoom, unsubscribeDeleteRoom, unsubscribeUpdateRoom]);
            })
        } else if ((currentUser.isAdmin && currentUser.orgUnits.length === 0) || (currentUser.isOrgUnitAdmin && currentUser.orgUnits.length === 0)) {
            const unsubscribeCreateRoom = subscribeToMore({
                document: fillDocument("CREATE"),
                variables: {},
                updateQuery: (prev, {subscriptionData}) => {
                    if (!subscriptionData.data) return prev;
                    const newRoom = fetchSubscriptionData("CREATE", subscriptionData);
                    return (Object.assign({}, prev, {
                            listRooms: {
                                items: [...prev.listRooms.items, newRoom].sort(roomCompareByName),
                                nextToken: null
                            }
                        })
                    )
                },
                onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
            });
            const unsubscribeDeleteRoom = subscribeToMore({
                document: fillDocument("DELETE"),
                variables: {},
                updateQuery: (prev, {subscriptionData}) => {
                    if (!subscriptionData.data) return prev;
                    const deletedRoomId = fetchSubscriptionData("DELETE", subscriptionData);
                    return Object.assign({}, prev, {
                        listRooms: {
                            items: prev.listRooms.items.filter((item: any) => item.roomId !== deletedRoomId),
                            nextToken: null
                        }
                    })
                },
                onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
            });
            const unsubscribeUpdateRoom = subscribeToMore({
                document: fillDocument("UPDATE"),
                variables: {},
                updateQuery: (prev, {subscriptionData}) => {
                    if (!subscriptionData.data) return prev;
                    const updatedRoom: Room = fetchSubscriptionData("UPDATE", subscriptionData);
                    let roomAlreadyInList = false;
                    let newRoomList = prev.listRooms.items.map((item: Room) => {
                        if (item.roomId === updatedRoom.roomId) {
                            roomAlreadyInList = true
                            return updatedRoom
                        }
                        return item
                    })
                    if (!roomAlreadyInList) {
                        newRoomList.push(updatedRoom)
                    }
                    return Object.assign({}, prev, {
                        listRooms: {
                            items: [...newRoomList].sort(roomCompareByName),
                            nextToken: null
                        }
                    })
                },
                onError: (error: any) => (Array.isArray(error.errors) ? error.errors : [error.errors || error]).forEach((e: any) => console.error(e))
            });
            unsubscriptionsList.push(...[unsubscribeCreateRoom, unsubscribeDeleteRoom, unsubscribeUpdateRoom]);
        }

        function unsubscribe(unsubscriptionsList: any []) {
            unsubscriptionsList.forEach(unsubscribe => unsubscribe())
        }

        return () => unsubscribe(unsubscriptionsList);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser.isAdmin, currentUser.isOrgUnitAdmin, currentUser.orgUnits]);

    useEffect(() => {
        //Subscription triggers when any room gets a new OrgUnit or isActive property changes to false. This way, the client realizes that he doesn't have access to the room anymore, and refetches the roomList
        if (subscriptionData && (!currentUser.isAdmin && !currentUser.isOrgUnitAdmin)) {
            const affectedRoom: Room[] = data.listRooms.items.filter((room: Room) => room.roomId === subscriptionData.onRoomAccessChange)
            if (affectedRoom && affectedRoom.length > 0) {
                refetch().then();
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [subscriptionData])

    return data?.listRooms?.items ?? [];
}