import React, {useCallback, useEffect, useState} from "react";
import Snackbar, {SnackbarOrigin} from '@material-ui/core/Snackbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import ErrorIcon from '@material-ui/icons/Error';
import SnackbarContent from "@material-ui/core/SnackbarContent";
import {useTranslation} from "react-i18next";

interface Props {
    errors: { error: Error, customMessage: string }[];
    backgroundColor: string;
    horizontalAlignment: SnackbarOrigin["horizontal"];
    verticalAlignment: SnackbarOrigin["vertical"];
    notDisplayCloseIcon?: boolean;
    clearErrors: () => void
}

const WarningSnackbar = (props: Props) => {

    const {t} = useTranslation();
    const [text, setText] = useState<string>("");
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        if (props.errors.length > 0) {
            let message = undefined;
            props.errors.forEach((error) => {
                if (error.customMessage !== "") {
                    message = error.customMessage;
                }
            })
            if (!message) {
                message = t("default_error");
            }
            setText(message);
            setVisible(true);
        } else {
            setVisible(false);
        }
    }, [props.errors, visible, setVisible, setText])


    const closeWarning = useCallback(() => {
        props.clearErrors()
    }, [props])

    return (
        <div key={"error-snackbar"}>
            <Snackbar
                className="snackbar"
                anchorOrigin={{
                    vertical: props.verticalAlignment,
                    horizontal: props.horizontalAlignment,
                }}
                open={visible}
                autoHideDuration={10 * 1000}
                onClose={closeWarning}
                ContentProps={{
                    'aria-describedby': 'message-id',
                }}>
                <SnackbarContent
                    style={{
                        backgroundColor: props.backgroundColor,
                    }}
                    message={<span id="message-id" style={{display: "flex", alignItems: "center"}}><ErrorIcon
                        style={{marginRight: 8}}/>{text}</span>}
                    action={[
                        <IconButton
                            className="iconbutton"
                            hidden={props.notDisplayCloseIcon}
                            onClick={closeWarning}
                            key={"closeWarningButton"}
                        >
                            <CloseIcon/>
                        </IconButton>,
                    ]}
                />
            </Snackbar>
        </div>
    )
};

export default WarningSnackbar
