const locales_en = {
    "button_notifications-title": "Notifications",
    "signout": "Sign out",
    "notification-dialog-title": "Notification settings",
    "notification-dialog-hint": "Choose which actions you want to receive email notifications for",
    "notification-option-1": "Receive my own seat bookings as an Outlook appointment",
    "notification-option-2": "Receive external bookings made by me as an Outlook appointment",
    "room_management_dialog-title": "Manage room plans",
    "room_public-active": "Public",
    "seat_management_dialog-title": "Manage seats",
    "room_hint_management_dialog_title" : "Manage rooms",
    "seat_booking_error_no_rooms_configured": "There are currently no room plans configured. Please contact your office manager.",
    "org_unit_no_org_selected": "Select an org-unit",
    "room_plan_no_room_selected": "Select a room plan",
    "room_plan_no_room_available": "No room plan available",
    "room_name-column": "Name",
    "room-name": "Room name",
    "room_plan-column": "SVG",
    "scaleFactor-column": "Scaling",
    "room_active-column": "Active",
    "room_default-column": "Default",
    "room_time-active": "By time",
    "button_upload-text": "Upload SVG",
    "button_svg_delete-text": "Delete SVG",
    "button_create_room-text": "Create",
    "button_close_room_management_dialog-text": "Close",
    "message_error_file_type": "File is invalid!",
    "message_success_uploaded_file": "File has been uploaded!",
    "message_error_create_room": "A room with the same name and the same org-unit already exists in the system.",
    "my_bookings_mngmt_dialog-title": "My bookings",
    "docking_mngmnt_dialog-title": "Manage docking stations",
    "monitor_mngmnt_dialog-title": "Manage monitors",
    "booking_limitation_dialog_title": "Advanced configuration",
    "seat_configuration_dialog-title": "Configure seat",
    "docking_station-column": "Docking stations",
    "monitor-column": "Monitors",
    "height-adjustable-column": "Height-adjustable",
    "height-adjustable-checkbox-label": "Table is height-adjustable",
    "select_monitor": "Select a monitor",
    "select_docking_station": "Select a docking station",
    "inv_mngmnt_close_dialog_button-text": "Close",
    "inv_mngmnt_add_button-text": "Add",
    "inv_mngmnt_edit_button-text": "Edit",
    "inv_mngmnt_delete_button-text": "Delete",
    "confirm_dialog_ok_button-text": "Confirm",
    "confirm_dialog_cancel_button-text": "Cancel",
    "seats": "Seats",
    "seat_info_dockingstations_title": "Docking stations",
    "seat_info_monitors_title": "Monitors",
    "button_save": "Save",
    "button_close": "Close",
    "seat_name-column": "Seat name",
    "configure_seat": "Configure seat",
    "room_name_column":"Room",
    "room_note_text_column":"Note text",
    "inv_mngmnt_error_create_inv": "An inventory with this name already exists in the system.",
    "roomplan_removeseat_or_book_for_other_day_title": "This seat is already yours on this day.",
    "roomplan_removemeetingroom_or_book_for_other_day_title": "This room already belongs to you on this day.",
    "roomplan_removeseat_or_book_for_other_day_infotext": "Would you like to delete your booking or book it for more days?",
    "button_delete_booking": "Delete booking",
    "button_book_other_days": "Book other days",
    "open_another_booking_dialog": "Book other days/times",
    "multibookingdialog_textfield_label_booking_for": "Booking for",
    "multibookingdialog_dropdown_label_booking_for": "Booking for",
    "multibookingdialog_legend_title": "Legend",
    "multibookingdialog_legend_own_booking": "Seat booked by me",
    "multibookingdialog_legend_no_booking": "Seat available",
    "multibookingdialog_meetingroom_legend_own_booking": "Room booked through me",
    "multibookingdialog_meetingroom_legend_no_booking": "Room available",
    "multibookingdialog_legend_booking_available": "Only certain time slots available",
    "multibookingdialog_legend_other_user": "Seat is not available",
    "multibookingdialog_meetingroom_legend_other_user": "Room not available",
    "multibookingdialog_legend_marked": "Days marked for booking",
    "multibookingdialog_title_normal": "Days to be booked",
    "multibookingdialog_title_book_other_days": "Select other days",
    "multibookingdialog_error_booking_not_complete": "Not all selected days could be booked. The selection of days has been adjusted accordingly. Please try again.",
    "multibookingdialog_error_seat_already_taken": "The seat is already taken on this day.",
    "multibookingdialog_meetingroom_error_seat_already_taken": "The room is booked in this time window.",
    "multibookingdialog_error_max_booking_days_exceeded": "Pre-booking is possible only until ",
    "multibookingdialog_error_max_bookable_days_": "Maximum of bookable days reached. Seat booking on other days is no longer possible.",
    "multibookingdialog_meetingroom_error_max_bookable_days_": "You reached the maximum number of bookable days. Booking rooms on other days won't be possible.",
    "multibookingdialog_dropdown_booking_for_me": "Booking for myself",
    "multibookingdialog_dropdown_booking_for_others": "Booking for others",
    "multibookingdialog_error_parallel_booking": "Another user has just booked the seat on %s. The day can no longer be booked.",
    "multibookingdialog_error_parallel_bookings": "Another user has just booked the seat on days %s. The days can no longer be booked.",
    "multibookingdialog_meetingroom_error_parallel_booking": "Another user has just booked the room on %s. The Room is not bookable anymore on this day.",
    "multibookingdialog_meetingroom_error_parallel_bookings": "Another user has just booked the room on the following days %s. The room is not bookable on those days anymore.",
    "multibookingdialog_time_window_title": "Free time slots",
    "multibookingdialog_meetingroom_settings_title": "Settings",
    "multibookingdialog_time_window_no_common_time_slot": "No common available time slot on the selected days.",
    "multibookingdialog_error_time_slot_not_bookable": "Selected time slot is not bookable.",
    "button_book_other_days_times": "Book other days/time",
    "multibookingdialog_meetingroom_booking_type_internal": "Internal",
    "multibookingdialog_meetingroom_booking_type_external": "External",
    "multibookingdialog_meetingroom_booking_type_vip": "VIP",
    "book": "Book",
    "cancel": "Cancel",
    "owner-column": "Seat owner",
    "select_user": "Select user",
    "no_owner": "No owner",
    "no_user": "No user",
    "other_user": "non-2Gether-user",
    "apply": "Apply",
    "seat-adjustable-height-hover": "height-adjustable",
    "expand-seat-filters": "Expand seat filters",
    "collapse-seat-filters": "Collapse seat filters",
    "delete_room_dialog_content": "Are you sure you want to delete it?",
    "jsLocalDateProperty": "en-GB",
    "date": "Date",
    "delete": "Delete",
    "roomplan": "Room plan",
    "seat": "Seat",
    "own_bookings_management_dialog_title": "My bookings",
    "own_bookings_management_alert_dialog_title": "Are you sure you want to delete selected bookings?",
    "own_bookings_management_alert_dialog_amount_bookings": "Number of selected bookings",
    "timeWindow": "Time slot",
    /*RoomManager*/
    "rm_showUploadConfirmationDialog_cancel_button-text": "Cancel",
    "rm_showUploadConfirmationDialog-text": "The following seats are not available in the new room plan, bookings for these seats will be lost: ",
    "rm_showUploadConfirmationDialog_refresh_button-text": "Update",
    /*DailyBookingsDisplay*/
    "daily_bookings_table_bookingname_column": "Name",
    "daily_bookings_table_room_column": "Room plan",
    "daily_bookings_table_seat_column": "Seat",
    "daily_bookings_table_orgunit_column": "Org. unit",
    "daily_bookings_empty_list_message": "There are no bookings on the selected day",
    "daily_bookings_count_display_text": "Bookings: ",
    "booking-error_title": "Booking error",
    "default_error": "Something went wrong with your request.",
    "booking_error": "Something went wrong with your booking.",
    "error_collection": "Occurred errors",
    "error_message": "Error message",
    "custom_error_message": "Custom message",
    "stack_trace": "Stack Trace",
    "max_bookable_days": "Max. number of bookable days",
    "max_lead_time": "Max. lead time in days",
    "Booking_day": "Bookings on",
    "minutes": "minutes",
    "message_disable_time_bookings": "There are seats that have multiple bookings per day. The earliest booking on each seat will be kept and the rest of the bookings will be deleted. In total %number bookings will be deleted. Confirm the deletion?",
    "message_disable_time_booking": "There are seats that have multiple bookings per day. The earliest booking on each seat will be kept and the rest of the bookings will be deleted. In total one booking will be deleted. Confirm the deletion?",
    /*Neighborhoods*/
    "neighborhood_management_dialog-title": "Manage neighborhoods",
    "neighborhoods": "Neighborhoods",
    "organisational_units": "Organisational Units",
    "no_neighborhood_selected": "Select a neighborhood"
}
export default locales_en;